<div class="calendarMonthClass">
  <div class="calendar-month-head">
    <app-month-select class="calendar-month-head-month"
                      [selected]="{month: selectedDate.month, year: selectedDate.year}"
                      [withIcon]="false"
                      [minMonth]="minMonth"
                      [maxMonth]="maxMonth"
                      [minYear]="minYear"
                      [maxYear]="maxYear"
                      [forCalendar]="true"
                      [openDirection]="'bottom'"
                      [isNeedLimitMonth]="isNeedLimit"
                      (OnSelect)="onChangeMonth($event)">
    </app-month-select>
    <span *ngIf="asStartRange" class="calendar-month-head-text">{{'GENERAL.START' | translate}}</span>
    <span *ngIf="asEndRange" class="calendar-month-head-text">{{'GENERAL.END' | translate}}</span>
  </div>
  <div class="calendar-month-content">
    <div class="calendar-month-days-week">
      <div *ngFor="let day of daysOfWeek" class="calendar-month-day-week">{{day.name}}</div>
    </div>
    <div class="calendar-month-days-block">
      <div *ngFor="let week of weeks" class="calendar-month-days">
        <div *ngFor="let day of week" class="calendar-month-day">
          <div class="calendar-month-day-date"
               (click)="onChangeDay(day.date, day.isCurrentMonth, day.moment,
               (day.isNextMonth && isMaxMonth) || (day.isPrevMonth && isMinMonth) || day.disabled)"
               [ngClass]="{
               'as-start-range': asStartRange,
               'as-end-range': asEndRange,
               'disabled': (day.isNextMonth && isMaxMonth) || (day.isPrevMonth && isMinMonth) || day.disabled,
               'checked': selectedMoment && day.moment.isSame(selectedMoment),
               'is-today': day.isToday,
               'no-current-month': !day.isCurrentMonth}">
            <span class="text">{{day.date}}</span>
            <span *ngIf="selectedMoment && day.moment.isSame(selectedMoment)"
                  class="font-icons"
                  [ngClass]="{
                  'icon-calendar_date_checked': !asStartRange && !asEndRange,
                  'icon-calendar_date_start_checked': asStartRange && !asEndRange,
                  'icon-calendar_date_end_checked': !asStartRange && asEndRange
                  }">
              <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="withTime" class="calendar-month-time">
    <app-time-select-input class="calendar-month-time-input"
                           [id]="id"
                           [selected]="selectedTime"
                           [withIcon]="true"
                           [withMinutes]="withMinutes"
                           [withSeconds]="withSeconds"
                           (OnSelect)="onChangeTime($event)">
    </app-time-select-input>
  </div>
</div>
