<div class="multiDictionaryModalClass">
  <div class="multi-dictionary-modal-head">
    <div class="multi-dictionary-modal-title-block">
      <div class="multi-dictionary-modal-title">{{title}}</div>
      <app-switch class="multi-dictionary-modal-regime"
                  [title]="'GENERAL.ONLY_SELECTED' | translate"
                  [value]="selectedRegime"
                  [name]="'only-selected'"
                  (OnChange)="onChangeRegime($event)">
      </app-switch>
    </div>
    <div *ngIf="!selectedRegime" class="multi-dictionary-modal-search">
      <app-checkbox class="multi-dictionary-modal-select-all"
                    [(ngModel)]="selectedAll"
                    [name]="'multi-dictionary-selected-all'"
                    [disabled]="disabled"
                    (OnChange)="onSelectAll($event)">
      </app-checkbox>
      <app-search-field class="multi-dictionary-modal-search-input"
                        [placeholder]="''"
                        [value]="params && params.search ? params.search : null"
                        [searchId]="'multi-dictionary-search'"
                        [debounce]="1000"
                        (OnSearch)="search($event)"
      ></app-search-field>
      <span class="font-icons" [class.icon-sort_asc]="!params || params.sorting === 'asc'"
            [class.icon-sort_desc]="params && params.sorting === 'desc'"
            (click)="changeSorting()"
      ></span>
    </div>
    <div *ngIf="selectedRegime" class="multi-dictionary-modal-search">
      <app-checkbox class="multi-dictionary-modal-select-all"
                    [(ngModel)]="selectedAllForRegime"
                    [name]="'multi-dictionary-selected-all'"
                    [disabled]="disabled"
                    (OnChange)="onSelectAllForRegime($event)">
      </app-checkbox>
      <app-search-field class="multi-dictionary-modal-search-input"
                        [placeholder]="''"
                        [value]="searchTextForRegime ? searchTextForRegime : null"
                        [searchId]="'multi-dictionary-search'"
                        [debounce]="1000"
                        (OnSearch)="searchForRegime($event)"
      ></app-search-field>
      <span class="font-icons" [class.icon-sort_asc]="orderingForRegime === 'asc'"
            [class.icon-sort_desc]="orderingForRegime === 'desc'"
            (click)="changeSortingForRegime()"
      ></span>
    </div>
  </div>
  <div class="multi-dictionary-modal-body">
    <app-loader [spinnerSize]="80" [show]="loadingInProgress"></app-loader>
    <div *ngIf="list && list.elements && list.elements.length > 0 && !selectedRegime"
         class="multi-dictionary-modal-content scroll-block"
         #dictList
         (scroll)="onScrollList($event)">
        <app-checkbox-list  class="multi-dictionary-list"
                            [selected]="selectedKeys"
                            [checkboxList]="list.elements"
                            [checkListName]="'multi-dictionary-list'"
                            [disabled]="disabled"
                            [withSelectTitle]="true"
                            [forDictionary]="true"
                            (OnChange)="onSelectItems($event)"
       ></app-checkbox-list>
    </div>
    <div *ngIf="selectedListForRegime && selectedListForRegime.length > 0 && selectedRegime"
         class="multi-dictionary-modal-content scroll-block"
         #dictList
         (scroll)="onScrollList($event)">
      <app-checkbox-list  class="multi-dictionary-list"
                          [selected]="selectedKeysForRegime"
                          [checkboxList]="selectedListForRegime"
                          [checkListName]="'multi-dictionary-list'"
                          [disabled]="disabled"
                          [withSelectTitle]="true"
                          [forDictionary]="true"
                          (OnChange)="onSelectItemsForRegime($event)"
      ></app-checkbox-list>
    </div>
    <div *ngIf="!list || !list.elements || list.elements.length === 0 && !selectedRegime"
         class="multi-dictionary-modal-empty">
      {{'GENERAL.EMPTY_LIST' | translate}}
    </div>
    <div *ngIf="(!selectedListForRegime || selectedListForRegime.length === 0) && selectedRegime"
         class="multi-dictionary-modal-empty">
      {{'GENERAL.EMPTY_SELECTED' | translate}}
    </div>
  </div>
  <div class="multi-dictionary-modal-footer">
    <div *ngIf="!selectedRegime" class="multi-dictionary-modal-count">
      {{list && list.elements ? list.elements.length : 0}} из {{list.totalElements}}
    </div>
    <div *ngIf="selectedRegime" class="multi-dictionary-modal-count">
      {{selectedItemsForRegime ? selectedItemsForRegime.length : 0}} из {{list.totalElements}}
    </div>
    <div class="multi-dictionary-modal-btns">
      <app-button class="multi-dictionary-modal-btn"
                  [title]="'GENERAL.CANCEL' | translate"
                  (OnClick)="cancel()"
      ></app-button>
      <app-button class="multi-dictionary-modal-btn blue"
                  [title]="'GENERAL.APPLY' | translate"
                  [disabled]="disabled || !activeApply"
                  (OnClick)="apply()"
      ></app-button>
    </div>
  </div>
</div>

