import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  forwardRef,
  ElementRef,
  ViewChild
} from '@angular/core';
import { DateTimeService } from "@shared/services/date-time.service";
import { ITextMaskConfig } from "@core/interfaces/text-mask";
import { createAutoCorrectedDatePipe } from "text-mask-addons/dist/textMaskAddons";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeInputComponent),
      multi: true
    }
  ]
})
export class DateTimeInputComponent implements OnChanges, ControlValueAccessor {

  private dateFormat = this.dateTimeService.getDateFormat('date');
  private timeFormat = this.dateTimeService.getDateFormat('time');
  public timeWithSecondFormat = this.dateTimeService.getDateFormat('timeWithSecond');

  public selectedDate: string = null;
  public selectedTime: string = null;
  public isFocus: boolean = false;
  public enableClickOutside: boolean = false;

  public selectedTimeFormat: string = this.timeFormat;
  private dateMask = this.dateTimeService.getDateMask();
  private timeMask = this.dateTimeService.getTimeMask(this.timeFormat);

  public withDate: boolean = true;

  public dateInputConfig: ITextMaskConfig = {
    mask: this.dateMask,
    showMask: true,
    guide: true,
    keepCharPositions: true,
    pipe: createAutoCorrectedDatePipe('dd.mm.yyyy')
  };
  public timeInputConfig: ITextMaskConfig = {
    mask: this.timeMask,
    showMask: true,
    guide: true,
    keepCharPositions: true
  };

  public _onChange: any = () => { };
  public _onTouch: any = () => { };

  @Input() selected: string = null;
  @Input() id: string = null;
  @Input() format: string = this.dateFormat;
  @Input() withoutClear: boolean = false;
  @Input() noManualInput: boolean = false;
  @Input() hasError: boolean = false;
  @Input() errorText: string = null;
  @Input() disabled: boolean = false;
  @Input() tooltipPlacement: string = 'top';
  @Output() OnSelect: EventEmitter<string> = new EventEmitter<string>(); // выбор месяца/года

  @ViewChild('dateInput') public dateInputRef: ElementRef;
  @ViewChild('timeInput') public timeInputRef: ElementRef;

  constructor(private dateTimeService: DateTimeService) { }

  ngOnChanges() {
    this.checkDateTimeMask();
    this.changeValue(this.selected);
  }

  private setCaretPositionAtBeginning(dateInput: boolean): void {
    const elem = dateInput ? this.dateInputRef.nativeElement : this.timeInputRef.nativeElement;
    const value = dateInput ? this.selectedDate : this.selectedTime;
    if (!value || value === '' || value.includes('_')) {
      const index: number = 0;
      elem.setSelectionRange(index, index);
    }
    this.isFocus = true;
    this.enableClickOutside = true;
  };

  private checkDateTimeMask() {
    this.withDate = this.format.includes(this.dateFormat);
    const timeM: string = this.format && this.format !== this.dateFormat
      ? !!this.format.split(' ')[1]
        ? this.format.split(' ')[1] : this.format
      : null;
    if (timeM && timeM === this.timeWithSecondFormat) {
      this.timeMask = this.dateTimeService.getTimeMask(this.timeWithSecondFormat);
      this.selectedTimeFormat = this.timeWithSecondFormat;
      this.timeInputConfig = { ...this.timeInputConfig, ...{ mask: this.timeMask, pipe: createAutoCorrectedDatePipe('HH:MM:SS') } };
    } else if (timeM && timeM === this.timeFormat) {
      this.timeMask = this.dateTimeService.getTimeMask(this.timeFormat);
      this.selectedTimeFormat = this.timeFormat;
      this.timeInputConfig = { ...this.timeInputConfig, ...{ mask: this.timeMask, pipe: createAutoCorrectedDatePipe('HH:MM') } };
    } else {
      this.timeMask = this.dateTimeService.getTimeMask(this.timeFormat);
      this.timeInputConfig = { ...this.timeInputConfig, ...{ mask: this.timeMask, pipe: createAutoCorrectedDatePipe('HH:MM') } };
      this.selectedTimeFormat = null;
    }
  }

  private changeValue(value: string) {
    this.selected = value ? value : null;
    this.selectedDate = this.selected
      ? this.dateTimeService.getDateStringFromString(this.selected, this.format, this.dateFormat)
      : null;
    this.selectedTime = this.selected && this.selectedTimeFormat
      ? this.dateTimeService.getTimeStringFromString(this.selected, this.format, this.selectedTimeFormat)
      : null;
    if (!this.selectedDate || (this.selectedTimeFormat && !this.selectedTime)) {
      this.selected = null;
    }
    this._onChange(this.selected);
  }

  public onFocus(dateInput: boolean) {
    setTimeout(() => this.setCaretPositionAtBeginning(dateInput), 0);
  }

  public onChange() {
    let value = this.selectedDate && this.withDate
      ? this.dateTimeService.getDateStringFromString(this.selectedDate, this.dateFormat, this.dateFormat)
        ? this.dateTimeService.getDateStringFromString(this.selectedDate, this.dateFormat, this.dateFormat)
        : null
      : null;
    if (this.selectedTimeFormat) {
      if (!this.selectedTime) {
        this.selectedTime = this.dateTimeService.getTimeStringFromString(value, this.dateFormat, this.selectedTimeFormat);
      }
      value = value ? `${value} ${this.selectedTime}` : this.selectedTime;
    }
    this.writeValue(value);
    this.OnSelect.emit(value);
  }

  public onClickOutside() {
    this.enableClickOutside = false;
    this.isFocus = false;
    this.prepareValue();
    this.onChange();
  }

  private prepareValue() {
    if (this.selectedDate && this.withDate && this.selectedDate.includes('_')) {
      if (this.selectedDate === '__.__.____') {
        this.selectedDate = null;
      } else {
        const dateArray = this.selectedDate.split('.');
        if (dateArray && dateArray.length > 0) {
          let result = dateArray.map((item: string, index: number) => {
            let countConcat: number = 0;
            for (let i = 0; i < item.length; i++) {
              if (item[i].includes('_')) {
                countConcat++;
              }
            }
            if (countConcat > 0 && countConcat < item.length) {
              for (let i = 0; i <= countConcat; i++) {
                item = item.replace('_', '0');
              }
            } else if (countConcat === item.length) {
              if (index === 0) {
                item = dayjs().day().toString();
                if (item.length === 1) {
                  item = '0' + item;
                }
              } else if (index === 1) {
                item = (dayjs().month() + 1).toString();
                if (item.length === 1) {
                  item = '0' + item;
                }
              } else {
                item = dayjs().year().toString();
              }
            }
            return item;
          });
          this.selectedDate = result.join('.');
        } else {
          this.selectedDate = null;
        }
      }
    }
    if (this.selectedTimeFormat) {
      if (this.withDate && !this.selectedDate) {
        this.selectedTime = null;
      } else if (this.selectedTime && this.selectedTime.includes('_')) {
        for (let i=0; i<this.selectedTime.length; i++) {
          if (this.selectedTime[i].includes('_')) {
            this.selectedTime = this.selectedTime.replace('_', '0');
          }
        }
      }
    }
  }

  public clearSelect() {
    this.selectedDate = null;
    this.selectedTime = null;
    this.writeValue(null);
    this.OnSelect.emit(null);
  }

  public changedDate(value) {
    if (value && !value.includes('_')) {
      if (this.selectedTimeFormat) {
        this.dateInputRef.nativeElement.blur();
        this.timeInputRef.nativeElement.focus();
      } else {
        this.onChange();
      }
    }
  }

  public changedTime(value) {
    if (value && !value.includes('_')) {
      this.onChange();
    } else {
      let countNumber = 0;
      for (let i=0; i<=9; i++) {
        if (value.includes(i.toString())) {
          countNumber++;
        }
      }
      if (countNumber === 0 && value.includes('_')) {
        this.timeInputRef.nativeElement.blur();
        this.dateInputRef.nativeElement.focus();
      }
    }
  }

  writeValue(value: string): void {
    this.selected = value ? value : null;
    this.changeValue(this.selected);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
