<div class="menuClass"
  [class.open]="isOpenMenu | async">
  <div class="menu-head">
    <span class="menu-close font-icons icon-close"
      (click)="closeMenu()">
    </span>
    <span class="menu-toogle font-icons"
      [class.icon-menu_open]="!isMenuCollapseOpen"
      [class.icon-menu_collapse]="isMenuCollapseOpen"
      (click)="collapseMenu()">
    </span>
  </div>
  <div class="menu-body scroll-menu-block">
    <div class="menu-list">
      <app-menu-item *ngFor="let item of menu"
                     [item]="item"
                     [mainItem]="true"
                     (changeOpen)="changeItemOpen()">
      </app-menu-item>
    </div>
  </div>
</div>
