import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private errorMessage: string = '';

  constructor(
    private translateService: TranslateService,
    private toastr: ToastrService,
    private httpClient: HttpClient
  ) {
    this.translateService.get('GENERAL.ERROR_DOWNLOAD_FILE').subscribe(result => {
      this.errorMessage = result;
    })
  }

  public getPhoto(url, requestBody, queryParams?): Observable<HttpResponse<Blob>> {
    return this.httpClient.post(`${url}`, requestBody, {
      observe: 'response',
      responseType: 'blob',
      params: queryParams
    });
  }

  public downloadFile(url: string, fileName: string, requestBody: any = null, withPost: boolean = false): void {
    const func$ = withPost ? this.getBlobFileWithPost(url, requestBody) : this.getBlobFile(url);
    func$.subscribe(
      data => this.downloadFileBlob(data, fileName),
      (error: HttpErrorResponse) => {
        error?.error instanceof Blob
          ? error.error.text().then(text => {
            this.toastr.error(JSON.parse(text).message, this.errorMessage);
          })
          : this.toastr.error(this.errorMessage);
      }
    );
  }

  public getBlobFile(url, queryParams?): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(`${url}`, {
      observe: 'response',
      responseType: 'blob',
      params: queryParams
    });
  }

  public getBlobFileWithPost(url, requestBody, queryParams?): Observable<HttpResponse<Blob>> {
    return this.httpClient.post(`${url}`, requestBody, {
      observe: 'response',
      responseType: 'blob',
      params: queryParams
    });
  }

  public downloadFileBlob(data, fileNameWithExtension: string) {
    const fileName = fileNameWithExtension ? fileNameWithExtension : data.headers.get('filename');
    const downloadLink = document.createElement('a');
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    const url = window.URL.createObjectURL(data.body);
    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute('download', decodeURIComponent(fileName)); // при изменении имени заголовка, filename, в /download/{dataObjectUuid}. Нужно изменить заголовок и здесь
    downloadLink.click();
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(url);
  }

}
