<div class="address-close" (click)="closeModalClick()">
    <span class="font-icons icon-calendar_clear"></span>
</div>
<app-loader [show]="isLoading"></app-loader>
<div class="AddressCardClass">
    <div class="address-modal-header">
        <div class="universal-card-title">
            <span>{{titleCard}}</span>
        </div>
        <app-button *ngIf="regime == 'add' && type=='object'"
                    class="modal-header-button address-import-button black"
                    [title]="'ADDRESS_CARD.IMPORT_ADDRESS' | translate"
                    (OnClick)="importAddress()">
        </app-button>
        <span *ngIf="canDelete"
              class="font-icons icon-delete"
              (click)="confirmDelete()">
        </span>
    </div>
    <div class="address-modal-body">
        <app-address-tree
          [object]="object"
          [type]="type"
          [regime]="regime"
          [tree]="elementTree"
          [treeSelect$]="treeSelect$"
          (onElementSelect)="loadElement($event)"
          (onObjectSelect)="loadObject($event)"
          (onElementRemove)="updateSaveCounts()"
          (onObjectRemove)="removeObject()">
        </app-address-tree>
        <app-address-object-card *ngIf="selectedType == 'object' && selectedObject"
                                 [object]="selectedObject"
                                 [tree]="elementTree"
                                 [regime]="regime"
                                 (onObjectChange)="onObjectChange($event)">
        </app-address-object-card>
        <app-address-element-card *ngIf="selectedType == 'element' && selectedElement"
                                  [element]="selectedElement"
                                  [tree]="elementTree"
                                  [regime]="regime"
                                  (OnElementChange)="onElementChange($event)">
        </app-address-element-card>
    </div>

    <div class="modal-footer">
        <div class="address-save-message" *ngIf="canSave()">
            <span>{{primarySaveSign}}</span>
            <span *ngIf="canSaveElementsCount > 0"> {{'ADDRESS_CARD.WILL_ADD_ELEMENTS' |
                translate:{'value':canSaveElementsCount} }}</span>
            <span *ngIf="canSaveElementsCount > 0 && (canSaveElementsTypesCount > 0 || canSaveObject)">, </span>
            <span *ngIf="canSaveElementsTypesCount > 0"> {{'ADDRESS_CARD.WILL_ADD_ELEMENT_TYPES' |
                translate:{'value':canSaveElementsTypesCount} }}</span>
            <span *ngIf="canSaveElementsTypesCount > 0 && canSaveObject">, </span>
            <span *ngIf="canSaveObject"> {{'ADDRESS_CARD.WILL_ADD_OBJECT' | translate }}</span>
        </div>
        <app-button *ngIf="regime != 'view'"
                    class="modal-footer-btn blue"
                    [title]="saveButtonTitle"
                    [disabled]="!canSave()"
                    (click)="saveData()">
        </app-button>
    </div>
</div>
