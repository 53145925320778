import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss']
})
export class ActionMenuComponent implements OnInit {

  @Input() disabled: boolean = false;
  @Input() withIconEllipsis: boolean = false;
  @Input() withIconAdd: boolean = false;
  @Input() withIconReport: boolean = false;

  public openedMenu: boolean = false; // флаг открытия выпадающего меню
  public enableClickOutside: boolean = false; // Флаг управления работы outsideClickEvent

  constructor() { }

  ngOnInit() {
  }

  /**
   * функция открытия выпадающего меню
   */
  public openMenu(event, open: boolean): void {
    if (this.disabled) {
      return;
    }
    this.openedMenu = open;
    this.enableClickOutside = open;
  }


}
