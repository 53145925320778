import { UtilsService } from '@shared/services/utils.service';
import { catchError } from 'rxjs/operators';
import { ImportAddressComponent } from './components/import-address/import-address.component';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { forkJoin, Subject, throwError } from 'rxjs';
import { AddressCardService } from './address-card.service';
import { ICreateAddressTree, ICreateAddressObject, ICreateAddressElement, IAddressObjectRich } from '@core/interfaces/address';
import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ICreateAddressTreeElement, ICreateAddressTreeObject, IAddressElementTree } from "@core/interfaces/address";
import { AddressApiService } from "@core/services/api/address/address-api.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { OpenModalService } from "@shared/services/open-modal.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AddressSource } from "@app/app.enums";
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AddressObjectCardComponent } from './components/address-object-card/address-object-card.component';
import { AddressElementCardComponent } from './components/address-element-card/address-element-card.component';

@Component({
  selector: 'app-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss']
})
export class AddressCardComponent implements OnInit {

  @Input() id: number = null; // ид выбранной записи
  @Input() elementTreeId: number = null; // ид нижнего элемента дерева
  @Input() object: ICreateAddressTreeObject = null; // выбранный адресный объект
  @Input() regime: 'view' | 'edit' | 'add' = 'view'; // режим - view, edit, add
  @Input() type: 'object' | 'element' = null; // тип (адресный объект или адресообразующий элемент)
  @Input() title: string = null; // заголовок карточки (может быть пустым при добавлении новой)
  @Input() guideCode: string = null; // код справочника
  @Output() OnSave: Function; // функция после обновления
  @Output() OnClose: Function; // функция после закрытия

  @ViewChild(AddressObjectCardComponent, { static: false }) objectCard: AddressObjectCardComponent;
  @ViewChild(AddressElementCardComponent, { static: false }) elementCard: AddressElementCardComponent;

  public elementTree: ICreateAddressTreeElement[] = [];
  /**
   * Оригинальное дерево. Для определения изменений, которые будут сохранены.
   */
  public originalElementTree: ICreateAddressTreeElement[] = [];
  /**
   * Оригинальный объект. Для определения изменений, которыые будут сохранены.
   */
  public originalObject: ICreateAddressTreeObject = null;

  // счетчики сохранения для вывода сообщения в шаблоне
  public canSaveElementsCount: number = 0;
  public canSaveElementsTypesCount: number = 0;
  public canSaveObject: boolean = false;

  public canDelete: boolean = false;

  /**
   * Выбор элемента дерева извне
   */
  public treeSelect$: Subject<number | 'object'> = new Subject();

  public isLoading: boolean = false; // флаг загрузки
  public titleCard: string = null; // заголовок для отображения на карточке
  public readonly saveButtonTitle: string = this.translateService.instant(`GENERAL.${this.regime == 'add' ? 'ADD' : 'SAVE'}`);
  public primarySaveSign: string;

  public selectedType: 'object' | 'element'; // текущий выбор из дерева
  public selectedElement: ICreateAddressTreeElement; // выбранный из дерева элемент
  public selectedObject: ICreateAddressTreeObject; // выбранный из дерева объект

  public userChangedData: boolean = false;

  constructor(
    private addressApiService: AddressApiService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private modalService: OpenModalService,
    public activeModal: NgbActiveModal,
    private addressCardService: AddressCardService,
    private utilsService: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.primarySaveSign = this.translateService.instant(`ADDRESS_CARD.${this.regime == 'add' ? 'WILL_ADD' : 'WILL_CHANGE'}`);
    // в режиме создания добавляем стандартный заголовок и первый элемент дерева (регион)
    if (this.regime === 'add') {
      this.titleCard = this.type === 'element' ? this.translateService.instant('ADDRESS_CARD.NEW_ELEMENT') : this.translateService.instant('ADDRESS_CARD.NEW_OBJECT');
      this.elementTree.push({
        addressRegistryId: null,
        addressTreeManual: null,
        localId: null,
        position: 0,
        name: null,
        objectTypeFullName: this.translateService.instant('ADDRESS_CARD.REGION'),
        parentId: null,
        sourceType: AddressSource.FIAS
      })
    }
    else {
      this.getData();
    }
  }

  private getData() {
    this.isLoading = true;
    const reqs = [];
    if (this.elementTreeId) {
      reqs.push(this.getTree());
    }
    if (this.object) {
      reqs.push(this.getObjectData());
    }
    forkJoin(reqs).subscribe(
      (res) => {
        if (this.regime == 'edit') {
          this.titleCard = this.addressCardService.getAddressTitle(this.elementTree, this.object);
          if (this.type == 'object') {
            this.object.name = this.titleCard;
          }
        }
        else {
          this.titleCard = this.title;
        }
        this.isLoading = false;
        this.canDelete = this.type == 'element'
          ? this.elementTree[this.elementTree.length - 1].sourceType === AddressSource.MANUAL
          && this.regime == 'edit'
          : this.object.sourceType === AddressSource.MANUAL
          && this.regime == 'edit';
      },
      (error) => {
        this.isLoading = false;
        this.closeModal();
      }
    )
  }

  /**
   * Получить подробные данные объекта
   */
  private getObjectData(): Observable<IAddressObjectRich> {
    return this.addressCardService.getObjectRichData(this.object)
      .pipe(
        // сохраняем данные
        tap(res => {
          this.object.richData = res;
          this.object.addressManual = this.object.sourceType == AddressSource.MANUAL ?
            this.addressCardService.mapObjectRichDataForEditing(res) : null;
          this.originalObject = { ...this.object };
        }),
        // ловим ошибку при получении объекта
        catchError(error => {
          this.toastr.error(
            this.translateService.instant(`ADDRESS_CARD.ERRORS.LOAD_OBJECT`),
            this.translateService.instant('GENERAL.ERROR_LOAD')
          );
          return throwError(error)
        })
      );
  }

  /**
   * Получить подробные данные дерева элементов
   */
  private getTree(): Observable<ICreateAddressTreeElement[]> {
    return this.addressApiService.getElementTree(this.elementTreeId)
      .pipe(
        map(res => {
          return this.prepareTree(res)
        }),
        // добавляем подробные данныые элементов
        switchMap(res => {
          let reqs = res.map(item => {
            return this.addressCardService.getElementRichData(item)
              .pipe(
                map(res => {
                  return {
                    ...item,
                    richData: res,
                    sourceType: res.source
                  } as ICreateAddressTreeElement;
                }));
          });
          return forkJoin(reqs);
        }),
        // добавляем данные для редактирования
        map(res => res.map(item => {
          return {
            ...item,
            addressTreeManual: item.sourceType == AddressSource.MANUAL ?
              this.addressCardService.mapElementRichDataForEditing(item.richData) : null
          } as ICreateAddressTreeElement;
        })),
        // сохраняем данные
        tap(res => {
          this.elementTree = res;
          this.originalElementTree = res.map(item => {
            return { ...item };
          });
        }),
        // ловим ошибку при получении дерева
        catchError(error => {
          this.toastr.error(
            this.translateService.instant(`ADDRESS_CARD.ERRORS.LOAD_TREE`),
            this.translateService.instant('GENERAL.ERROR_LOAD')
          );
          return throwError(error);
        })
      );
  }

  private prepareTree(elements: IAddressElementTree[]): ICreateAddressTreeElement[] {
    if (elements && elements.length > 0) {
      return elements.map((element: IAddressElementTree) => {
        return {
          addressRegistryId: element.addressRegistryId,
          addressTreeManual: null,
          localId: element.localId,
          position: elements.length - element.rowNumber,
          name: element.name,
          objectTypeFullName: element.objectTypeFullName,
          parentId: element.parentId,
          sourceType: null
        }
      }).sort((a, b) => a.position - b.position);
    }
    else {
      return [];
    }
  }

  /**
   * Закрыть карточку.
   * Если пользователь изменил данные, то сначала показать модал подтверждения действия.
   */
  public closeModalClick() {
    if ((this.userChangedData && this.regime == 'add') || this.canSave()) {
      this.modalService.show(
        ConfirmModalComponent,
        {
          question: this.translateService.instant('ADDRESS_CARD.CLOSE_CONFIRM_MODAL_TITLE'),
          applyTitle: this.translateService.instant('ADDRESS_CARD.CLOSE_CONFIRM_BUTTON_TITLE'),
          cancelTitle: this.translateService.instant('GENERAL.CANCEL'),
          onApply: () => {
            this.closeModal();
          },
          onCancel: () => {

          }
        },
        'onCancel',
        {
          centered: true,
          windowClass: 'modal-confirm',
        }
      )
    }
    else {
      this.closeModal();
    }
  }

  private closeModal() {
    this.activeModal.dismiss();
  }

  /**
   * Выбрать элемент. На событие из дерева.
   * @param el элемент из дерева
   */
  public loadElement(el: ICreateAddressTreeElement) {
    this.selectedType = 'element';
    this.selectedElement = el;
  }

  /**
   * Выбрать объект. На событие из дерева.
   * @param obj объект из дерева
   */
  public loadObject(obj: ICreateAddressTreeObject) {
    this.selectedType = 'object';
    this.selectedObject = obj;
  }

  public removeObject() {
    this.object = null;
    this.selectedObject = null;
    this.updateSaveCounts();
  }

  /**
   * Изменение элемента из карточки элемента
   * @param el элемент
   */
  public onElementChange(el: ICreateAddressTreeElement) {
    this.userChangedData = true;
    this.elementTree.splice(el.position, 1, el);
    this.selectedElement = el;
    this.updateSaveCounts();
    if (this.regime == 'add') {
      // убираем все следующие элементы дерева, которые выбраны из справочника
      this.elementTree = this.elementTree.filter(item =>
        item.position <= el.position
        || (!item.localId && !item.addressRegistryId && !item.systemGuid));
      // убираем выбранный из справочника объект
      if (this.object && this.object.addressRegistryId) {
        this.object = this.object = {
          addressManual: null,
          addressRegistryId: null,
          sourceType: null,

          localId: null,
          name: null,
          objectTypeFullName: this.translateService.instant('ADDRESS_CARD.TREE_NEW_OBJECT')
        };
      }
    }
    else {
      // меняем заголовок карточки и имя объекта
      this.titleCard = this.addressCardService.getAddressTitle(this.elementTree, this.object);
      if (this.type == 'object' && this.object) {
        this.object.name = this.titleCard;
      }
    }
  }

  /**
   * Изменение объекта из карточки объекта
   * @param obj объект
   */
  public onObjectChange(obj: ICreateAddressTreeObject) {
    this.userChangedData = true;
    this.object = obj;
    this.updateSaveCounts();
    if (this.regime != 'add') {
      this.titleCard = this.addressCardService.getAddressTitle(this.elementTree, this.object);
    }
  }

  /**
   * Обновить счетчики сохранения
   */
  public updateSaveCounts() {
    this.canSaveElementsTypesCount = this.elementTree.filter(item => item.addressTreeManual && item.objectTypeManual).length;
    if (this.regime == 'add') {
      // добавляем только вручную созданные элементы или элементы из FIAS без localId (еще не добавленные)
      this.canSaveElementsCount = this.elementTree.filter(item => item.addressTreeManual || item.addressRegistryId).length;
      if (this.type == 'object') {
        this.canSaveObject = !!(this.object && (this.object.addressManual || this.object.addressRegistryId));
      }
    }
    else {
      let count = 0;
      this.originalElementTree.forEach((a, i) => {
        let b = this.elementTree[i];
        if (this.utilsService.compareObjects(a.addressTreeManual, b.addressTreeManual) === false) {
          count++;
        }
      });
      this.canSaveElementsCount = count;
      if (this.type == 'object') {
        this.canSaveObject = !this.utilsService.compareObjects(this.object.addressManual, this.originalObject.addressManual);
      }
    }
  }

  /**
   * Доступна или не доступна кнопка сохранения.
   * Показывать или нет сообщение сохранения.
   */
  public canSave(): boolean {
    if (this.regime == 'view' || this.userChangedData == false) {
      return false;
    }
    if (this.canSaveElementsCount == 0 && this.canSaveElementsTypesCount == 0 && this.canSaveObject == false) {
      return false;
    }
    if (this.type == 'object' && this.regime == 'add' && (this.canSaveObject == false || this.elementTree && this.elementTree.length < 2)) {
      return false;
    }
    if (this.elementTree.find(item => !item.localId && !item.addressRegistryId && !item.addressTreeManual)) {
      return false;
    }
    return true;
  }

  /**
   * Сохранить данные в режимах add и edit
   */
  public saveData() {
    let component = this.elementCard
      ? this.elementCard
      : this.objectCard;
    if (component && component.form.invalid && component.activeTabKey == 'MANUAL') {
      component.checkFields();
      return;
    }
    if (this.regime == 'add') {
      this.saveNewTree();
    }
    else {
      this.saveChanges();
    }
  }

  /**
   * Сохранение нового объекта / дерева адресообразующих элементов
   */
  private saveNewTree() {
    this.isLoading = true;
    let addressTreeElements: ICreateAddressTreeElement[] = this.elementTree.map(
      item => {
        return {
          addressRegistryId: item.addressRegistryId ? item.addressRegistryId : null,
          addressTreeManual: item.addressTreeManual ?
            <ICreateAddressElement>this.addressCardService.prepareDatesForBackend(item.addressTreeManual)
            : null,
          localId: item.localId ? item.localId : null,
          position: item.position,
          sourceType: item.sourceType
        }
      }
    );
    let data: ICreateAddressTree = {
      addressTreeElements,
      nsiAddress: null
    };
    // добавляем к дереву объект
    if (this.type == 'object') {
      data.nsiAddress = {
        addressManual: this.object.addressManual ?
          <ICreateAddressObject>this.addressCardService.prepareDatesForBackend(this.object.addressManual)
          : null,
        addressRegistryId: this.object.addressRegistryId,
        sourceType: this.object.sourceType
      }
    }
    this.addressApiService.addTree(data).subscribe(
      (res) => {
        this.isLoading = false;
        this.toastr.success(
          this.translateService.instant(`ADDRESS_CARD.SUCCESS.ADD_${'object' ? 'OBJECT' : 'ELEMENT'}`),
          this.translateService.instant('GENERAL.SUCCESS_SAVE')
        );
        if (this.OnSave) {
          this.OnSave();
        }
        this.closeModal();
      },
      (error) => {
        this.isLoading = false;
        this.toastr.error(
          this.translateService.instant(`ADDRESS_CARD.ERRORS.ADD_${'object' ? 'OBJECT' : 'ELEMENT'}`),
          this.translateService.instant('GENERAL.ERROR_SAVE')
        );
      }
    )
  }

  /**
   * Сохранить изменения в режиме Edit
   */
  private saveChanges() {
    this.isLoading = true;
    let changedElements: ICreateAddressElement[] = this.elementTree
      .filter((item, i) => {
        let res = this.utilsService.compareObjects(item.addressTreeManual, this.originalElementTree[i].addressTreeManual);
        return res != null ? !res : false;
      })
      .map(item => {
        return <ICreateAddressElement>this.addressCardService.prepareDatesForBackend(
          {
            ...item.addressTreeManual,
            id: item.localId
          }
        );
      });

    let reqs: Observable<number>[] = changedElements.map(el => {
      return this.addressApiService.updateElement(el)
    });

    if (this.type == 'object' && this.object.addressManual) {
      let obj = <ICreateAddressObject>this.addressCardService.prepareDatesForBackend(
        {
          ...this.object.addressManual,
          addressTreeId: this.elementTreeId
        }
      );
      reqs.push(this.addressApiService.updateObject(obj));
    }
    forkJoin(reqs).subscribe(
      (res) => {
        this.isLoading = false;
        this.toastr.success(
          this.translateService.instant(`ADDRESS_CARD.SUCCESS.UPDATE_${'object' ? 'OBJECT' : 'ELEMENT'}`),
          this.translateService.instant('GENERAL.SUCCESS_SAVE')
        );
        if (this.OnSave) {
          this.OnSave();
        }
        this.closeModal();
      },
      (error) => {
        this.isLoading = false;
        this.toastr.error(
          this.translateService.instant(`ADDRESS_CARD.ERRORS.UPDATE_${'object' ? 'OBJECT' : 'ELEMENT'}`),
          this.translateService.instant('GENERAL.ERROR_SAVE')
        );
      }
    );
  }

  /**
   * Открыть импорт адреса.
   * Если пользователь изменил данные, то сначала показать модал подтверждения действия.
   */
  public importAddress() {
    if (this.userChangedData) {
      this.modalService.show(
        ConfirmModalComponent,
        {
          question: this.translateService.instant('ADDRESS_CARD.IMPORT_ADDRESS_CONFIRM_TITLE'),
          applyTitle: this.translateService.instant('ADDRESS_CARD.IMPORT_ADDRESS_CONFIRM'),
          cancelTitle: this.translateService.instant('GENERAL.CANCEL'),
          onApply: () => {
            this.openImportAddressModal();
          },
          onCancel: () => {

          }
        },
        'onCancel',
        {
          centered: true,
          windowClass: 'modal-confirm',
        }
      );
    }
    else {
      this.openImportAddressModal();
    }
  }

  private openImportAddressModal() {
    this.modalService.show(
      ImportAddressComponent,
      {
        type: this.type,
        OnApply: (obj: ICreateAddressTreeObject, tree: ICreateAddressTreeElement[]) => {
          this.isLoading = true;
          this.userChangedData = true;
          this.object = obj;
          this.elementTree = tree;
          setTimeout(() => {
            this.updateSaveCounts();
            if (this.object) {
              this.treeSelect$.next('object');
            }
            else {
              this.treeSelect$.next(this.elementTree.length - 1);
            }
            this.isLoading = false;
          }, 1000);
        }
      },
      'OnClose',
      {
        centered: true,
        windowClass: 'dictionary-modal dictionary-modal-full-width',
      }
    )
  }

  public confirmDelete() {
    let question = this.type == 'element'
      ? this.translateService.instant(`ADDRESS_CARD.CONFIRM_DELETE_ELEMENT`, { name: this.elementTree[this.elementTree.length - 1].name })
      : this.translateService.instant('ADDRESS_CARD.CONFIRM_DELETE_OBJECT');
    this.modalService.show(
      ConfirmModalComponent,
      {
        question,
        applyTitle: this.translateService.instant('GENERAL.DELETE'),
        cancelTitle: this.translateService.instant('GENERAL.CANCEL'),
        onApply: () => {
          this.type == 'element'
            ? this.delete('ELEMENT', this.elementTree[this.elementTree.length - 1].localId)
            : this.delete('OBJECT', this.object.localId);
        },
        onCancel: () => { }
      },
      'onCancel',
      {
        centered: true,
        windowClass: 'modal-confirm',
      }
    );
  }

  private delete(mode: 'OBJECT' | 'ELEMENT', id: number) {
    this.isLoading = true;
    const req = mode == 'OBJECT'
      ? this.addressApiService.deleteObject(id)
      : this.addressApiService.deleteElement(id);
    req.subscribe(
      (res) => {
        this.isLoading = false;
        this.toastr.success(
          this.translateService.instant(`ADDRESS_CARD.SUCCESS.DELETE_${mode}`),
          this.translateService.instant('GENERAL.SUCCESS_DELETE')
        );
        if (this.OnSave) {
          this.OnSave();
        }
        this.closeModal();
      },
      (error) => {
        this.isLoading = false;
        this.toastr.error(
          error && error.error && error.error.message ? error.error.message : '',
          this.translateService.instant(`ADDRESS_CARD.ERRORS.DELETE_${mode}`)
        );
      }
    );
  }
}
