import {Component, forwardRef, Output, EventEmitter, Input, OnChanges, ChangeDetectorRef} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {ISelectItem} from "@core/interfaces/select-item";

@Component({
  selector: 'app-simple-select',
  templateUrl: './simple-select.component.html',
  styleUrls: ['./simple-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleSelectComponent),
      multi: true
    }
  ]
})
export class SimpleSelectComponent implements OnChanges, ControlValueAccessor {

  @Input() selected: string | number = null; // выбранное значение
  @Input() list: ISelectItem[] = []; // список значений
  @Input() title: string = null; // тайтл при отсутствии выбранного значения (placeholder)
  @Input() forFilter: boolean = false; // флаг типа селекта - для панели фильтров или для полей
  @Input() disabled: boolean = false; // блокировка
  @Input() withStr: boolean = false; // со стрелкой
  @Input() withManyRow: boolean = false; // отображение значения в несколько строк
  @Input() withChildren: boolean = false; // 2-х уровневый список
  @Input() openDirection: string = 'bottom center'; // направление открытия
  @Input() withRemove = false; // флаг отображение иконки сброса значения
  @Output() OnSelect: EventEmitter<ISelectItem> = new EventEmitter<ISelectItem>();

  public selectedKey: string | number = null; // выбранное значение - key
  public selectedValue: string = null; // выбранное значение - value
  public openedSelect: boolean = false; // флаг открытия выпадающего списка
  public enableClickOutside: boolean = false; // Флаг управления работы outsideClickEvent

  public _onChange: any = () => {};
  public _onTouch: any = () => {};

  constructor(private changeDetection: ChangeDetectorRef) { }

  ngOnChanges() {
    this.changeValue(this.selected);
  }

  private changeValue(key: string | number) {
    if(this.list && this.list.length > 0) {
      if (!!key) {
        let listItem = null;
        if (this.withChildren) {
          const listItemParent = this.list.find((item: ISelectItem) => {
            const listItemChild = item.children && item.children.length > 0
              ? item.children.find((child: ISelectItem) => child.key.toString() === key.toString())
              : null;
            return !!listItemChild;
          });
          listItem = listItemParent
            ? listItemParent.children.find((item: ISelectItem) => item.key.toString() === key.toString())
            : null;
        } else {
          listItem = this.list.find((item: ISelectItem) => item.key.toString() === key.toString());
        }
        this.selectedKey = listItem? listItem.key : null;
        this.selectedValue = listItem? listItem.value : null;
      } else {
        this.selectedKey = null;
        this.selectedValue = null;
      }
      this._onChange(this.selectedKey);
      this.changeDetection.detectChanges();
    }
  }

  // функция открытия выпадающего списка
  public openSelect(event, open: boolean): void {
    if (this.disabled === true) {
      return;
    }
    this.openedSelect = open;
    this.enableClickOutside = open;
  }

  public removeSelection(): void {
    this.writeValue(null);
    this.OnSelect.emit({key: this.selectedKey, value: null});
  }

  // функция выбора значения
  public onSelectItem(item: ISelectItem): void {
    this.selectedKey = item.key;
    this.selectedValue = item.value;
    this._onChange(this.selectedKey);
    this.OnSelect.emit(item);
    this.openedSelect = false;
    this.enableClickOutside = false;
    this.writeValue(this.selectedKey);
  }

  writeValue(value: any): void {
    this.selectedKey = !!value && typeof value === 'object' ? value.key : value;
    this.selectedValue = !!value && typeof value === 'object' ? value.value : null;
    this.selected = this.selectedKey;
    this.changeValue(this.selectedKey);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
