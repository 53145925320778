import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { ICreateAddressTreeElement, ICreateAddressTreeObject } from "@core/interfaces/address";

@Component({
  selector: 'app-address-tree',
  templateUrl: './address-tree.component.html',
  styleUrls: ['./address-tree.component.scss']
})
export class AddressTreeComponent implements OnInit, OnChanges {

  /**
   * выбранный адресный объект
   */
  @Input() object: ICreateAddressTreeObject = null;
  /**
   * дерево элементов
   */
  @Input() tree: ICreateAddressTreeElement[] = [];

  /**
   * режим - добавление, редактирование, просмотр
   */
  @Input() regime: 'view' | 'edit' | 'add' = 'view';

  /**
   * тип - element, object
   */
  @Input() type: 'element' | 'object' = null;

  /**
   * Событие выбора элемента дерева извне
   */
  @Input() treeSelect$: Subject<number | 'object'> = null;

  /**
   * Событие выбора элемента дерева
   */
  @Output() onElementSelect: EventEmitter<ICreateAddressTreeElement> = new EventEmitter<ICreateAddressTreeElement>();
  /**
   * Событие выбора адресного объекта
   */
  @Output() onObjectSelect: EventEmitter<ICreateAddressTreeObject> = new EventEmitter<ICreateAddressTreeObject>();
  /**
   * Событие удаления адресного элемента
   */
   @Output() onElementRemove: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Событие удаления адресного объекта
   */
  @Output() onObjectRemove: EventEmitter<any> = new EventEmitter<any>();

  public selectedIndex: number | 'object'; // индекс выбранного элемента / объекта

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    if (this.treeSelect$) {
      this.treeSelect$.subscribe(
        res => {
          if (typeof (res) == 'number') {
            this.selectElement(res);
          }
          else {
            this.selectObject();
          }
        }
      )
    }
  }

  ngOnChanges(): void {
    // первоначальный выбор элемента / объекта при загрузке
    if (!this.selectedIndex && this.tree && this.tree.length > 0) {
      if (this.regime != 'add') {
        if (this.type == 'element') {
          this.selectElement(this.tree.length - 1);
        }
        else {
          this.selectObject();
        }
      }
      else {
        this.selectElement(0);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.treeSelect$) {
      this.treeSelect$.unsubscribe();
    }
  }

  public selectElement(index: number) {
    if (this.selectedIndex != index) {
      this.selectedIndex = index;
      this.onElementSelect.next({ ...this.tree[index] });
    }
  }

  public selectObject() {
    if (this.selectedIndex != 'object') {
      this.selectedIndex = 'object';
      this.onObjectSelect.next({ ...this.object });
    }
  }

  /**
   * Вставить элемент в дерево
   */
  public insertElement(parentIndex: number) {
    this.tree.forEach((element, index) => {
      if (parentIndex < index) {
        element.position++;
      }
    });

    this.tree.splice(
      parentIndex + 1,
      0,
      {
        addressRegistryId: null,
        addressTreeManual: null,
        localId: null,
        position: this.tree[parentIndex].position + 1,
        name: null,
        objectTypeFullName: this.translateService.instant('ADDRESS_CARD.TREE_NEW_ELEMENT'),
        parentId: this.tree[parentIndex].localId ? this.tree[parentIndex].localId : null,
        sourceType: null
      }
    );
  }

  public addElement() {
    this.tree.push({
      addressRegistryId: null,
      addressTreeManual: null,
      localId: null,
      position: this.tree.length,
      name: null,
      objectTypeFullName: this.translateService.instant('ADDRESS_CARD.TREE_NEW_ELEMENT'),
      parentId: this.tree[this.tree.length - 1].localId ? this.tree[this.tree.length - 1].localId : null,
      sourceType: null
    });
    this.selectElement(this.tree.length - 1);
  }

  /**
   * Убирает элемент и все следующие элементы из дерева
   * @param index индекс элемента
   */
  public removeElement(index: number) {
    this.tree.splice(index, this.tree.length - index);
    this.selectElement(index - 1);
    this.onElementRemove.emit();
  }

  public removeObject() {
    this.object = null;
    this.onObjectRemove.emit();
    if (this.selectedIndex === 'object') {
      this.selectElement(this.tree.length - 1);
    }
  }

  public addObject() {
    this.object = {
      addressManual: null,
      addressRegistryId: null,
      sourceType: null,

      localId: null,
      name: null,
      objectTypeFullName: this.translateService.instant('ADDRESS_CARD.TREE_NEW_OBJECT')
    };
    this.selectObject();
  }

  /**
   * Можно ли вставить элемент в дерево.
   */
  public canInsertElement(index: number) {
    // только перед элементом или объектом, заполненным вручную
    // только в режиме создания
    if (this.regime == 'add' &&
      (this.tree[index + 1]
        && this.tree[index + 1].localId == null
        && this.tree[index + 1].addressRegistryId == null
        && this.tree[index + 1].addressTreeManual) ||
      (!this.tree[index + 1]
        && this.object
        && !this.object.localId
        && !this.object.addressRegistryId
        && this.object.addressManual)
    ) {
      return true;
    }
    return false;
  }

  /**
   * Показывать ли блок добавления элементов / объекта
   */
  public canAddElementOrObject(): boolean {
    // только в режиме создания
    if (this.regime != 'add') {
      return false;
    }
    // только если выбран первый элемент (субъект РФ)
    if (!this.tree[0].localId && !this.tree[0].addressRegistryId) {
      return false;
    }
    if (this.object) {
      return false;
    }
    return true;
  }

  public canAddElement(): Boolean {
    return !this.object;
  }

  /**
   * Можно ли добавить объект
   */
  public canAddObject(): boolean {
    return this.type == 'object' && this.tree && this.tree.length > 1 && !this.object;
  }

  /**
   * Можно ли убрать элемент из дерева
   * @param item элемент дерева
   */
  public canRemoveElementFromTree(item: ICreateAddressTreeElement): boolean {
    // только в режиме создания, первый элемент (субъект РФ) убрать нельзя
    return this.regime == 'add' && item.position != 0;
  }

  public firstLatterToUpperCase(value: string): string {
    if (!value) {
      return '';
    }
    return value[0].toUpperCase() + value.substr(1).toLowerCase();
  }
}
