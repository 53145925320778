<div class="ImportAddressClass">
    <div class="modal-header">
        <div class="universal-card-title">
            <span>{{'ADDRESS_CARD.IMPORT_ADDRESS' | translate}}</span>
        </div>
    </div>
    <div class="import-address-body">
        <app-search-field [debounce]="1000" (OnSearch)="searchObjects($event)"></app-search-field>
        <div class="import-address-list scroll-block">
            <app-loader [show]="searchLoading"></app-loader>
            <div *ngFor="let item of searchResults"
                 class="import-address-item"
                 [class.selected]="item == selectedItem"
                 (click)="selectedItem = item">
                <span class="content">{{item.name}}</span>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <app-button class="modal-footer-btn"
                    [title]="'GENERAL.CANCEL' | translate"
                    (OnClick)="closeModal()">
        </app-button>
        <app-button class="modal-footer-btn blue"
                    [title]="'GENERAL.APPLY' | translate"
                    [disabled]="!selectedItem"
                    (OnClick)="applySelectedObject()">
        </app-button>
    </div>

</div>
