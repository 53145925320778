<div class="calendarClass"
     (clickOutside)="openCalendar($event, !enableClickOutside)"
     [clickOutsideEnabled]="enableClickOutside">
  <app-date-time-input class="calendar-input"
                       [selected]="selected"
                       [id]="id"
                       [class.opened]="openedCalendar"
                       [disabled]="disabled"
                       [noManualInput]="noManualInput"
                       [format]="format"
                       [hasError]="hasError || hasErrorMin || hasErrorMax"
                       [errorText]="hasError ? errorText : hasErrorMin ? errorMin : hasErrorMax ? errorMax : null"
                       (OnSelect)="changeDate($event)"
                       (click)="openCalendar($event, !enableClickOutside)"
  ></app-date-time-input>
  <div class="calendar-balloon {{openDirection}}" [class.opened]="openedCalendar">
    <app-calendar-month class="calendar-balloon-block"
                        [id]="id"
                        [selected]="selected"
                        [format]="format"
                        [withMinutes]="withMinutes"
                        [withSeconds]="withSeconds"
                        [withTime]="withTime"
                        [minDate]="minDateForCalendar"
                        [maxDate]="maxDateForCalendar"
                        [maxYear]="maxYear"
                        [minYear]="minYear"
                        [maxMonth]="maxMonth"
                        [minMonth]="minMonth"
                        [isNeedLimit]="isNeedLimit"
                        (OnSelect)="selectDateTime($event)"
    ></app-calendar-month>
  </div>
</div>
