import {Component, EventEmitter, Input, Output} from '@angular/core';
import { IUserMenu } from '@app/core/interfaces/user';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent {

  @Input() item: IUserMenu;
  @Input() mainItem:boolean = false;
  @Output() changeOpen: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  public changeOpened() {
    if (this.item.isOpened) {
      this.item.isOpened = false;
      this.item.children?.forEach(child => {
        child.isOpened = false;
      });
    }
    else {
      this.item.isOpened = true;
    }
    this.changeItemOpen();
  }

  public changeItemOpen() {
    this.changeOpen.emit();
  }
}
