import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";
import { map, share } from "rxjs/operators";
import {
  IDeviceDictionaryParams,
  IDeviceModelDictionaryParams,
  IDictionaryBody,
  IDictionaryParams, IDictionaryParamsWithActual,
  IMeasureToolDictionaryParams,
  IMeasureToolTypeDictionaryParams,
  IOrganizationRoleDictionaryParams,
  ISystemTypesParams,
  ITransformRatioDictionaryParams,
  IUiObjectDictionaryParams,
  IVendorDictionaryParams,
} from "@core/interfaces/dictionary";
import { Observable } from "rxjs";
import { ISelectItem } from "@core/interfaces/select-item";
import { IAddressTreeDictionaryParams } from "@core/interfaces/address";
import { MeasureObjectType, ResourceTypeCode } from "@app/app.enums";

@Injectable({
  providedIn: 'root'
})
export class DictionaryApiService {

  constructor(private HttpClient: HttpClient) { }

  // Справочник "Адреса" (локальные)
  public getAddressDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/address/object/local`, params
    ).pipe(share());
  }

  // Справочник "Вид оборудования" (список)
  public getDeviceTypeList(codes: string[], isShortName: boolean = false): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/device-type`,
      { codes: codes, isShortName: isShortName }
    ).pipe(share());
  }

  // Справочник "Тип ресурса" (список)
  public getResourceTypeList(codes: string[], isShortName: boolean = false): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/resource-type`,
      { codes: codes, isShortName: isShortName }
    ).pipe(share());
  }

  // Справочник "Роли пользователя"
  public getRolesDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/user-role`, params
    ).pipe(share());
  }

  // Справочник "Группы пользователя" (список)
  public getGroupsList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/group`, {}
    ).pipe(share());
  }

  // Справочник "Группы пользователя"
  public getGroupsDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/user-group`, params
    ).pipe(share());
  }

  // Справочник "Производитель" (список)
  public getVendorsList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/vendor`, {}
    ).pipe(share());
  }

  // Справочник "Производитель"
  public getVendorsDictionary(params: IVendorDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/vendor`, params
    ).pipe(share());
  }

  // Справочник "Наименование средства измерения" (список)
  public getMeasureToolsList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/measure-tool`, {}
    ).pipe(share());
  }

  // Справочник "Наименование средства измерения"
  public getMeasureToolsDictionary(params: IMeasureToolDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/measure-tool`, params
    ).pipe(share());
  }

  // Справочник "Наименование средства измерения"
  public getMeasureToolTypeDictionary(params: IMeasureToolTypeDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/measure-tool-type`, params
    ).pipe(share());
  }

  // Справочник "Модель оборудования" (список)
  public getDeviceModelsList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/device-model`, {}
    ).pipe(share());
  }

  // Справочник "Модель оборудования"
  public getDeviceModelsDictionary(params: IDeviceModelDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/device-model`, params
    ).pipe(share());
  }

  // Справочник "Организации"
  public getOrganizationsDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/organization`, params
    ).pipe(share());
  }

  // Справочник "Роли организации"
  public getOrganizationRoleDictionary(params: IOrganizationRoleDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/organization-role`, params
    ).pipe(share());
  }

  // Справочник "Тип системы" (список)
  public getSystemTypesList(params: ISystemTypesParams = {}): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/system-type`, params
    ).pipe(share());
  }

  // Справочник "Тип точки учета" (список)
  public getMeasurePointTypesList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/measure-point-type`, {}
    ).pipe(share());
  }

  // Справочник "Тип подключения" (список)
  public getLinkTypesList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/link-type`, {}
    ).pipe(share());
  }

  // Справочник "Тип учета" (список)
  public getAccountingTypesList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/accounting-type`, {}
    ).pipe(share());
  }

  // Справочник "Элементы UI"
  public getUiObjectDictionary(params: IUiObjectDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/ui-object`, params
    ).pipe(share());
  }

  // Справочник "Тип холодного водоснабжения" (список)
  public getWaterSystemTypesList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/water-system-type`, {}
    ).pipe(share());
  }

  // Справочник "Способ водоподготовки" (список)
  public getWaterTreatmentTypesList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/water-treatment-type`, {}
    ).pipe(share());
  }

  // Справочник "Тип теплоносителя" (список)
  public getHeatTransferTypeList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/heat-transfer-type`, {}
    ).pipe(share());
  }

  // Справочник "Тип сети" (список)
  public getNetworkTypeList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/network-type`, {}
    ).pipe(share());
  }

  // Справочник "Тип объекта учета"
  public getMeasureObjectTypeDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/measure-object-type`, params
    ).pipe(share());
  }

  // Справочник "Тип объекта учета" (список)
  public getMeasureObjectTypeTreeDictionary(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/measure/object/dict/type`, {}
    ).pipe(share());
  }

  // Справочник "Адреса. Состояние дома" (список)
  public getAddressBuildingStateList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/address-building-state`, {}
    ).pipe(share());
  }

  // Справочник "Адреса. Состояние дома"
  public getAddressBuildingStateDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/address-building-state`, params
    ).pipe(share());
  }

  // Справочник "Адреса. Уровни"
  public getAddressObjectLevelList(isOnlyVisibleRecords: boolean = false): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/address-object-level`,
      { isOnlyVisibleRecords: isOnlyVisibleRecords }
    ).pipe(share());
  }

  // Справочник "Адреса. Вид строения" (список)
  public getAddressBuildingTypeList(codes: string[], isShortName: boolean = false): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/address-building-type`,
      { codes: codes, isShortName: isShortName }
    ).pipe(share());
  }

  // Справочник "Адреса. Вид строения"
  public getAddressBuildingTypeDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/address-building-type`, params
    ).pipe(share());
  }

  // Справочник "Адреса. Вид владения (недвижимости)" (список)
  public getAddressEstateTypeList(codes: string[], isShortName: boolean = false): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/address-estate-type`,
      { codes: codes, isShortName: isShortName }
    ).pipe(share());
  }

  // Справочник "Адреса. Вид владения (недвижимости)"
  public getAddressEstateTypeDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/address-estate-type`, params
    ).pipe(share());
  }

  // Справочник "Адреса. Тип и сокращение адресообразующего элемента"
  public getAddressObjectTypeDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/address-object-type`, params
    ).pipe(share());
  }

  // Справочник "Адреса. Адресообразующий элемент"
  public getAddressTreeDictionary(params: IAddressTreeDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/address-tree`, params
    ).pipe(share());
  }

  // Справочник "Коэффициент трансформации"
  public getTransformationRatio(params: ITransformRatioDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/transformation-ratio`, params
    ).pipe(share());
  }

  // Справочник "Тип прибора учета" (список)
  public getDeviceMeterType(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/device-meter-type`, {}
    ).pipe(share());
  }

  // Справочник "Тип Коэффициента трансформации" (список)
  public getTransformationRatioType(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/transformation-ratio-type`, {}
    ).pipe(share());
  }

  // Справочник "Адреса. Id объекта учета"
  public getMeasureObjectIdDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/measure/object/dict/ids`, params
    ).pipe(share());
  }

  // Справочник "Адреса. Id оборудования"
  public getDeviceIdDictionary(params: IDeviceDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/device/dict/ids`, params
    ).pipe(share());
  }

  // Справочник "Адреса. Серийный номер оборудования"
  public getDeviceSerialNumberDictionary(params: IDeviceDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/device/dict/serials`, params
    ).pipe(share());
  }

  // Справочник "Статусы объекта (ОУ, УСПД, УПД, ПУ, ТУ, ИУ)" (список)
  public getMeasureObjectStateList(codes: MeasureObjectType[], isShortName: boolean = false): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/measure/object/dict/state/list`,
      { codes: codes, isShortName: isShortName }
    ).pipe(share());
  }

  // Справочник "Часовые зоны"
  public getTimeZoneDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/time-zone`, params
    ).pipe(share());
  }

  /**
   * Список временных зон
   */
  public getTimeZoneList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/time-zone`, {})
      .pipe(share());
  }

  // Справочник "Уровень сигнала GSM" (список)
  public getGsmSignalLevelList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/gsm-signal-level`,
      {}
    ).pipe(share());
  }

  // Справочник "Тип активного подключения" (список)
  public getActiveConnectTypeList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/active-connection-type`,
      {}
    ).pipe(share());
  }

  // Справочник "Тип трубопровода" (список)
  public getPipelineTypeList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/pipeline-type`,
      {}
    ).pipe(share());
  }

  // Справочник "Четность прибора учета" (список)
  public getDeviceMeterParityList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/device-meter-parity`,
      {}
    ).pipe(share());
  }

  // Справочник "Тип подключения модема" (список)
  public getModemConnectTypeList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/modem-connection-type`,
      {}
    ).pipe(share());
  }

  // Справочник "Тип порта" (список)
  public getPortTypeList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/port-type`,
      {}
    ).pipe(share());
  }

  // Справочник "Тип TCP порта" (список)
  public getTcpPortTypeList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/tcp-port-type`,
      {}
    ).pipe(share());
  }

  // Справочник "Номер порта УСД" (список)
  public getUsdPortNumberList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/usd-port-number`,
      {}
    ).pipe(share());
  }

  // Справочник "Пользователи"
  public getUserDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/user`, params
    ).pipe(share());
  }

  // Справочник "Тип события"
  public getEventTypeDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/event-type`, params
    ).pipe(share());
  }

  // Справочник "Категория события"
  public getEventCategoryDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/event-category`, params
    ).pipe(share());
  }

  // Справочник "Тип события" для микросервисов
  public getEventTypeDictionaryForEventLog(remoteService: string): Observable<ISelectItem[]> {
    return this.HttpClient.get<ISelectItem[]>(
      `${environment.API_URL}/event/log/dict/${remoteService}/event/type`
    ).pipe(share());
  }

  // Справочник "Категория события" для микросервисов
  public getEventCategoryDictionaryForEventLog(remoteService: string): Observable<ISelectItem[]> {
    return this.HttpClient.get<ISelectItem[]>(
      `${environment.API_URL}/event/log/dict/${remoteService}/event/category`
    ).pipe(share());
  }

  // Справочник "Единицы измерения физических величин" (список)
  public getMeasureUnitList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/measure-unit`,
      {}
    ).pipe(share());
  }

  // Справочник "Тип данных прибора учета" (список)
  public getMeasureParamTypeList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/measure-param-type`,
      {}
    ).pipe(share());
  }

  // Справочник "Параметры учета ресурсов" (список)
  public getMeasureParamList(measureParamTypeId?: number, resourceTypeCode?: ResourceTypeCode): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/measure-param`,
      { measureParamTypeId: measureParamTypeId, resourceType: resourceTypeCode }
    ).pipe(share());
  }

  // Справочник "Режим работы теплосчетчика" (список)
  public getHeatMeterWorkModeList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/heat-meter-work-mode`,
      {}
    ).pipe(share());
  }

  // Справочник "Тип фотографии" (список)
  public getPhotoTypeList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/photo-type`,
      {}
    ).pipe(share());
  }

  // Справочник "Тип документа" (список)
  public getDocumentTypeList(codes: string[]): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/document-type`,
      { codes: codes }
    ).pipe(share());
  }

  /**
   * Справочник "Тип пароля электросчетчика" (список)
   * @param codes
   */
  public getEEPasswordTypeList(codes: string[]): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/password-type-ee`,
      { codes }
    )
  }

  // Справочник "Тип события ИВК" (список)
  public getIvkEventTypeList(codes: string[] = null): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/ivk-event-type`,
      { codes: codes }
    ).pipe(
      map((items: ISelectItem[]) => {
        const result: ISelectItem[] = items && items.length > 0
          ? items.map((l) => { return { key: l.ext && l.ext.code ? l.ext.code : l.key, value: l.value }; })
          : [];
        return result;
      }), share());
  }

  /**
   * Справочник Режим работы TCP порта
   */
  public getTcpPortModeList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/tcp-port-mode`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник Класс точности счетчиков электроэнергии
   * @param codes коды
   * @returns
   */
  public getPuEeAccuracyClassList(codes: string[] = []): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/accuracy-class-ee`,
      { codes }
    ).pipe(share());
  }

  /**
   * Справочник Тип загрузки
   */
  public getUploadTypesList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/import-type`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник Статусы загрузки
   * @returns
   */
  public getUploadStatesList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/monitoring/import/dict/state`,
      {}
    ).pipe(share());
  }

  // Справочник "Категории объекта учета" (список)
  public getMeasureObjectCategoryList(): Observable<ISelectItem[]> {
    return this.HttpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/measure-object-category`, {}
    ).pipe(share());
  }

  // Справочник "Военный городок"
  public getMilTownDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/military-town`, params
    ).pipe(share());
  }

  // Справочник "Номер по ГП"
  public getMOGenplanNumberDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.HttpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/measure-object-genplan-number`,
      params,
    ).pipe(share());
  }

}
