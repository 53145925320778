<div class="universalCardClass"
     [class.full-width]="fields && fields.length > 2"
     [class.half-width]="fields && fields.length === 2">
  <app-loader [show]="isLoading"></app-loader>
  <div class="modal-header">
    <div class="universal-card-title">
      <span *ngIf="regime === 'add'">{{'GENERAL.NEW_ROW' | translate}}</span>
      <span *ngIf="regime !== 'add'">{{title}}</span>
    </div>
    <span *ngIf="regime === 'edit'"
          class="font-icons icon-delete universal-card-delete"
          (click)="deleteCard()"
    ></span>
  </div>
  <div class="modal-body">
    <div class="universal-card-content scroll-block">
      <form class="universal-card-form" [formGroup]="form">
        <div *ngFor="let field of fields"
             class="universal-card-form-item"
             [class.one-field]="fields && fields.length === 1"
             [class.two-field]="fields && fields.length === 2">
          <div class="universal-card-form-item-label">
            <span class="form-field-label" [class.required]="field.required">{{field.name}}</span>
          </div>
          <div class="universal-card-form-item-field">
            <app-input *ngIf="(field.type === colType.VARCHAR || field.type === colType.BIGINT
            || field.type === colType.INTEGER || field.type === colType.NUMBER) && !field.refDictCode"
                       [formControl]="form.controls[field.code]"
                       class="universal-card-form-item-input"
                       [class.submitted]="isSubmitted"
                       [disablePlaceholderAnimation]="true"
                       [disableControl]="field.readonly || regime === 'view'"
                       [pattern]="field.pattern"
                       [inputId]="field.code"
                       (OnChange)="changeField($event, field.code)"
            ></app-input>
            <app-calendar *ngIf="field.type === colType.TIMESTAMP"
                          [formControl]="form.controls[field.code]"
                          [id]="field.code"
                          class="universal-card-form-item-input"
                          [isNeedLimit]="false"
                          [format]="dateFormat"
                          [disableControl]="field.readonly || regime === 'view'"
                          (OnSelect)="changeField($event, field.code)">
            </app-calendar>
            <app-checkbox *ngIf="field.type === colType.BOOL"
                          [formControl]="form.controls[field.code]"
                          [name]="field.code"
                          class="universal-card-form-item-select"
                          [disableControl]="field.readonly || regime === 'view'"
                          (OnChange)="changeField($event, field.code)">
            </app-checkbox>
            <app-simple-select *ngIf="field.refDictCode && field.refDictMode === filterType.select"
                               class="universal-card-form-item-select"
                               [formControl]="form.controls[field.code]"
                               [list]="field.refList | async"
                               [disableControl]="field.readonly || regime === 'view'"
                               [withRemove]="true"
                               [openDirection]="'bottom left'"
                               [title]="'GENERAL.SELECT' | translate"
                               (OnSelect)="changeField($event, field.code)">
            </app-simple-select>
            <app-simple-dictionary *ngIf="field.refDictCode && field.refDictMode === filterType.simpleDictionary"
                                   class="universal-card-form-item-select"
                                   [formControl]="form.controls[field.code]"
                                   [disableControl]="field.readonly || regime === 'view'"
                                   [loadingInProgress]="field.refDictionary.dictLoading"
                                   [title]="field.name"
                                   [list]="field.refDictionary.dictList"
                                   [params]="field.refDictionary.dictParams"
                                   (OnSelect)="changeField($event, field.code)"
                                   (OnLoadList)="updateDictionaryList($event, field.refDictionary)"
            ></app-simple-dictionary>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <app-button class="modal-footer-btn universal-card-footer-btn"
                (click)="close()"
                [title]="'GENERAL.CANCEL' | translate"
    ></app-button>
    <app-button *ngIf="regime !== 'view'"
                class="modal-footer-btn universal-card-footer-btn blue"
                [disabled]="!hasUnsavedFields"
                (click)="saveCard()"
                [title]="'GENERAL.SAVE' | translate"
    ></app-button>
  </div>
</div>
