<div class="rangeCalendarClass"
     (clickOutside)="openCalendar($event, !enableClickOutside)"
     [clickOutsideEnabled]="enableClickOutside">
  <div class="range-calendar-inputs">
    <app-date-time-input class="range-calendar-input"
                         [selected]="selected.start"
                         id="start-{{id}}"
                         [class.opened]="openedCalendar"
                         [disabled]="disabled"
                         [noManualInput]="noManualInput"
                         [withoutClear]="withoutClear"
                         [format]="format"
                         [hasError]="hasErrorStart || hasErrorStartInner"
                         [errorText]="hasErrorStart ? errorStart : hasErrorStartInner ? errorStartInner : null"
                         (OnSelect)="changeDate($event, 'start')"
                         (click)="openCalendar($event, !enableClickOutside)"
    ></app-date-time-input>
    <span class="range-calendar-separator">—</span>
    <app-date-time-input class="range-calendar-input"
                         [selected]="selected.end"
                         id="end-{{id}}"
                         [class.opened]="openedCalendar"
                         [disabled]="disabled"
                         [noManualInput]="noManualInput"
                         [withoutClear]="withoutClear"
                         [format]="format"
                         [hasError]="hasErrorEnd || hasErrorMaxDate"
                         [errorText]="hasErrorEnd ? errorEnd : hasErrorMaxDate ? errorMaxDate : null"
                         [tooltipPlacement]="'top-right'"
                         (OnSelect)="changeDate($event, 'end')"
                         (click)="openCalendar($event, !enableClickOutside)"
    ></app-date-time-input>
  </div>
  <div class="range-calendar-balloon {{openDirection}}" [class.opened]="openedCalendar">
    <app-calendar-month class="range-calendar-balloon-block"
                        [id]="'start-' + id"
                        [selected]="selected.start"
                        [format]="format"
                        [asStartRange]="true"
                        [withMinutes]="withMinutes"
                        [withSeconds]="withSeconds"
                        [withTime]="withTime"
                        [maxYear]="startMaxYear"
                        [minYear]="startMinYear"
                        [maxMonth]="startMaxMonth"
                        [minMonth]="startMinMonth"
                        [minDate]="startMinDateForCalendar"
                        [maxDate]="startMaxDateForCalendar"
                        [isNeedLimit]="isNeedLimit"
                        (OnSelect)="selectDateTime($event, 'start')"
    ></app-calendar-month>
    <app-calendar-month class="range-calendar-balloon-block"
                        [id]="'end-' + id"
                        [selected]="selected.end"
                        [format]="format"
                        [asEndRange]="true"
                        [withMinutes]="withMinutes"
                        [withSeconds]="withSeconds"
                        [withTime]="withTime"
                        [maxYear]="endMaxYear"
                        [minYear]="endMinYear"
                        [maxMonth]="endMaxMonth"
                        [minMonth]="endMinMonth"
                        [minDate]="endMinDateForCalendar"
                        [maxDate]="endMaxDateForCalendar"
                        [isNeedLimit]="isNeedLimit"
                        (OnSelect)="selectDateTime($event, 'end')"
    ></app-calendar-month>
  </div>
</div>
