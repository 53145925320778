import { DateTimeService } from '@shared/services/date-time.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { IRangeDate } from '@app/core/interfaces/calendar';
import { ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-range-time-select',
  templateUrl: './range-time-select.component.html',
  styleUrls: ['./range-time-select.component.scss']
})
export class RangeTimeSelectComponent implements OnChanges, ControlValueAccessor {

  @Input() timeFormat: string = this.dateTimeService.getDateFormat('time');
  @Input() selected: IRangeDate = { start: null, end: null };
  @Input() id: string = null;
  @Input() withoutClear: boolean = false;
  @Input() disabled: boolean = false;

  @Output() OnSelect: EventEmitter<IRangeDate> = new EventEmitter<IRangeDate>();

  public _onChange: any = () => { };
  public _onTouch: any = () => { };

  constructor(
    private dateTimeService: DateTimeService
  ) { }

  ngOnChanges() {
    this.changeValue(this.selected);
  }

  public enterTime(value: string, type: 'start' | 'end') {
    switch (type) {
      case 'start': {
        this.selected.start = value && value !== '' ? value : null;
        break;
      }
      case 'end': {
        this.selected.end = value && value !== '' ? value : null;
        break;
      }
    }
    this.writeValue(this.selected);
    this.OnSelect.emit(this.selected);
  }


  changeValue(value: IRangeDate) {
    this.selected = value ? value : { start: null, end: null };
    this._onChange(this.selected);
  }

  writeValue(value: IRangeDate): void {
    this.selected = value ? value : { start: null, end: null };
    this.changeValue(this.selected);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }
}
