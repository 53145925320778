<div class="pageNotFoundClass page-content">
  <div class="content-block">
    <span class="font-icons icon-404 error-code"></span>
    <div class="error-description" [translate]="'PAGE_NOT_FOUND.ERROR_DESCRIPTION'"></div>
    <app-button class="error-btn blue to-main-from-404"
                [title]="'PAGE_NOT_FOUND.TO_MAIN_PAGE' | translate"
                (OnClick)="toMainPage()"
    ></app-button>
    <div class="error-logo">
      <span class="font-icons icon-logo2"></span>
    </div>
  </div>
</div>
