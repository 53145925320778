<div class="noAccessSoftwareRentContractClass page-content">
  <div class="no-access-contract-content">
    <span class="no-access-contract-icon font-icons icon-no_access"></span>
    <div class="no-access-contract-title">{{'NO_ACCESS_CONTRACT.TITLE' | translate}}</div>
    <div class="no-access-contract-description">{{'NO_ACCESS_CONTRACT.DESCRIPTION' | translate}}</div>
    <div class="no-access-contract-info">
      <div class="no-access-contract-phone">
        <span class="font-icons icon-phone"></span>
        <span class="no-access-contract-phone-text">{{supportPhone}}</span>
      </div>
      <div class="no-access-contract-mail">
        <span class="font-icons icon-mail"></span>
        <a href="mailTo:{{supportEmail}}"
           class="outer-href">{{supportEmail}}</a>
      </div>
    </div>
  </div>
</div>
