<div class="textareaClass">
  <textarea id="{{textareaId}}"
            class="textarea-component"
            name="{{textareaId}}"
            [maxlength]="maxLength"
            [style.height.px]="textareaHeight"
            [(ngModel)]="model"
            (focus)="emitFocusEvent()"
            (blur)="emitBlurEvent()"
            [disabled]="disabled"
            [placeholder]="placeholder"
            [readonly]="readonly"
  ></textarea>
</div>
