<div class="multiSelectClass" (clickOutside)="openSelect($event, !enableClickOutside)"
     [clickOutsideEnabled]="enableClickOutside">

  <!--Для фильтров, открытие по клику на заголовок фильтра-->
  <div *ngIf="forFilter"
       class="multi-select-for-filter"
       [class.disabled]="disabled">
    <div class="multi-select-head" [class.opened]="openedSelect">
      <div class="multi-select-title" (click)="openSelect($event, !openedSelect)">{{title}}</div>
      <ng-container [ngTemplateOutlet]="balloon"></ng-container>
    </div>
    <div class="multi-select-values" *ngIf="selectedItems && selectedItems.length > 0">
      <div *ngFor="let item of selectedItems | slice: 0: countShowValuesForFilter"
           class="multi-select-value"
           [class.with-remove]="selectedItems.length > 1"
           (click)="removeSelectItem(item)">
        <div class="multi-select-selected" [title]="item.value">
          <span>{{item.value}}</span>
        </div>
        <div class="multi-select-remove">
          <span class="font-icons icon-clear"></span>
        </div>
      </div>
      <div *ngIf="selectedItems.length > 1"
           class="multi-select-remove-all"
           (click)="removeSelection()">
        <span>{{selectedItems.length}}</span>
        <span class="font-icons icon-clear"></span>
      </div>
    </div>
  </div>

  <!--Для полей, открытие по клику на выбранное значение или плейсхолдер-->
  <div *ngIf="!forFilter"
       class="multi-select-for-field"
       [class.disabled]="disabled">
    <div class="multi-select-for-field-value" [class.with-icon]="selectedItems && selectedItems.length > 0">
      <div *ngIf="title && (!selectedItems || selectedItems.length === 0)"
           class="multi-select-placeholder"
           [class.opened]="openedSelect"
           (click)="openSelect($event, !openedSelect)">
        {{title}}
      </div>
      <div *ngIf="selectedItems && selectedItems.length > 0"
           class="multi-select-selected"
           [class.opened]="openedSelect"
           (click)="openSelect($event, !openedSelect)">
        <span class="multi-select-selected-text" [title]="selectedItemsValue.join(', ')">{{selectedItemsValue.join(', ')}}</span>
        <span class="multi-select-selected-count">({{selectedItemsValue.length}})</span>
      </div>
      <ng-container [ngTemplateOutlet]="balloon"></ng-container>
    </div>
    <div *ngIf="selectedItems && selectedItems.length > 0 && !disabled"
         class="multi-select-for-field-remove"
         (click)="removeSelection()">
      <span class="font-icons icon-clear"></span>
    </div>
    <div *ngIf="selectedItems && selectedItems.length > 0 && disabled"
         class="multi-select-for-field-block">
      <span class="font-icons icon-lock"></span>
    </div>
  </div>

</div>

<!--Шаблон выпадающего списка-->
<ng-template #balloon>
  <div class="multi-select-balloon {{openDirection}}"
       [class.opened]="openedSelect"
       [class.for-filter]="forFilter"
       [class.disabled]="disabled"
  >
    <div *ngIf="list && list.length > 0" class="multi-select-list scroll-block">
      <app-checkbox-list  class="multi-select-list-items for-multi-select"
                          [selected]="checkedKeys"
                          checkListName="multi-select-list"
                          [disabled]="disabled"
                          [withSelectTitle]="true"
                          [checkboxList]="list"
                          [forMultiSelect]="true"
                          (OnChange)="changeCheckedKeys($event)"
      ></app-checkbox-list>
    </div>
    <div *ngIf="!list || list.length === 0"
         class="multi-select-list-empty"
    >{{'GENERAL.EMPTY_LIST' | translate}}</div>
    <div *ngIf="list && list.length > 0" class="multi-select-list-btns">
      <app-button class="multi-select-list-btn"
                  [title]="'GENERAL.CANCEL' | translate"
                  (OnClick)="openSelect($event, !enableClickOutside)"
      ></app-button>
      <app-button class="multi-select-list-btn blue"
                  [title]="'GENERAL.APPLY' | translate"
                  [disabled]="disabled"
                  (OnClick)="onApply()"
      ></app-button>
    </div>
  </div>
</ng-template>
