import { Router, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs/internal/Observable";
import { LocalStorageService } from "@core/services/local-storage.service";
import { BehaviorSubject, Subscription } from "rxjs";
import { IAccessTreeItem, IUserMenu } from "@core/interfaces/user";
import { AuthService } from "@core/services/auth/auth.service";
import { ACCESS_TREE_OBJECT_TYPE } from "@app/app.enums";
import * as cloneDeep from 'lodash.clonedeep';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private openedMenuKey: string = 'userMenuOpened';
  private openedMenu$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public userMenuKey: string = 'userMenu';
  private userMenu$: BehaviorSubject<IUserMenu[]> = new BehaviorSubject<IUserMenu[]>([]);
  private menuUrls = {
    g_Catalog: '/nsi/dictionaries',
    g_Group: '/admin/settings-access/groups',
    g_Role: '/admin/settings-access/roles',
    g_User: '/admin/settings-access/users',
    g_System: '/admin/parameters/system-parameters',
    g_TaskSchedule: '/admin/parameters/task-schedule',
    g_EventLog: '/admin/monitoring/event-log',
    g_IVKEvents: '/admin/monitoring/event-log-ivk',
    g_DBTests: '/admin/monitoring/db-tests',
    g_Uploads: '/admin/monitoring/uploads-journal',
    g_SoftwareRentalContract: '/admin/settings-access/software-rent-contracts',
    g_MeasureObjects: '/energy-resources/measure-objects',
    g_MeasurePoints: '/energy-resources/measure-points',
    g_Devices: '/energy-resources/devices'
  };
  private menuIcons = {
    m_NSI: 'icon-menu_nsi',
    m_Admin: 'icon-menu_admin',
    m_EnergyResources: 'icon-menu_immac'
  };

  private activeUrl: string = null;
  private routerSubscription: Subscription;

  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private router: Router
  ) {
    this.getUserAccessTree();
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.setActiveUrl(this.router.url);
      });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  private getUserAccessTree() {
    const menuFromStorage: IUserMenu[] = this.localStorageService.getObjectByName(this.userMenuKey);
    if (menuFromStorage && this.authService.isAuthorized()) {
      this.setUserMenu(menuFromStorage);
    }
  }

  /**
   * Свернуть / развернуть все пункты меню
   * @param value
   */
  public collapseMenu(value: boolean) {
    let menu: IUserMenu[] = cloneDeep(this.userMenu$.getValue());
    if (menu?.length > 0) {
      menu = menu.map((item: IUserMenu) => {
        item.isOpened = value;
        if (item.children && item.children.length > 0) {
          item.children = item.children.map((child: IUserMenu) => {
            child.isOpened = value;
            return child;
          });
        }
        return item;
      });
      this.setUserMenu(menu);
    }
  }

  public getUserMenu(): Observable<IUserMenu[]> {
    return this.userMenu$.asObservable();
  }

  public setUserMenu(userMenu: IUserMenu[]) {
    this.userMenu$.next(userMenu);
    this.localStorageService.setObjectByName(this.userMenuKey, userMenu);
  }

  public setUserMenuWithPrepare(menuFromBackend: IAccessTreeItem[]) {
    this.setActiveMenu(this.prepareMenu(menuFromBackend), this.activeUrl);
  }

  public setOpenedMenu(open: boolean) {
    this.openedMenu$.next(open);
    this.localStorageService.setObjectByName(this.openedMenuKey, open);
  }

  public getOpenedMenu(): Observable<boolean> {
    return this.openedMenu$.asObservable();
  }

  private prepareMenu(menuFromBackend: IAccessTreeItem[]): IUserMenu[] {
    return this.prepareMenuChildren(menuFromBackend, null);
  }

  private prepareMenuChildren(menuFromBackend: IAccessTreeItem[], parentId: number) {
    let result: IUserMenu[] = [];
    let levelItems: IAccessTreeItem[] = [...menuFromBackend.filter((item: IAccessTreeItem) => item.parentId === parentId), ...[]];
    result = levelItems.map((level: IAccessTreeItem) => {
      return {
        id: level.id,
        name: level.name,
        code: level.code,
        objectType: level.objectType,
        parentId: level.parentId,
        sortOrder: level.sortOrder,
        isOpened: false,
        isActive: false,
        url: this.getMenuUrl(level.code),
        iconClass: this.getMenuIconClass(level.code),
        isFavorite: false,
        children: level.objectType === ACCESS_TREE_OBJECT_TYPE.MENU
          ? this.prepareMenuChildren(menuFromBackend, level.id)
          : null
      }
    });
    return result.length > 0
      ? result.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1)
      : [];
  }

  private getMenuIconClass(code: string) {
    const iconClass = this.menuIcons[code];
    return iconClass ? iconClass : null;
  }

  private getMenuUrl(code: string) {
    const url = this.menuUrls[code];
    return url ? url : null;
  }

  public setActiveMenu(menu: IUserMenu[], url: string) {
    if (menu && menu.length > 0) {
      menu = menu.map((item: IUserMenu) => {
        if (item.children && item.children.length > 0) {
          item.children = item.children.map((child: IUserMenu) => {
            if (child.children && child.children.length > 0) {
              child.children = child.children.map((child2: IUserMenu) => {
                child2.isActive = child2.url === url;
                return child2;
              });
            }
            const hasActiveChild = child.children && child.children.length > 0
              && !!child.children.find((child2: IUserMenu) => child2.isActive);
            child.isOpened = hasActiveChild;
            child.isActive = child.url === url || hasActiveChild;
            return child;
          });
        }
        const hasActiveChild = item.children && item.children.length > 0
          && !!item.children.find((child: IUserMenu) => child.isActive);
        item.isOpened = hasActiveChild;
        item.isActive = item.url === url || hasActiveChild;
        return item;
      });
      this.setUserMenu(menu);
    }
  }

  public setActiveUrl(url: string) {
    this.activeUrl = url;
    this.setActiveMenu(this.userMenu$.getValue(), this.activeUrl);
    this.setOpenedMenu(this.activeUrl === '/main');
  }
}
