import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true
    }
  ]
})
export class TextareaComponent implements OnInit, ControlValueAccessor {

  private innerValue: any;

  @Input() textareaId: string = ''; // ид textarea
  @Input() textareaHeight: number = 69; // высота textarea
  @Input() placeholder: string = ''; // плейсхолдер
  @Input() disabled: boolean = false; // блокировка
  @Input() readonly: boolean = false; // только чтение
  @Input() maxLength: string = '255'; // максимальное кол-во символов
  @Output() OnFocus = new EventEmitter<any>(); // событие фокуса
  @Output() OnBlur = new EventEmitter<any>(); // событие снятия фокуса
  @Output() OnChange = new EventEmitter<any>(); // событие ввода


  onChange: any = () => {};
  onTouched: any = () => {};

  get model(): string {
    return this.innerValue;
  }

  set model(val) {
    this.innerValue = val;
    this.onChange(this.innerValue);
    this.OnChange.emit(this.innerValue);
  }

  constructor() { }

  ngOnInit() {
  }

  writeValue(value): void {
    this.innerValue = value;
    this.onChange(this.innerValue);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  emitFocusEvent() {
    this.OnFocus.emit();
  }

  emitBlurEvent() {
    this.OnBlur.emit();
    this.onTouched();
  }

}
