import {Component, EventEmitter, forwardRef, Input, OnChanges, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ISelectItem} from "@core/interfaces/select-item";

@Component({
  selector: 'app-radiobox-list',
  templateUrl: './radiobox-list.component.html',
  styleUrls: ['./radiobox-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioboxListComponent),
      multi: true
    }
  ]
})
export class RadioboxListComponent implements OnChanges, ControlValueAccessor {

  @Input() selected: string | number; // ключ выбранного значения
  @Input() radioListName: string; // имя
  @Input() asLineRow: boolean = false; //отобразить в строку
  @Input() required: boolean; // обязательность выбора
  @Input() disabled: boolean; // блокировка
  @Input() radioList: ISelectItem[]; // список значений
  @Input() withSelectTitle: boolean = false; // с выделение заголовка у выбранного значения (для списков)
  @Output() OnChange: EventEmitter<ISelectItem> = new EventEmitter<ISelectItem>(); // событие при смене состояния

  public _onChange: any = () => {};
  public _onTouch: any = () => {};

  constructor() { }

  ngOnChanges() {
    this.changeValue(this.selected);
  }

  private changeValue(value: string | number) {
    this.selected = value;
    this._onChange(this.selected);
  }

  writeValue(value: string | number) {
    if (!this.selected || value.toString() !== this.selected.toString()) this.changeValue(value);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  /**
   * изменение выбранного значения
   * @param item
   */
  public changeRadio(item: ISelectItem): void {
    if (!this.disabled && !item.disabled) {
      this.changeValue(item.key);
      this.OnChange.emit(item);
    }
  }
}
