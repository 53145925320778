import { Injectable } from '@angular/core';
import { LocalStorageService } from '@core/services/local-storage.service';
import { UserApiService } from "@core/services/api/user-api.service";
import { IAccessObjects, IAccessTree, IAccessTreeItem } from "@core/interfaces/user";
import { ACCESS_TREE_OBJECT_TYPE, ResourceTypeCode, USER_ACCESS } from "@app/app.enums";
import { MenuService } from "@shared/services/menu.service";
import { forkJoin } from "rxjs";
import { SystemParamsService } from "@core/services/system-params.service";

@Injectable({
  providedIn: 'root',
})
export class VisibilityService {

  private accessObjects: USER_ACCESS[];
  private accessResourceTypes: string[];
  private userAccessObjectsKey: string = 'userAccessObjects';
  private userAccessResourceTypesKey: string = 'userAccessResourceTypes';

  constructor(
    private localStorageService: LocalStorageService,
    private userApiService: UserApiService,
    private menuService: MenuService,
    private systemParamsService: SystemParamsService
  ) { }

  /**
   * получение списка доступных аттрибутов
   * и списка доступных типов ресурсов
   */
  public getAccessObjects() {
    const userAccessObjects$ = this.userApiService.getUserAccessObjects();
    const userAccessTree$ = this.userApiService.getUserAccessTree();
    forkJoin([userAccessObjects$, userAccessTree$]).subscribe(
      ([accessObjects, accessTree]: [IAccessObjects, IAccessTree]) => {
        this.menuService.setUserMenuWithPrepare(accessTree.uiObjects);
        const accessGrids = accessTree.uiObjects && accessTree.uiObjects.length > 0
          ? this.prepareAccessGrids(accessTree.uiObjects)
          : [];
        this.localStorageService.setObjectByName(
          this.userAccessObjectsKey,
          [...accessObjects.uiObjectsCodes, ...accessGrids]);
        this.localStorageService.setObjectByName(this.userAccessResourceTypesKey, accessObjects.resourceTypeCodes);
      }, (error) => {
        this.clearUserSession();
      });
  }

  private prepareAccessGrids(accessTree: IAccessTreeItem[]) {
    return accessTree.filter((item: IAccessTreeItem) => item.objectType === ACCESS_TREE_OBJECT_TYPE.GRID)
      .map((item: IAccessTreeItem) => { return item.code });
  }

  /**
   * проверка доступа к аттрибуту
   * @param {string} attr
   * @returns {boolean}
   */
  public isVisible(attr: USER_ACCESS): boolean {
    this.accessObjects = this.localStorageService.getObjectByName(this.userAccessObjectsKey);
    return !!this.accessObjects
      && !!this.accessObjects.find((item: USER_ACCESS) => item.toString().toLowerCase() === attr.toString().toLowerCase());
  }

  /**
   * проверка доступа к разделу аттрибутов
   * @param {string} keyPrefix
   * @returns {boolean}
   */
  public isSectionVisible(keyPrefix: string): boolean {
    this.accessObjects = this.localStorageService.getObjectByName(this.userAccessObjectsKey);
    return !!this.accessObjects
      && !!this.accessObjects.find((item: USER_ACCESS) => item.toString().toLowerCase().startsWith(keyPrefix.toLowerCase()));
  }

  /**
   * проверка доступа к типу ресурса
   * @param {ResourceTypeCode} resourceTypeCode
   * @returns {boolean}
   */
  public isVisibleResourceType(resourceTypeCode: ResourceTypeCode): boolean {
    this.accessResourceTypes = this.localStorageService.getObjectByName(this.userAccessResourceTypesKey);
    return !!this.accessResourceTypes.find((item: ResourceTypeCode) => item === resourceTypeCode);
  }

  public clearAccess() {
    this.localStorageService.setObjectByName(this.userAccessObjectsKey, null);
    this.localStorageService.setObjectByName(this.userAccessResourceTypesKey, null);
  }

  public hasAnyAccessObjects() {
    this.accessObjects = this.localStorageService.getObjectByName(this.userAccessObjectsKey);
    return Boolean(this.accessObjects && this.accessObjects.length > 0);
  }

  public hasAccessObjects(attrs: USER_ACCESS[]) {
    const hasAccess = attrs.filter((attr: USER_ACCESS) => this.isVisible(attr));
    return hasAccess && hasAccess.length > 0;
  }

  public clearUserSession() {
    this.menuService.setUserMenu(null);
    this.systemParamsService.clearSystemParameters();
    this.clearAccess();
  }

}
