import { HttpErrorResponse } from '@angular/common/http';
import { ITokenResponse } from './../auth/oauth2.service';
import { BehaviorSubject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, catchError, filter, take, finalize } from 'rxjs/operators';
import { AuthService } from '@core/services/auth/auth.service';
import { SYSTEM_PARAMETER_NAME } from "@app/app.enums";
import { LocalStorageService } from "@core/services/local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  private isRefreshing: boolean = false;
  private token$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private authService: AuthService,
    private localStorageService: LocalStorageService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.isAuthorized() && !req.headers.get('Authorization') && !req.url.includes('/oauth/token')) {
      req = this.addToken(req, this.authService.getToken());
    }

    return next.handle(req).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401 && req && !req.url.includes('/oauth/token')) {
        return this.handleUnauthorizedError(req, next, error);
      }
      else {
        return throwError(error);
      }
    }));
  }

  private addToken(req: HttpRequest<any>, token: string) {
    return req.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`,
        'Content-type': req.headers.get('Content-Type')
          ? req.headers.get('Content-Type')
          : 'application/json; charset=utf-8'
      }
    });
  }

  private handleUnauthorizedError(req: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
    // обновляем токен и возвращаем запрос с новым токеном
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.token$.next(null);
      const authUrl: string = this.localStorageService.getObjectByName(SYSTEM_PARAMETER_NAME.AUTH_URL);
      if (authUrl) {
        return this.authService.refreshToken(authUrl)
          .pipe(
            catchError(errors => {
              if (errors instanceof HttpErrorResponse && errors.status === 400) {
                document.location.href = this.authService.getAuthCodeUrl(document.location.href);
                return throwError(errors);
              } else {
                this.isRefreshing = false;
                const redirectUrl = document.location.href ? btoa(document.location.href) : null;
                this.navigateToLogin(redirectUrl);
                return throwError(errors);
              }
            }),
            switchMap((res: ITokenResponse) => next.handle(this.addToken(req, res.access_token))
              .pipe(finalize(() => {
                this.token$.next(res.access_token);
                this.isRefreshing = false;
              }))
            )
          );
      }
    }
    // если запрос пришел во время обновления токена
    // ждем обновления и возвращаем запрос с новым токеном
    else {
      return this.token$.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => next.handle(this.addToken(req, token)))
      )
    }
  }

  private navigateToLogin(redirectUrl: string = null): void {
    const authUrl: string = this.localStorageService.getObjectByName(SYSTEM_PARAMETER_NAME.AUTH_URL);
    if (authUrl) {
      if (redirectUrl) {
        document.location.href = `${authUrl}/auth?state=${redirectUrl}`;
      } else {
        document.location.href = `${authUrl}/auth`;
      }
    }
  }
}
