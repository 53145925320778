import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

import {environment} from "@env/environment";
import {share} from "rxjs/operators";
import {Observable} from "rxjs";
import {
  IUniversalGridRequest,
  IUniversalGuideInfo,
  IUniversalGuideUpdateRequest
} from "@shared/components/universal-guide/universal-guide.interface";
import {IGridRequestPayload, IPagedResponse} from "@shared/components/base/ag-base-grid/ag-base-grid.interface";

@Injectable({
  providedIn: 'root'
})
export class UniversalGuideApiService {

  constructor(private HttpClient: HttpClient) {}

  public getInfo(code: string): Observable<IUniversalGuideInfo> {
    return this.HttpClient.get<IUniversalGuideInfo>(`${environment.API_URL}/admin/object/info?code=${code}`).pipe(share());
  }

  public getGrid(requestPayload: IUniversalGridRequest): Observable<IPagedResponse> {
    return this.HttpClient.post<IPagedResponse>(
      `${environment.API_URL}/admin/object/grid`,
      requestPayload
    ).pipe(share());
  }

  public getRow(code: string, id: number): Observable<any> {
    return this.HttpClient.get<any>(`${environment.API_URL}/admin/object/row?code=${code}&id=${id}`).pipe(share());
  }

  public addRow(request: IUniversalGuideUpdateRequest): Observable<any> {
    return this.HttpClient.post(`${environment.API_URL}/admin/object`, request).pipe(share());
  }

  public updateRow(request: IUniversalGuideUpdateRequest): Observable<any> {
    return this.HttpClient.put(`${environment.API_URL}/admin/object`, request).pipe(share());
  }

  public deleteRow(code: string, id: number): Observable<any> {
    return this.HttpClient.delete(`${environment.API_URL}/admin/object?code=${code}&id=${id}`).pipe(share());
  }

}
