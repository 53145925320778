<div class="dateTimeInputClass"
       [class.error]="hasError"
       [class.disabled]="disabled"
       [class.focus]="isFocus"
       (clickOutside)="onClickOutside()"
       [clickOutsideEnabled]="enableClickOutside">
       <span *ngIf="withDate"
              class="font-icons icon-calendar_date"></span>
       <input *ngIf="withDate"
              #dateInput
              id="calendar-date-{{id}}"
              class="date-input"
              name="calendar-date"
              [(ngModel)]="selectedDate"
              (focus)="onFocus(true)"
              type="text"
              [disabled]="disabled || noManualInput"
              [textMask]="dateInputConfig"
              (ngModelChange)="changedDate($event)">
       <span *ngIf="selectedTimeFormat"
              class="font-icons icon-calendar_time"></span>
       <input *ngIf="selectedTimeFormat"
              #timeInput
              id="calendar-time-{{id}}"
              class="time-input"
              [class.with-second]="selectedTimeFormat === timeWithSecondFormat"
              name="calendar-time"
              [(ngModel)]="selectedTime"
              (focus)="onFocus(false)"
              type="text"
              [disabled]="disabled || noManualInput"
              [textMask]="timeInputConfig"
              (ngModelChange)="changedTime($event)">
       <span *ngIf="!disabled && (selectedDate || selectedTime) && !withoutClear"
              class="font-icons icon-clear"
              (click)="clearSelect()"></span>
       <span *ngIf="hasError && errorText"
             [ngbTooltip]="errorText"
             class="font-icons icon-error_info"
             tooltipClass="tooltip-with-list"
             [placement]="tooltipPlacement"
             container="body">
         <span class="path1"></span><span class="path2"></span>
       </span>
</div>
