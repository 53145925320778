import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {IEventLogChange, IEventLogError, IEventLogItem} from "@core/interfaces/event-log";
import {EventLogApiService} from "@core/services/api/event-log-api.service";
import {IAccordionItem} from "@core/interfaces/accordion";
import {DateTimeService} from "@shared/services/date-time.service";

@Component({
  selector: 'app-event-log-card',
  templateUrl: './event-log-card.component.html',
  styleUrls: ['./event-log-card.component.scss']
})
export class EventLogCardComponent implements OnInit {

  @Input() info: IEventLogItem = null;
  @Input() service: string = null;

  public eventTypeTitle: string = null;
  public startDate: string = null;
  public endDate: string = null;
  public changes: IEventLogChange[] = [];
  public errors: IEventLogError[] = [];

  public isLoading: boolean = false; // флаг загрузки

  public blocks: IAccordionItem[] = [];

  private dateFormat: string = this.dateTimeService.getDateFormat('dateTimeWithSecond');

  constructor(
    private translateService: TranslateService,
    private activeModal: NgbActiveModal,
    private eventLogApiService: EventLogApiService,
    private dateTimeService: DateTimeService
  ) {
    this.translateService.get(['EVENT_LOG.CARD.BLOCKS']).subscribe(result => {
      this.blocks = [
        {id: 'params', title: result['EVENT_LOG.CARD.BLOCKS']['PARAMS'], open: true},
        {id: 'changes', title: result['EVENT_LOG.CARD.BLOCKS']['CHANGED_ATTRIBUTES'], open: false},
        {id: 'errors', title: result['EVENT_LOG.CARD.BLOCKS']['ERRORS'], open: false}
      ];
    });
  }

  ngOnInit(): void {
    if (this.info && this.info.id) {
      this.eventTypeTitle = this.info.typeName;
      this.startDate = this.info.startDate
        ? this.dateTimeService.getDateFromBackend(this.info.startDate, this.dateFormat)
        : null;
      this.endDate = this.info.endDate
        ? this.dateTimeService.getDateFromBackend(this.info.endDate, this.dateFormat)
        : null;
      this.getChanges();
      if (this.info.errorCount > 0) {
        this.getErrors();
      } else {
        this.errors = [];
      }
    } else {
      this.changes = [];
      this.errors = [];
    }
  }

  private getChanges() {
    this.isLoading = true;
    this.eventLogApiService.getEventInfo(this.info.id, this.service)
      .subscribe((result: IEventLogChange[]) => {
        this.changes = result && result.length > 0 ? this.prepareChanges(result) : [];
        this.isLoading = false;
        }, error => {
        this.changes = [];
        this.isLoading = false;
      });
  }

  private getErrors() {
    this.isLoading = true;
    this.eventLogApiService.getEventErrors(this.info.id, this.service)
      .subscribe((result: IEventLogError[]) => {
        this.errors = result && result.length > 0 ? this.prepareErrors(result) : [];
        this.isLoading = false;
      }, error => {
        this.errors = [];
        this.isLoading = false;
      });
  }

  private prepareChanges(list: IEventLogChange[]) {
    const items: IEventLogChange[] = list.map((item: IEventLogChange) => {
      item.changeFrom = item.changeFrom && item.changeFrom !== '' && item.changeFrom !== "null"
        ? this.dateTimeService.getDateOrText(item.changeFrom, this.dateFormat) :null;
      item.changeTo = item.changeTo && item.changeTo !== '' && item.changeTo !== "null"
        ? this.dateTimeService.getDateOrText(item.changeTo, this.dateFormat) : null;
      return item;
    });
    return items.filter((item: IEventLogChange) => item.changeFrom !== item.changeTo);
  }

  private prepareErrors(list: IEventLogError[]) {
    return list.map((item: IEventLogError) => {
      item.errorDate = item.errorDate
        ? this.dateTimeService.getDateFromBackend(item.errorDate , this.dateFormat)
        : null;
      return item;
    });
  }

  public close() {
    this.info = null;
    this.eventTypeTitle = null;
    this.startDate = null;
    this.endDate = null;
    this.changes = [];
    this.errors = [];
    this.activeModal.close();
  }
}
