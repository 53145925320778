<div class="row">
  <div class="col-9">
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
      {{ title }}
    </div>
    <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
         [class]="options.messageClass" [innerHTML]="message">
    </div>
    <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite"
         [class]="options.messageClass" [attr.aria-label]="message">
      {{ message }}
    </div>
  </div>
  <div class="col-3 text-right">
    <span *ngIf="options.closeButton" (click)="remove()" class="btn btn-sm font-icons icon-forward"></span>
  </div>
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
