import { Injectable } from "@angular/core";
import { IDayMonthYear } from "@core/interfaces/select-item";
import { date_full_ISO_pattern, date_ISO_pattern } from "@app/app.enums";
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {

  public readonly DIGIT_REG_EXP = /\d/;
  public readonly DATE_SEPARATOR = '.';
  public readonly TIME_SEPARATOR = ':';

  private UNIT_OF_TIME_MAP = {
    year: 'year',
    month: 'month',
    week: 'week',
    iso_week: 'isoWeek',
    day: 'day',
    hour: 'hour',
    minute: 'minute',
    second: 'second'
  };

  private dateFormat = {
    date: 'DD.MM.YYYY',
    time: 'HH:mm',
    timeWithSecond: 'HH:mm:ss',
    dateTime: 'DD.MM.YYYY HH:mm',
    dateTimeWithSecond: 'DD.MM.YYYY HH:mm:ss',
    dateTimeForDate: 'MM.DD.YYYY HH:mm',
    dateForFile: 'DD.MM.YYYY_HH:mm:ss'
  };

  constructor() {
  }

  /**
   * возвращает строку с датой для бэка из строки в заданном формате
   * с часовым поясом клиента, "2021-03-17T11:00:00+05:00"
   * @param date строка даты
   * @param format формат даты
   * @returns
   */
  public getBackendDateFromString(date: string, format: string = this.dateFormat.dateTime): any {
    return dayjs(date, format).format();
  }

  /**
   * возвращает строку с датой для бэка из строки в заданном формате
   * без часового пояса клиента, "2021-03-17T11:00:00Z"
   * @param date строка даты
   * @param format формат даты
   * @param keepLocalTime оставить локальное время или вычесть часовой пояс из результата
   * @returns
   */
  public getBackendDateFromStringNoTimezone(
    date: string,
    format: string = this.dateFormat.dateTime,
    keepLocalTime: boolean = true): any {
    return dayjs(date, format).utc(keepLocalTime).format();
  }

  //возвращает строку с датой в заданном формате из даты с бэка
  public getDateFromBackend(
    date: string,
    format: string = this.dateFormat.dateTime,
    keepLocalTime: boolean = true): string {
    if (this.isBackendDate(date)) {
      return dayjs(date)
        .utc(keepLocalTime)
        .format(format);
    }
    return 'Invalid date';
  }

  //возвращает строку с датой в заданном формате из даты с бэка со сдвигом ЧС
  public getDateFromBackendWithOffset(
    date: string,
    format: string = this.dateFormat.dateTime,
    offset: string = null,
    keepLocalTime: boolean = false): string {
    if (this.isBackendDate(date)) {
      return dayjs(date)
        .utcOffset(offset, keepLocalTime)
        .format(format);
    }
    return 'Invalid date';
  }

  private isBackendDate(date): boolean {
    return date && date.indexOf('T') > -1;
  }

  private isISOFullDate(date): boolean {
    return !!(date && String(date).match(date_full_ISO_pattern));
  }

  private isISODate(date): boolean {
    return !!(date && String(date).match(date_ISO_pattern));
  }

  //возвращает строку с датой в заданном формате из даты с бэка или текст
  public getDateOrText(
    date: string,
    format: string = this.dateFormat.dateTime,
    keepLocalTime: boolean = true): string {
    if (this.isISOFullDate(date)) {
      return dayjs(date)
        .utc(keepLocalTime)
        .format(format);
    } else if (this.isISODate(date)) {
      return dayjs(date).format(this.dateFormat.date)
    }
    return date;
  }

  public getDateFormat(type: string): string {
    return this.dateFormat[type];
  }

  public getDateMask(): any[] {
    return [
      this.DIGIT_REG_EXP,
      this.DIGIT_REG_EXP,
      this.DATE_SEPARATOR,
      this.DIGIT_REG_EXP,
      this.DIGIT_REG_EXP,
      this.DATE_SEPARATOR,
      this.DIGIT_REG_EXP,
      this.DIGIT_REG_EXP,
      this.DIGIT_REG_EXP,
      this.DIGIT_REG_EXP
    ];
  }

  public getTimeMask(timeFormat: string = this.dateFormat.timeWithSecond): any[] {
    if (timeFormat === this.dateFormat.timeWithSecond) {
      return [
        this.DIGIT_REG_EXP,
        this.DIGIT_REG_EXP,
        this.TIME_SEPARATOR,
        this.DIGIT_REG_EXP,
        this.DIGIT_REG_EXP,
        this.TIME_SEPARATOR,
        this.DIGIT_REG_EXP,
        this.DIGIT_REG_EXP
      ];
    } else {
      return [
        this.DIGIT_REG_EXP,
        this.DIGIT_REG_EXP,
        this.TIME_SEPARATOR,
        this.DIGIT_REG_EXP,
        this.DIGIT_REG_EXP
      ];
    }
  }

  // получение IDayMonthYear из строки даты
  public getDayMonthYearFromString(date: string, format: string = this.dateFormat.date): IDayMonthYear {
    if (date) {
      const dateM = dayjs(date, format);
      return {
        day: dateM.date(),
        month: dateM.month() + 1,
        year: dateM.year()
      }
    } else {
      return null;
    }
  }

  // получение строки даты из IDayMonthYear
  public getStringFromDayMonthYear(value: IDayMonthYear, format: string = this.dateFormat.date): string {
    if (value) {
      return dayjs(
        new Date(value.year, value.month - 1, value.day)
      ).format(format);
    } else {
      return null;
    }
  }

  public getTimeStringFromString(
    value: string,
    inputFormat: string = this.dateFormat.dateTimeWithSecond,
    outputFormat: string = this.dateFormat.timeWithSecond
  ): string {
    if (value) {
      const dateM = dayjs(value, inputFormat);
      if (dateM.year() === 0) {
        dateM.year(dayjs().year());
      }
      return dateM.format(outputFormat) !== 'Invalid date' ? dateM.format(outputFormat) : null;
    } else {
      return null;
    }
  }

  public getDateStringFromString(
    value: string,
    inputFormat: string = this.dateFormat.dateTimeWithSecond,
    outputFormat: string = this.dateFormat.date
  ): string {
    if (value) {
      const dateM = dayjs(value, inputFormat);
      if (dateM.year() === 0) {
        dateM.year(dayjs().year());
      }
      return dateM.format(outputFormat) !== 'Invalid date' ? dateM.format(outputFormat) : null;
    } else {
      return null;
    }
  }

  /**
   * Получить строку временной зоны
   * @returns +03:00 / -05:00
   */
  public getTimeZoneOffsetString(date?: string, format?: string) {
    let jsonDate = dayjs(date, format).format();
    return jsonDate.includes('+')
      ? jsonDate.substr(jsonDate.indexOf('+'))
      : jsonDate.substr(jsonDate.lastIndexOf('-'));
  }
}
