import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@env/environment";
import {Observable} from "rxjs";
import {share} from "rxjs/operators";
import {IAccessObjects, IAccessTree, IUserInfo} from "@core/interfaces/user";

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  constructor(
    private HttpClient: HttpClient
  ) {}

  public getUserAccessObjects(): Observable<IAccessObjects> {
    return this.HttpClient.get<IAccessObjects>(`${environment.API_URL}/user/access/object`).pipe(share());
  }

  public getUserAccessTree(): Observable<IAccessTree> {
    return this.HttpClient.get<IAccessTree>(`${environment.API_URL}/user/access/tree`).pipe(share());
  }

  public getUserInfo(): Observable<IUserInfo> {
    return this.HttpClient.get<IUserInfo>(`${environment.API_URL}/user/info/short`).pipe(share());
  }
}
