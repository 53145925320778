import {Component, ElementRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IDictionaryBody, IDictionaryParams} from "@core/interfaces/dictionary";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ISelectItem} from "@core/interfaces/select-item";

@Component({
  selector: 'app-simple-dictionary-modal',
  templateUrl: './simple-dictionary-modal.component.html',
  styleUrls: ['./simple-dictionary-modal.component.scss']
})
export class SimpleDictionaryModalComponent implements OnInit {

  @Input() loadingInProgress: boolean; // флаг загрузки
  @Input() selected: ISelectItem = null; // выбранное значение
  @Input() list: IDictionaryBody; // список значений
  @Input() params: IDictionaryParams; // список значений
  @Input() title: string = null; // тайтл при отсутствии выбранного значения (placeholder)
  @Output() OnSelect: Function; // применить выбранное значение
  @Output() OnLoadList: Function; //загрузить список значений
  @Output() OnClose: Function; //функция при закрытии

  @ViewChild('dictList') dictList: ElementRef;

  public selectedItem: ISelectItem = null; // выбранное значение
  public activeApply: boolean = false;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.selectedItem = this.selected ? this.selected : null;
    if (this.params && this.params.page && this.params.page.pageNumber == 1
      && this.list && this.list.elements && this.list.elements.length === 0) {
      if (this.OnLoadList) {
        this.OnLoadList(this.params);
      }
    }
    this.checkActiveApply();
  }

  private checkActiveApply() {
    this.activeApply = (this.selectedItem && this.selected && this.selectedItem.key.toString() !== this.selected.key.toString())
      || (this.selectedItem && !this.selected);
  }
  /**
   * функция выбора значения
   * @param item
   */
  public onSelectItem(item: ISelectItem): void {
    this.selectedItem = item;
    this.checkActiveApply();
  }

  /**
   * применить
   */
  public apply() {
    if (!this.selectedItem) {
      return;
    }
    if (this.OnSelect) {
      this.OnSelect(this.selectedItem);
    }
    this.cancel();
  }

  /**
   * скролл списка
   * @param event
   */
  public onScrollList(event: Event) {
    const element: EventTarget = event.target;
    if (element['scrollHeight'] - element['scrollTop'] <= element['clientHeight'] + 10) {
      this.loadMore();
    }
  }

  /**
   * подгрузка списка
   */
  public loadMore() {
    if (this.params.page.pageNumber < this.list.totalPages) {
      this.params.page.pageNumber = this.params.page.pageNumber + 1;
      if (this.OnLoadList) {
        this.OnLoadList(this.params);
      }
    }
  }

  /**
   * сортировка списка
   */
  public changeSorting() {
    if (this.params.sorting === 'asc') {
      this.params.sorting = 'desc';
    } else {
      this.params.sorting = 'asc';
    }
    this.params.page.pageNumber = 1;
    if (this.dictList) {
      this.dictList.nativeElement.scrollTo(0, 0);
    }
    if (this.OnLoadList) {
      this.OnLoadList(this.params);
    }
  }

  /**
   * поиск по списку
   * @param value
   */
  public search(value: string) {
    this.params.search = value;
    this.params.page.pageNumber = 1;
    if (this.dictList) {
      this.dictList.nativeElement.scrollTo(0, 0);
    }
    if (this.OnLoadList) {
      this.OnLoadList(this.params);
    }
  }

  /**
   * отмена
   */
  public cancel(): void {
    if (this.OnClose) {
      this.OnClose();
    }
    this.activeModal.close();
  }

}
