import {Component, Input, Output, EventEmitter, OnChanges, forwardRef} from '@angular/core';
import {DateTimeService} from "@shared/services/date-time.service";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import * as dayjs from 'dayjs';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarComponent),
      multi: true
    }
  ]
})
export class CalendarComponent implements OnChanges, ControlValueAccessor {

  private dateFormat = this.dateTimeService.getDateFormat('date');
  private timeFormat = this.dateTimeService.getDateFormat('time');
  public timeWithSecondFormat = this.dateTimeService.getDateFormat('timeWithSecond');
  private dateTimeFormat = this.dateTimeService.getDateFormat('dateTimeWithSecond');

  private currentYear = dayjs().year(); //текущий год
  private currentMonth = dayjs().month() + 1; //текущий год

  public withTime: boolean = false;
  public withSeconds: boolean = false;
  public minDateForCalendar: string = null;
  public maxDateForCalendar: string = null;

  public hasErrorMin: boolean = false;
  public hasErrorMax: boolean = false;
  public errorMin: string = null;
  public errorMax: string = null;
  private errorMinText: string = null;
  private errorMaxText: string = null;

  public openedCalendar: boolean = false;
  public enableClickOutside: boolean = false;

  @Input() selected: string = null;
  @Input() id: string = null;
  @Input() format: string = this.dateFormat;
  @Input() noManualInput: boolean = false;
  @Input() hasError: boolean = false;
  @Input() errorText: string = null;
  @Input() disabled: boolean = false;
  @Input() withMinutes: boolean = true;
  @Input() minDate: string = null; // минимальная дата в формате format
  @Input() maxDate: string = null; // максимальная дата в формате format
  @Input() minMonth: number = 1; // минимальный месяц в минимальном году
  @Input() maxMonth: number = this.currentMonth; // максимальный месяц в максимальном году
  @Input() minYear: number = this.currentYear - 20; // минимальный год
  @Input() maxYear: number = this.currentYear + 20; // максимальный год
  @Input() isNeedLimit: boolean = true;
  @Input() openDirection: string = 'bottom left';
  @Output() OnSelect: EventEmitter<string> = new EventEmitter<string>(); // выбор даты

  public _onChange: any = () => {};
  public _onTouch: any = () => {};

  constructor(
    private dateTimeService: DateTimeService,
    private translateService: TranslateService
  ) {
    this.translateService.get('GENERAL').subscribe((result) => {
      this.errorMinText = result['ERROR_DATE_MIN'];
      this.errorMaxText = result['ERROR_DATE_MAX'];
    });
  }

  ngOnChanges() {
    this.setMinMax();
    this.checkTimeMask();
    this.changeValue(this.selected);
  }

  private setMinMax() {
    if (this.isNeedLimit) {
      if (this.minDate) {
        this.minDateForCalendar = dayjs(this.minDate, this.format).format(this.dateFormat);
        this.minMonth = dayjs(this.minDate, this.format).month() + 1;
        this.minYear = dayjs(this.minDate, this.format).year();
      }
      if (this.maxDate) {
        this.maxDateForCalendar = dayjs(this.maxDate, this.format).format(this.dateFormat);
        this.maxMonth = dayjs(this.maxDate, this.format).month() + 1;
        this.maxYear = dayjs(this.maxDate, this.format).year();
      }
    }
  }

  private checkTimeMask() {
    const timeM: string = this.format && this.format !== this.dateFormat ? this.format.split(' ')[1] : null;
    this.withTime = !!timeM && (timeM === this.timeWithSecondFormat || timeM === this.timeFormat);
    this.withSeconds = !!timeM && timeM === this.timeWithSecondFormat;
  }

  private changeValue(value: string) {
    this.selected = value ? value : null;
    this._onChange(value);
  }

  public selectDateTime(value: string) {
    this.selected = value ? dayjs(value, this.format).format(this.format) : null;
    this.checkDate();
    this.writeValue(this.selected);
    this.OnSelect.emit(this.selected);
    if (!this.withTime) {
      this.openCalendar(null, false);
    } else {
      event.stopPropagation();
    }
  }

  public changeDate(value: string) {
    this.selected = value;
    this.checkDate();
    this.writeValue(value);
    this.OnSelect.emit(value);
  }

  public openCalendar(event, open: boolean) {
    if (this.disabled === true) {
      return;
    }
    this.openedCalendar = open;
    this.enableClickOutside = open;
  }

  private checkDate() {
    if (this.selected && this.minDate) {
      const date = dayjs(this.selected, this.format);
      const minMoment = dayjs(this.minDate, this.format);
      this.hasErrorMin = date.isBefore(minMoment);
      this.errorMin = date.isBefore(minMoment) ? `${this.errorMinText} ${this.minDate}` : null;
    } else {
      this.hasErrorMin = false;
      this.errorMin = null;
    }
    if (this.selected && this.maxDate) {
      const date = dayjs(this.selected, this.format);
      const maxMoment = dayjs(this.maxDate, this.format);
      this.hasErrorMax = date.isAfter(maxMoment);
      this.errorMax = date.isAfter(maxMoment) ? `${this.errorMaxText} ${this.maxDate}` : null;
    } else {
      this.hasErrorMax = false;
      this.errorMax = null;
    }
  }

  writeValue(value: string): void {
    this.selected = value ? value : null;
    this.changeValue(this.selected);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
