import { ContentTypeInterceptor } from '@core/services/interceptors/content-type.interceptor';
import { NgModule, APP_INITIALIZER, Injector, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from '@core/services/auth/auth.service';
import { AuthGuard } from '@core/guards/auth-guard.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateInitializerFactory } from '@core/services/translate-initializer.service';
import { OAuth2Service } from "@core/services/auth/oauth2.service";
import { TokenInterceptorService } from "@core/services/interceptors/token-interceptor.service";
import { AuthInitializerFactory } from "@core/services/auth/auth-initializer.service";
import { AccessGuardService } from "@core/guards/access-guard.service";
import { LocalStorageService } from './services/local-storage.service';
import { AuthorizationGuard } from "@core/guards/authorization-guard.service";
import { ChunkLoadErrorHandler } from './services/handlers/chunk-load-error.handler';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    AuthGuard,
    AccessGuardService,
    AuthorizationGuard,
    { provide: AuthService, useClass: OAuth2Service },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContentTypeInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: TranslateInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: AuthInitializerFactory,
      deps: [AuthService, LocalStorageService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ChunkLoadErrorHandler
    }
  ]
})
export class CoreModule { }
