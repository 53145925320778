import {Injectable} from "@angular/core";
import {IGridRequestPayload} from "@shared/components/base/ag-base-grid/ag-base-grid.interface";
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {share} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {IEventLogChange, IEventLogError} from "@core/interfaces/event-log";
import {ISelectItem} from "@core/interfaces/select-item";

@Injectable({
  providedIn: 'root'
})
export class EventLogApiService {

  constructor(
    private HttpClient: HttpClient
  ) {
  }

  // грид Журнал событий для карточек ОУ, оборудования и ТУ
  public getObjectHistoryJournalGrid(objectTypeCode: string, objectId: number, requestPayload: IGridRequestPayload)
    : Observable<any> {
    return this.HttpClient.post(
      `${environment.API_URL}/event/log/${objectTypeCode}/${objectId}/grid`,
      requestPayload)
      .pipe(share());
  }

  // Журнал событий. Получение дополнительной информации по конкретному событию
  public getEventInfo(eventLogId: number, remoteService: string): Observable<IEventLogChange[]> {
    return this.HttpClient.get<IEventLogChange[]>(
      `${environment.API_URL}/event/log/${remoteService}/${eventLogId}/infos`)
      .pipe(share());
  }

  // Журнал событий. Получение информации об ошибках по конкретному событию
  public getEventErrors(eventLogId: number, remoteService: string): Observable<IEventLogError[]> {
    return this.HttpClient.get<IEventLogError[]>(
      `${environment.API_URL}/event/log/${remoteService}/${eventLogId}/errors`)
      .pipe(share());
  }

  // Журнал событий. Справочник микросервисов
  public getEventLogServices(): Observable<ISelectItem[]> {
    return this.HttpClient.get<ISelectItem[]>(
      `${environment.API_URL}/event/log/dict/service`)
      .pipe(share());
  }

  // грид Журнал событий
  public getEventLogGrid(remoteService: string, requestPayload: IGridRequestPayload): Observable<any> {
    return this.HttpClient.post(
      `${environment.API_URL}/event/log/${remoteService}/grid`, requestPayload)
      .pipe(share());
  }

  // грид Журнал событий ИВК
  public getEventLogIvkGrid(requestPayload: IGridRequestPayload): Observable<any> {
    return this.HttpClient.post(
      `${environment.API_URL}/event/ivk/log/grid`, requestPayload)
      .pipe(share());
  }

  // Журнал(ы) событий. Справочник сервисов
  public getAdminSystemEventLogServices(): Observable<ISelectItem[]> {
    return this.HttpClient.get<ISelectItem[]>(
      `${environment.API_URL}/admin/system/event/log/dict/service`)
      .pipe(share());
  }

  // Журнал(ы) событий. Справочник категорий событий выбранного сервиса
  public getAdminSystemEventLogCategories(service: string): Observable<ISelectItem[]> {
    return this.HttpClient.get<ISelectItem[]>(
      `${environment.API_URL}/admin/system/event/log/dict/category?service=${service}`)
      .pipe(share());
  }

  // Журнал(ы) событий. Справочник типов событий выбранного сервиса
  public getAdminSystemEventLogTypes(service: string, categoryId: number = null): Observable<ISelectItem[]> {
    return this.HttpClient.get<ISelectItem[]>(
      `${environment.API_URL}/admin/system/event/log/dict/type?service=${service}&categoryId=${categoryId}`)
      .pipe(share());
  }

  // Журнал(ы) событий. Справочник категорий ошибок выбранного сервиса
  public getAdminSystemEventLogCategoryErrors(service: string): Observable<ISelectItem[]> {
    return this.HttpClient.get<ISelectItem[]>(
      `${environment.API_URL}/admin/system/event/log/dict/category/error?service=${service}`)
      .pipe(share());
  }

  // Журнал(ы) событий. Справочник типов ошибок выбранного сервиса
  public getAdminSystemEventLogTypeErrors(service: string, categoryId: number = null): Observable<ISelectItem[]> {
    return this.HttpClient.get<ISelectItem[]>(
      `${environment.API_URL}/admin/system/event/log/dict/type/error?service=${service}&categoryId=${categoryId}`)
      .pipe(share());
  }
}
