import {Component, EventEmitter, forwardRef, Input, OnChanges, Output} from '@angular/core';
import {IDictionaryBody, IDictionaryParams} from "@core/interfaces/dictionary";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {OpenModalService} from "@shared/services/open-modal.service";
import {SimpleDictionaryModalComponent} from "@shared/components/simple-dictionary/simple-dictionary-modal/simple-dictionary-modal.component";
import {ISelectItem} from "@core/interfaces/select-item";
import {DictionaryService} from "@shared/services/dictionary.service";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";

@Component({
  selector: 'app-simple-dictionary',
  templateUrl: './simple-dictionary.component.html',
  styleUrls: ['./simple-dictionary.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleDictionaryComponent),
      multi: true
    }
  ]
})
export class SimpleDictionaryComponent implements OnChanges, ControlValueAccessor {

  @Input() loadingInProgress: boolean; // флаг загрузки
  @Input() selected: ISelectItem = null; // выбранное значение
  @Input() list: IDictionaryBody = this.dictionaryService.getDefaultList(); // список значений
  @Input() params: IDictionaryParams = this.dictionaryService.getDefaultParams();
  @Input() title: string = null; // тайтл при отсутствии выбранного значения (placeholder)
  @Input() titleDictionary: string = null; // тайтл справочника
  @Input() forFilter: boolean = false; // флаг типа селекта - для панели фильтров или для полей
  @Input() disabled: boolean = false; // блокировка
  @Input() withRemove = false; // флаг отображение иконки сброса значения
  @Input() forAddRow = false; // флаг отображения для добавления записи с +
  @Output() OnSelect: EventEmitter<ISelectItem> = new EventEmitter<ISelectItem>();
  @Output() OnLoadList: EventEmitter<IDictionaryParams> = new EventEmitter<IDictionaryParams>(); //загрузить список значений

  public _onChange: any = () => {};
  public _onTouch: any = () => {};

  protected simpleDictionaryModal: NgbModalRef;

  constructor(
    protected modalService: OpenModalService,
    protected dictionaryService: DictionaryService
  ) { }

  ngOnChanges() {
    if (!this.list) {
      this.list = this.dictionaryService.getDefaultList();
    }
    if (this.simpleDictionaryModal && this.simpleDictionaryModal.componentInstance) {
      this.simpleDictionaryModal.componentInstance.list = this.list;
      this.simpleDictionaryModal.componentInstance.loadingInProgress = this.loadingInProgress;
      this.simpleDictionaryModal.componentInstance.params = this.params;
      this.simpleDictionaryModal.componentInstance.selected = this.selected;
    }
    this._onChange(this.selected);
  }

  public open() {
    if (this.disabled) {
      return;
    }
    this.simpleDictionaryModal = this.modalService.show(
      SimpleDictionaryModalComponent,
      {
        selected: this.selected,
        list: this.list,
        params: this.params,
        title: this.titleDictionary ? this.titleDictionary : this.title,
        loadingInProgress: this.loadingInProgress,
        OnSelect: (item: ISelectItem) => {
          this.apply(item);
        },
        OnLoadList: (params: IDictionaryParams) => {
          this.loadList(params);
        },
        OnClose: () => {
          this.simpleDictionaryModal = undefined;
          this.params = {...this.params, ...this.dictionaryService.getDefaultParams()};
          this.list = this.dictionaryService.getDefaultList();
        }
      },
      'OnClose',
      {
        centered: true,
        windowClass: 'dictionary-modal',
      });
  }

  public removeSelection(): void {
    this.writeValue(null);
    this.OnSelect.emit(null);
  }

  public apply(item: ISelectItem) {
    this._onChange(item);
    this.OnSelect.emit(item);
    this.writeValue(item);
  }

  public loadList(params: IDictionaryParams) {
    this.params = params;
    this.OnLoadList.emit(this.params);
  }

  writeValue(item: ISelectItem): void {
    this.selected = item;
    this._onChange(item);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
