<div class="yearSelectClass"
     [class.for-calendar]="forCalendar"
     (clickOutside)="openSelect($event, !enableClickOutside)"
     [clickOutsideEnabled]="enableClickOutside">
  <div class="year-select"
       [class.disabled]="disabled">
    <div *ngIf="withIcon" class="year-select-icon">
      <span class="font-icons icon-calendar_date"
            (click)="openSelect($event, !openedSelect)"></span>
    </div>
    <div class="year-select-value"
         [class.with-remove]="withRemove && selected && !disabled"
         [class.with-icon]="withIcon">
      <div *ngIf="placeholder && !selected"
           class="year-select-placeholder"
           [class.opened]="openedSelect"
           (click)="openSelect($event, !openedSelect)">
        {{placeholder}}
      </div>
      <div *ngIf="selected"
           class="year-select-selected"
           [class.opened]="openedSelect"
           (click)="openSelect($event, !openedSelect)">
        <span>{{selected}}</span>
      </div>
      <ng-container [ngTemplateOutlet]="balloon"></ng-container>
    </div>
    <div *ngIf="withRemove && selected && !disabled"
         class="year-select-remove"
         (click)="removeSelection()">
      <span class="font-icons icon-clear"></span>
    </div>
  </div>
</div>

<!--Шаблон выпадающего списка-->
<ng-template #balloon>
  <div class="year-select-balloon {{openDirection}}"
       [class.opened]="openedSelect">
    <div *ngIf="list && list.length > 0" class="year-select-list scroll-block">
      <div *ngFor="let item of list"
           class="year-select-list-item"
           [class.active]="selected && item === selected"
           (click)="onSelectYear(item)"
      >{{item}}</div>
    </div>
    <div *ngIf="!list || list.length === 0" class="year-select-list-empty"> </div>
  </div>
</ng-template>
