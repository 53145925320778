import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {MenuService} from "@shared/services/menu.service";
import {OpenModalService} from "@shared/services/open-modal.service";
import {ConfirmModalComponent} from "@shared/components/confirm-modal/confirm-modal.component";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "@core/services/user.service";
import {IUserInfo, IUserMenu} from "@core/interfaces/user";
import {SystemParamsService} from "@core/services/system-params.service";
import {SYSTEM_PARAMETER_NAME, USER_ACCESS} from "@app/app.enums";
import {Observable, Subscription} from "rxjs";
import {AddGuideModalComponent} from "@shared/components/add-guide-modal/add-guide-modal.component";
import {GuideApiService} from "@core/services/api/admin/guide-api.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {

  public user: IUserInfo;
  public supportPhone: string;
  public supportEmail: string;
  public mainUrl: string = '/main';
  public homeUrl: string = '';
  public isAdmin: boolean = false;
  public hasUnreadNotifications: boolean = true;
  public showSupportBalloon: boolean = false;
  public supBalEnableClickOutside: boolean = false;
  public showUserBalloon: boolean = false;
  public userBalEnableClickOutside: boolean = false;
  public showGuideBalloon: boolean = false;
  public guideBalEnableClickOutside: boolean = false;
  private exitTitles: string[];
  private publicSystemParamsSubscription: Subscription;
  private menuSubscription: Subscription;
  private userInfoSubsctiption: Subscription;

  constructor(
    private router: Router,
    private menuService: MenuService,
    private modalService: OpenModalService,
    private translateService: TranslateService,
    private userService: UserService,
    private systemParamsService: SystemParamsService,
    private guideApiService: GuideApiService
  ) { }

  ngOnInit() {
    this.translateService.get(['GENERAL.LOGOUT_QUESTION', 'GENERAL.EXIT']).subscribe((result: string[]) => {
      this.exitTitles = result;
    });
    this.publicSystemParamsSubscription = this.systemParamsService.getPublicSystemParams()
      .subscribe((systemParams: any) => {
      this.supportPhone = systemParams ? systemParams[SYSTEM_PARAMETER_NAME.SUPPORT_PHONE] : '';
      this.supportEmail = systemParams ? systemParams[SYSTEM_PARAMETER_NAME.SUPPORT_EMAIL] : '';
      this.homeUrl = systemParams && systemParams[SYSTEM_PARAMETER_NAME.AUTH_URL]
        ? `${systemParams[SYSTEM_PARAMETER_NAME.AUTH_URL]}/home` : '';
    });
    this.menuSubscription = this.menuService.getUserMenu()
      .subscribe((menu: IUserMenu[]) => {
        this.isAdmin = menu && menu.length > 0
          ? !!menu.find((item: IUserMenu) => item.code === USER_ACCESS['ADMIN'])
          : false;
      });
    this.userInfoSubsctiption = this.userService.getUserInfo().subscribe(user => {
      this.user = user;
    });
  }

  ngOnDestroy() {
    this.publicSystemParamsSubscription.unsubscribe();
    this.menuSubscription.unsubscribe();
    this.userInfoSubsctiption.unsubscribe();
  }

  public toogleMenu(open: boolean) {
    this.menuService.setOpenedMenu(open);
  }

  public downloadGuide() {
    this.guideApiService.getGuideFile();
    if (this.isAdmin) {
      this.toggleGuideBalloon(event, false);
    }
  }

  public loadGuide() {
    this.modalService.show(
      AddGuideModalComponent,
      {
      },
      null,
      {
        centered: true,
        windowClass: 'modal-measure-card',
      });
    this.toggleGuideBalloon(event, false);
  }

  public toggleSupportBalloon(event, open: boolean): void {
    this.showUserBalloon = false;
    this.userBalEnableClickOutside = false;
    this.showGuideBalloon = false;
    this.guideBalEnableClickOutside = false;
    this.showSupportBalloon = open;
    this.supBalEnableClickOutside = open;
  }

  public openSupportBalloon(event): void {
    event.stopPropagation();
    this.toggleSupportBalloon(event, true);
  }

  public toggleUserBalloon(event, open: boolean): void {
    this.showSupportBalloon = false;
    this.supBalEnableClickOutside = false;
    this.showGuideBalloon = false;
    this.guideBalEnableClickOutside = false;
    this.showUserBalloon = open;
    this.userBalEnableClickOutside = open;
  }

  public openUserBalloon(event): void {
    event.stopPropagation();
    this.toggleUserBalloon(event, true);
  }

  public toggleGuideBalloon(event, open: boolean): void {
    this.showSupportBalloon = false;
    this.supBalEnableClickOutside = false;
    this.showUserBalloon = false;
    this.userBalEnableClickOutside = false;
    this.showGuideBalloon = open;
    this.guideBalEnableClickOutside = open;
  }

  public openGuideBalloon(event): void {
    event.stopPropagation();
    this.toggleGuideBalloon(event, true);
  }

  public exit() {
    this.modalService.show(
      ConfirmModalComponent,
      {
        question: this.exitTitles['GENERAL.LOGOUT_QUESTION'],
        applyTitle: this.exitTitles['GENERAL.EXIT'],
        onApply: () => {
          this.userService.logout();
        }
      },
      null,
      {
        centered: true,
        windowClass: 'modal-confirm',
      });
    this.toggleUserBalloon(event, false);
  }

}
