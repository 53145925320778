<div class="confirmModalClass">
  <div class="modal-body">
    <div class="icon-box" *ngIf="iconQuestion">
      <span class="font-icons icon-style {{ iconQuestion }}"
            [style.font-size.px]="iconQuestionSize"
            [style.color]="iconQuestionColor"
      ></span>
    </div>
    <div class="confirm-info-box">
      <div *ngIf="title" class="title-box">
        <span class="title" [innerHTML]="title"></span>
      </div>
      <div *ngIf="question" class="question-box">
        <span class="question" [innerHTML]="question"></span>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <app-button class="modal-footer-btn"
                (click)="cancel()"
                [title]="cancelTitle"
                [icon]="iconCancel"
                [iconSize]="iconCancelSize"
                [iconColor]="iconCancelColor"
                [disabled]="cancelButtonDisabled"
    ></app-button>
    <app-button class="modal-footer-btn blue"
                (click)="apply()"
                [title]="applyTitle"
                [icon]="iconApply"
                [iconSize]="iconApplySize"
                [iconColor]="iconApplyColor"
                [disabled]="applyButtonDisabled"
    ></app-button>
  </div>
</div>
