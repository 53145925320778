import {Component, EventEmitter, forwardRef, Input, OnChanges, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import * as dayjs from 'dayjs';
import {IQuarterYear} from "@core/interfaces/select-item";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-quarter-select',
  templateUrl: './quarter-select.component.html',
  styleUrls: ['./quarter-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuarterSelectComponent),
      multi: true
    }
  ]
})
export class QuarterSelectComponent implements OnChanges, ControlValueAccessor {

  private currentYear = dayjs().year(); //текущий год
  private currentQuarter = dayjs().quarter(); //текущий год
  public quarterList: string[] = []; // список кварталов

  @Input() selected: IQuarterYear = {quarter: this.currentQuarter, year: this.currentYear}; // текущее значение
  @Input() minQuarter: number = 1; // минимальный квартал в минимальном году
  @Input() maxQuarter: number = this.currentQuarter; // максимальный квартал в максимальном году
  @Input() minYear: number = this.currentYear - 4; // минимальный год
  @Input() maxYear: number = this.currentYear; // максимальный год
  @Input() withIcon: boolean = true; // с иконкой календаря
  @Input() disabled: boolean = false; // блокировка
  @Input() isNeedLimitQuarter: boolean = true; // флаг необходимости ограничения кварталов по minQuarter и maxQuarter
  @Input() openDirection: string = 'bottom'; // направление открытия
  @Output() OnSelect: EventEmitter<IQuarterYear> = new EventEmitter<IQuarterYear>(); // выбор квартала/года

  public selectedQuarter: string; // название выбранного квартала
  public openedSelect: boolean = false; // флаг открытия выпадающего списка
  public enableClickOutside: boolean = false; // Флаг управления работы outsideClickEvent

  public _onChange: any = () => {};
  public _onTouch: any = () => {};

  constructor(
    private translateService: TranslateService
  ) {
    this.translateService.get('DATE.QUARTERS').subscribe((result) => {
      const quarter = result;
      for (const key in quarter) {
        if (quarter.hasOwnProperty(key)) {
          this.quarterList.push(quarter[key]);
        }
      }
    });
  this.selectedQuarter = this.quarterList[this.currentQuarter - 1];
  }

  ngOnChanges() {
    if (!this.selected) {
      this.selected = {quarter: this.currentQuarter, year: this.currentYear};
    }
    this.changeValue(this.selected);
  }

  private changeValue(value: IQuarterYear) {
    if (value) {
      this.selectedQuarter = this.quarterList[value.quarter - 1];
    }
    this._onChange(value);
  }

  // функция открытия выпадающего списка с месяцами
  public openQuarterSelect(event, open: boolean): void {
    if (this.disabled === true) {
      return;
    }
    this.openedSelect = open;
    this.enableClickOutside = open;
  }

  // функция выбора значения года
  public onSelectYear(year: number): void {
    this.selected.year = year;
    if (this.isNeedLimitQuarter) {
      if (year === this.maxYear && this.selected.quarter > this.maxQuarter) {
        this.selected.quarter = this.maxQuarter;
      } else if (year === this.minYear && this.selected.quarter < this.minQuarter) {
        this.selected.quarter = this.minQuarter;
      }
    }
    this._onChange(this.selected);
    this.OnSelect.emit(this.selected);
    this.writeValue(this.selected);
  }

  // функция выбора значения месяца
  public onSelectQuarter(quarter: number): void {
    if (this.isNeedLimitQuarter) {
      if (this.selected.year === this.maxYear && quarter > this.maxQuarter) {
        this.selected.quarter = this.maxQuarter;
      } else if (this.selected.year === this.minYear && quarter < this.minQuarter) {
        this.selected.quarter = this.minQuarter;
      } else {
        this.selected.quarter = quarter;
      }
    } else {
      this.selected.quarter = quarter;
    }
    this._onChange(this.selected);
    this.OnSelect.emit(this.selected);
    this.openedSelect = false;
    this.enableClickOutside = false;
    this.writeValue(this.selected);
  }

  writeValue(value: IQuarterYear): void {
    this.selected = value ? value : {quarter: this.currentQuarter, year: this.currentYear};
    this.changeValue(this.selected);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
