import { QuicklinkModule } from 'ngx-quicklink';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContextMenuModule } from 'ngx-contextmenu';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './components/header/header.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './components/button/button.component';
import { InputComponent } from './components/input/input.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ToastrComponent } from './components/toastr/toastr.component';
import { ToastrModule } from 'ngx-toastr';
import { MenuComponent } from './components/menu/menu.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { BreadcrumsComponent } from './components/breadcrums/breadcrums.component';
import { SimpleSelectComponent } from './components/simple-select/simple-select.component';
import { ClickOutsideModule } from "ng-click-outside";
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { SwitchComponent } from './components/switch/switch.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { RadioboxListComponent } from './components/radiobox-list/radiobox-list.component';
import { SearchFieldComponent } from './components/search-field/search-field.component';
import { SimpleDictionaryComponent } from './components/simple-dictionary/simple-dictionary.component';
import { SimpleDictionaryModalComponent } from './components/simple-dictionary/simple-dictionary-modal/simple-dictionary-modal.component';
import { NoSelectStubComponent } from './components/no-select-stub/no-select-stub.component';
import { YearSelectComponent } from './components/year-select/year-select.component';
import { MonthSelectComponent } from './components/month-select/month-select.component';
import { QuarterSelectComponent } from './components/quarter-select/quarter-select.component';
import { TimeSelectInputComponent } from './components/calendar/time-select-input/time-select-input.component';
import { CalendarMonthComponent } from './components/calendar/calendar-month/calendar-month.component';
import { DateTimeInputComponent } from './components/calendar/date-time-input/date-time-input.component';
import { CalendarComponent } from './components/calendar/calendar/calendar.component';
import { RangeCalendarComponent } from './components/calendar/range-calendar/range-calendar.component';
import { MultiDictionaryComponent } from './components/multi-dictionary/multi-dictionary.component';
import { MultiDictionaryModalComponent } from './components/multi-dictionary/multi-dictionary-modal/multi-dictionary-modal.component';
import { CheckboxListComponent } from './components/checkbox-list/checkbox-list.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { NoAccessStubComponent } from './components/no-access-stub/no-access-stub.component';
import { UniversalCardComponent } from './components/universal-guide/universal-card/universal-card.component';
import { SearchAddressFullFormComponent } from './components/search-address-full-form/search-address-full-form.component';
import { NoAccessSoftwareRentContractComponent } from './components/no-access-software-rent-contract/no-access-software-rent-contract.component';
import { ActionMenuComponent } from './components/action-menu/action-menu.component';
import { AccordionItemComponent } from "./components/accordion-item/accordion-item.component";
import { AddressSimpleDictionaryComponent } from './components/address-simple-dictionary/address-simple-dictionary.component';
import { AddressSimpleDictionaryModalComponent } from './components/address-simple-dictionary/address-simple-dictionary-modal/address-simple-dictionary-modal.component';
import { EventLogCardComponent } from './components/event-log-card/event-log-card.component';
import { AddressCardComponent } from "@shared/components/address-card/address-card.component";
import { AddressTreeComponent } from "@shared/components/address-card/components/address-tree/address-tree.component";
import { AddressElementCardComponent } from "@shared/components/address-card/components/address-element-card/address-element-card.component";
import { AddressObjectCardComponent } from "@shared/components/address-card/components/address-object-card/address-object-card.component";
import { AddressSearchComponent } from './components/address-search/address-search.component';
import { ImportAddressComponent } from './components/address-card/components/import-address/import-address.component';
import { AuthorizationComponent } from "@shared/components/authorization/authorization.component";
import { RangeTimeSelectComponent } from './components/calendar/range-time-select/range-time-select.component';
import { DisableControlDirective } from './directives/disable-control.directive';
import { AddGuideModalComponent } from "@shared/components/add-guide-modal/add-guide-modal.component";
import { BasePageWithChildsComponent } from './components/base-page-with-childs/base-page-with-childs.component';
import { MenuItemComponent } from './components/menu/components/menu-item/menu-item.component';
import { TrimOnBlurDirective } from './directives/trim-on-blur.directive';
import * as dayjs from 'dayjs';
import * as dayjsUtc from 'dayjs/plugin/utc';
import * as dayjsFormats from 'dayjs/plugin/customParseFormat';
import * as dayjsRu from 'dayjs/locale/ru';
import * as dayjsQuarter from 'dayjs/plugin/quarterOfYear';
import * as dayjsIsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as dayjsIsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as dayjsDuration from 'dayjs/plugin/duration';
import * as dayjsArraySupport from 'dayjs/plugin/arraySupport'
dayjs.extend(dayjsQuarter);
dayjs.extend(dayjsUtc);
dayjs.extend(dayjsFormats);
dayjs.extend(dayjsIsSameOrBefore);
dayjs.extend(dayjsIsSameOrAfter);
dayjs.extend(dayjsDuration);
dayjs.extend(dayjsArraySupport);
dayjs.locale(dayjsRu);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    TranslateModule,
    RouterModule,
    NgbModule,
    ContextMenuModule,
    ClickOutsideModule,
    QuicklinkModule,
    ToastrModule.forRoot({
      toastComponent: ToastrComponent,
      positionClass: 'toast-bottom-right',
      iconClasses: {
        success: 'font-icons icon-info',
        error: 'font-icons icon-error'
      },
      enableHtml: true,
      closeButton: true,
      timeOut: 15000,
      tapToDismiss: false
    })
  ],
  declarations: [
    HeaderComponent,
    PageNotFoundComponent,
    ButtonComponent,
    InputComponent,
    LoaderComponent,
    ToastrComponent,
    MenuComponent,
    ConfirmModalComponent,
    NoAccessComponent,
    PasswordInputComponent,
    BreadcrumsComponent,
    SimpleSelectComponent,
    CheckboxComponent,
    SwitchComponent,
    TabsComponent,
    MultiSelectComponent,
    RadioboxListComponent,
    SearchFieldComponent,
    SimpleDictionaryComponent,
    SimpleDictionaryModalComponent,
    NoSelectStubComponent,
    YearSelectComponent,
    MonthSelectComponent,
    QuarterSelectComponent,
    TimeSelectInputComponent,
    CalendarMonthComponent,
    DateTimeInputComponent,
    CalendarComponent,
    RangeCalendarComponent,
    MultiDictionaryComponent,
    MultiDictionaryModalComponent,
    CheckboxListComponent,
    TextareaComponent,
    NoAccessStubComponent,
    UniversalCardComponent,
    SearchAddressFullFormComponent,
    NoAccessSoftwareRentContractComponent,
    ActionMenuComponent,
    AccordionItemComponent,
    AddressSimpleDictionaryComponent,
    AddressSimpleDictionaryModalComponent,
    EventLogCardComponent,
    AddressCardComponent,
    AddressTreeComponent,
    AddressElementCardComponent,
    AddressObjectCardComponent,
    AddressSearchComponent,
    ImportAddressComponent,
    AuthorizationComponent,
    RangeTimeSelectComponent,
    DisableControlDirective,
    AddGuideModalComponent,
    BasePageWithChildsComponent,
    MenuItemComponent,
    TrimOnBlurDirective
  ],
  exports: [
    CommonModule,
    FormsModule,
    TextMaskModule,
    NgbModule,
    QuicklinkModule,
    ClickOutsideModule,
    ReactiveFormsModule,
    TranslateModule,
    ContextMenuModule,
    HeaderComponent,
    PageNotFoundComponent,
    NoAccessComponent,
    ButtonComponent,
    InputComponent,
    LoaderComponent,
    ToastrComponent,
    PasswordInputComponent,
    SimpleSelectComponent,
    MultiSelectComponent,
    CheckboxComponent,
    SwitchComponent,
    TabsComponent,
    ConfirmModalComponent,
    RadioboxListComponent,
    SearchFieldComponent,
    SimpleDictionaryComponent,
    SimpleDictionaryModalComponent,
    NoSelectStubComponent,
    YearSelectComponent,
    MonthSelectComponent,
    QuarterSelectComponent,
    TimeSelectInputComponent,
    CalendarMonthComponent,
    DateTimeInputComponent,
    CalendarComponent,
    RangeCalendarComponent,
    MultiDictionaryComponent,
    MultiDictionaryModalComponent,
    CheckboxListComponent,
    TextareaComponent,
    NoAccessStubComponent,
    ActionMenuComponent,
    AccordionItemComponent,
    AddressSimpleDictionaryComponent,
    AddressSimpleDictionaryModalComponent,
    AddressCardComponent,
    AddressTreeComponent,
    AddressElementCardComponent,
    AddressObjectCardComponent,
    AuthorizationComponent,
    RangeTimeSelectComponent,
    DisableControlDirective,
    AddGuideModalComponent,
    BasePageWithChildsComponent,
    TrimOnBlurDirective
  ],
  entryComponents: [
    ToastrComponent,
    ConfirmModalComponent,
    SimpleDictionaryModalComponent,
    AddressSimpleDictionaryModalComponent,
    MultiDictionaryModalComponent,
    UniversalCardComponent,
    EventLogCardComponent,
    AddressCardComponent,
    AddGuideModalComponent
  ]
})
export class SharedModule { }
