import { OnDestroy } from '@angular/core';
import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Route } from "@angular/router";
import { filter } from "rxjs/operators";
import { IBreadcrum } from "@core/interfaces/breadcrum";
import { ISelectItem } from "@core/interfaces/select-item";
import { VisibilityService } from "@core/services/visibility.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrums',
  templateUrl: './breadcrums.component.html',
  styleUrls: ['./breadcrums.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumsComponent implements OnInit, OnDestroy {

  public breadcrums: IBreadcrum[] = [];
  public list: ISelectItem[] = [];

  private eventsSub: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private visibilityService: VisibilityService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.breadcrums = this.createBreadcrumbs(this.activatedRoute.root);
    this.eventsSub = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.breadcrums = this.createBreadcrumbs(this.activatedRoute.root);
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy() {
    this.eventsSub.unsubscribe();
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: IBreadcrum[] = []
  ): IBreadcrum[] {
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      if (route.snapshot.parent.routeConfig?.children?.length > 1) {
        const parentUrl = breadcrumbs[breadcrumbs.length - 2].url;
        const listItems = route.snapshot.parent.routeConfig.children;
        const listItemsWithAccess = listItems.filter((item: Route) =>
          item.data['userAccessObjects'] && item.data['userAccessObjects'].length > 0
            ? this.visibilityService.hasAnyAccessObjects
            && this.visibilityService.hasAccessObjects(item.data['userAccessObjects'])
            : !!item.component);
        this.list = listItemsWithAccess.map((item) => {
          return {
            key: parentUrl + `/${item.path}`,
            value: item.data['breadcrumb']
          }
        });
      } else {
        this.list = [];
      }
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data['breadcrumb'];
      const withComponent = !!child.component;
      if (!!label) {
        breadcrumbs.push({ label, url, withComponent });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  public changePage(item: ISelectItem) {
    this.router.navigate([item.key]);
  }
}
