import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@env/environment";
import { share } from "rxjs/operators";
import {
  ICreateAddressTree,
  IAddressElementRich,
  IAddressElementSearchResult,
  IAddressElementTree,
  IAddressObjectRich,
  IAddressObjectSearchResult,
  ICreateAddressElement,
  ICreateAddressObject,
  IAddressSearchParams,
  IAddressObjectSearchFtsResult,
  IAddressObjectWithTreeShort
} from "@core/interfaces/address";

@Injectable({
  providedIn: 'root'
})
export class AddressApiService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Получение информации по адресному объекту в локальном справочнике
   * @param id идентификатор объекта
   */
  public getElementLocalInfo(id: number): Observable<IAddressElementRich> {
    return this.httpClient.get<IAddressElementRich>(
      `${environment.API_URL}/admin/address/tree/element/${id}/info/local`
    ).pipe(share());
  }

  /**
   * Получение информации по адресному элементу в глобальном справочнике
   * @param id идентификатор элемента
   */
  public getElementGlobalInfo(id: number): Observable<IAddressElementRich> {
    return this.httpClient.get<IAddressElementRich>(
      `${environment.API_URL}/admin/address/tree/element/${id}/info/global`
    ).pipe(share());
  }

  /**
   * Дерево адресообразующих элементов для указанного элемента
   * @param id идентификатор элемента
   */
  public getElementTree(id: number): Observable<IAddressElementTree[]> {
    return this.httpClient.get<IAddressElementTree[]>(
      `${environment.API_URL}/admin/address/tree/element/${id}/list`
    ).pipe(share());
  }

  /**
   * Дерево адресообразующих элементов для локальной БД и ЦАС. Поиск по подстроке
   * @param params параметры поиска
   */
  public getElementTreeSearch(params: IAddressSearchParams): Observable<IAddressElementSearchResult[]> {
    return this.httpClient.post<IAddressElementSearchResult[]>(
      `${environment.API_URL}/admin/address/tree/element/search`, params
    ).pipe(share());
  }

  /**
   * Создание адресного элемента вручную
   * @param params 
   */
  public addElement(params: ICreateAddressElement): Observable<number> {
    return this.httpClient.post<number>(
      `${environment.API_URL}/admin/address/tree/element`, params
    ).pipe(share());
  }

  /**
   * Изменение адресного элемента, созданного вручную
   * @param params 
   */
  public updateElement(params: ICreateAddressElement): Observable<number> {
    return this.httpClient.put<number>(
      `${environment.API_URL}/admin/address/tree/element`, params
    ).pipe(share());
  }

  /**
   * Удаление адресного элемента, созданного вручную
   * @param id идентификатор элемента
   */
  public deleteElement(id: number) {
    return this.httpClient.delete(`${environment.API_URL}/admin/address/tree/element/${id}`).pipe(share());
  }

  /**
   * Получение информации по адресному объекту в локальном справочнике
   * @param id идентификатор объекта
   */
  public getObjectLocalInfo(id: number): Observable<IAddressObjectRich> {
    return this.httpClient.get<IAddressObjectRich>(
      `${environment.API_URL}/admin/address/object/${id}/info/local`
    ).pipe(share());
  }

  /**
   * Получение информации по адресному объекту в глобальном справочнике
   * @param id идентификатор объекта
   */
  public getObjectGlobalInfo(id: number): Observable<IAddressObjectRich> {
    return this.httpClient.get<IAddressObjectRich>(
      `${environment.API_URL}/admin/address/object/${id}/info/global`
    ).pipe(share());
  }

  /**
   * Адресные объекты (дома) для локальной БД и ЦАС. Поиск по подстроке
   * @param params 
   */
  public getObjectTreeSearch(params: IAddressSearchParams): Observable<IAddressObjectSearchResult[]> {
    return this.httpClient.post<IAddressObjectSearchResult[]>(
      `${environment.API_URL}/admin/address/object/search`, params
    ).pipe(share());
  }

  /**
   * Поиск полных адресов (c домами и деревом) по подстроке в ЦАС
   * @param rowCount макс. количество записей в ответе
   * @param search поисковая строка
   */
  public objectSearchFts(rowCount: number, search: string): Observable<IAddressObjectSearchFtsResult[]> {
    let body = {
      rowCount,
      search
    };
    return this.httpClient.post<IAddressObjectSearchFtsResult[]>(
      `${environment.API_URL}/admin/address/object/search/fts`, body
    ).pipe(share());
  }

  /**
   * Дерево адресообразующих элементов и объект для указанного элемента из ЦАС
   * @param globalObjectId идентификатор объекта
   */
  public getObjectTreeGlobal(globalObjectId: number): Observable<IAddressObjectWithTreeShort> {
    return this.httpClient.get<IAddressObjectWithTreeShort>(
      `${environment.API_URL}/admin/address/object/${globalObjectId}/tree/global`)
      .pipe(share());
  }

  /**
   * Создание адресного объекта вручную
   * @param params
   */
  public addObject(params: ICreateAddressObject): Observable<number> {
    return this.httpClient.post<number>(
      `${environment.API_URL}/admin/address/object`, params
    ).pipe(share());
  }

  /**
   * Изменение адресного объекта, созданного вручную
   * @param params
   */
  public updateObject(params: ICreateAddressObject): Observable<number> {
    return this.httpClient.put<number>(
      `${environment.API_URL}/admin/address/object`, params
    ).pipe(share());
  }

  /**
   * Удаление адресного объекта, созданного вручную
   * @param id идентификатор объекта
   */
  public deleteObject(id: number) {
    return this.httpClient.delete(`${environment.API_URL}/admin/address/object/${id}`).pipe(share());
  }

  /**
   * Сохранение адресного дерева и объекта(дома)
   * @param params дерево
   */
  public addTree(params: ICreateAddressTree) {
    return this.httpClient.post(
      `${environment.API_URL}/admin/address/tree`, params
    ).pipe(share());
  }
}
