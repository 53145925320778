<div class="quarterSelectClass">
  <div class="quarter-select"
       [class.disabled]="disabled">
    <div *ngIf="withIcon" class="quarter-select-icon">
      <span class="font-icons icon-calendar_date"></span>
    </div>
    <div class="quarter-select-value"
         [class.with-icon]="withIcon"
         (clickOutside)="openQuarterSelect($event, !enableClickOutside)"
         [clickOutsideEnabled]="enableClickOutside">
      <div *ngIf="selectedQuarter"
           class="quarter-select-selected"
           [class.opened]="openedSelect"
           (click)="openQuarterSelect($event, !openedSelect)">
        <span>{{selectedQuarter}}</span>
      </div>
      <ng-container [ngTemplateOutlet]="balloon"></ng-container>
    </div>
    <app-year-select class="quarter-select-year"
                     [minYear]="minYear"
                     [maxYear]="maxYear"
                     [withIcon]="false"
                     [disabled]="disabled"
                     [selected]="selected && selected.year ? selected.year : null"
                     [openDirection]="openDirection"
                     (OnSelect)="onSelectYear($event)"
    ></app-year-select>
  </div>
</div>

<!--Шаблон выпадающего списка-->
<ng-template #balloon>
  <div class="quarter-select-balloon {{openDirection}}"
       [class.opened]="openedSelect">
    <div *ngIf="quarterList && quarterList.length > 0" class="quarter-select-list">
      <div *ngFor="let item of quarterList; let ind = index"
           class="quarter-select-list-item"
           [class.active]="selected && ind + 1 === selected.quarter"
           [class.disabled]="isNeedLimitQuarter && selected && selected.year
           && ((selected.year === minYear && ind + 1 < minQuarter)
           || (selected.year === maxYear && ind + 1 > maxQuarter))"
      ><span (click)="onSelectQuarter(ind + 1)">{{item}}</span></div>
    </div>
    <div *ngIf="!quarterList || quarterList.length === 0" class="quarter-select-list-empty"> </div>
  </div>
</ng-template>
