import { Directive, Host, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[disableControl]'
})
export class DisableControlDirective {

  @Input() set disableControl(condition: boolean) {
    condition
      ? this.ngControl?.control?.disable()
      : this.ngControl?.control?.enable();
  }

  constructor(
    @Host()
    @Optional()
    private ngControl?: NgControl
  ) { }
}
