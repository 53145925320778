import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-no-access-stub',
  templateUrl: './no-access-stub.component.html',
  styleUrls: ['./no-access-stub.component.scss']
})
export class NoAccessStubComponent implements OnInit {

  @Input() text: string = null;

  constructor() { }

  ngOnInit() {
  }

}
