import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import * as dayjs from 'dayjs';
import {DateTimeService} from "@shared/services/date-time.service";
import {IDayMonthYear, IMonthYear} from "@core/interfaces/select-item";
import {IWeekDay} from "@core/interfaces/calendar";

@Component({
  selector: 'app-calendar-month',
  templateUrl: './calendar-month.component.html',
  styleUrls: ['./calendar-month.component.scss']
})
export class CalendarMonthComponent implements OnChanges {

  public dateFormat = this.dateTimeService.getDateFormat('date');
  public timeFormat = this.dateTimeService.getDateFormat('timeWithSecond');
  private currentDay = dayjs().date(); // текущее число
  private currentYear = dayjs().year(); //текущий год
  private currentMonth = dayjs().month() + 1; //текущий год
  private today: dayjs.Dayjs = dayjs(); // текущая дата

  public weeks: Array<IWeekDay[]> = []; // массив календаря
  public daysOfWeek: Array<{ number: number, name: string }> = [
    {number: 1, name: 'пн'},
    {number: 2, name: 'вт'},
    {number: 3, name: 'ср'},
    {number: 4, name: 'чт'},
    {number: 5, name: 'пт'},
    {number: 6, name: 'сб'},
    {number: 0, name: 'вс'}
  ];

  public selectedDate: IDayMonthYear = {
    day: this.currentDay,
    month:this.currentMonth,
    year: this.currentYear
  };

  public selectedTime: string = '00:00:00';

  public now: dayjs.Dayjs = dayjs([this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day]);
  public selectedMoment: dayjs.Dayjs = null;

  public isMinMonth: boolean = false;
  public isMaxMonth: boolean = false;

  private minMoment: dayjs.Dayjs = null;
  private maxMoment: dayjs.Dayjs = null;

  @Input() id: string = null;
  @Input() selected: string = null;
  @Input() format: string = this.dateFormat;
  @Input() withTime: boolean = false;
  @Input() withMinutes: boolean = true;
  @Input() withSeconds: boolean = false;
  @Input() asStartRange: boolean = false;
  @Input() asEndRange: boolean = false;
  @Input() minDate: string = null; // минимальная дата в формате DD.MM.YYYY
  @Input() maxDate: string = null; // максимальная дата в формате DD.MM.YYYY
  @Input() minMonth: number = 1; // минимальный месяц в минимальном году
  @Input() maxMonth: number = this.currentMonth; // максимальный месяц в максимальном году
  @Input() minYear: number = this.currentYear - 4; // минимальный год
  @Input() maxYear: number = this.currentYear + 4; // максимальный год
  @Input() isNeedLimit: boolean = true; // флаг необходимости ограничения месяцев по minMonth и maxMonth
  @Output() OnSelect: EventEmitter<string> = new EventEmitter<string>(); // выбор месяца/года

  constructor(private dateTimeService: DateTimeService) { }

  ngOnChanges() {
    if (!this.selected) {
      this.selectedDate = {day: this.currentDay, month: this.currentMonth, year: this.currentYear};
      this.selectedTime = '00:00:00';
      this.selectedMoment = null;
    } else {
      this.selectedDate = this.dateTimeService.getDayMonthYearFromString(this.selected, this.format);
      this.selectedTime = this.dateTimeService.getTimeStringFromString(this.selected, this.format);
      this.selectedMoment = dayjs([this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day]);
    }
    this.now = dayjs([this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day]);
    this.setMinMaxMonth();
    this.makeCalendar();
  }

  private setMinMaxMonth() {
    this.isMinMonth = this.isNeedLimit && this.selectedDate.month === this.minMonth && this.selectedDate.year == this.minYear;
    this.isMaxMonth = this.isNeedLimit && this.selectedDate.month === this.maxMonth && this.selectedDate.year == this.maxYear;
    this.minMoment = this.isNeedLimit && this.minDate ? dayjs(this.minDate, this.dateFormat) : null;
    this.maxMoment = this.isNeedLimit && this.maxDate ? dayjs(this.maxDate, this.dateFormat) : null;
  }

  private prepareValue() {
    const date = this.dateTimeService.getStringFromDayMonthYear(this.selectedDate);
    return date + ' ' + this.selectedTime;
  }

  public onChangeTime(value: string) {
    this.selectedTime = value;
    this.OnSelect.emit(this.prepareValue());
  }

  public onChangeMonth(value: IMonthYear) {
    this.selectedDate.month = value.month;
    this.selectedDate.year = value.year;
    this.now = dayjs([this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day]);
    this.selectedMoment = dayjs([this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day]);
    this.setMinMaxMonth();
    this.makeCalendar();
    this.OnSelect.emit(this.prepareValue());
  }

  public onChangeDay(value: number, isCurrentMonth: boolean, date: dayjs.Dayjs, disabled: boolean) {
    if (disabled === true) {
      return;
    }
    if (isCurrentMonth) {
      this.selectedDate.day = value;
      this.now = dayjs([this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day]);
      this.selectedMoment = date;
      this.OnSelect.emit(this.prepareValue());
    } else {
      if (date.isAfter(this.now)) {
        this.selectedDate.day = value;
        this.onChangeMonth({month: date.month() + 1, year: date.year()});
      } else {
        this.selectedDate.day = value;
        this.onChangeMonth({month: date.month() + 1, year: date.year()});
      }
    }
  }

  private makeCalendar() {
    let start = this.now.clone().startOf('month');
    const end = this.now.clone().endOf('month');
    const startDay = start.day();
    const countOfWeekday = 7;
    const lastWeekday = 6;
    this.weeks = [];

    if (startDay > 1 || startDay === 0) {
      const times = startDay === 0 ? lastWeekday : (startDay - 1);
      start = start.subtract(times, 'days');
    }

    while (start.isSameOrBefore(end, 'day')) {
      const week: IWeekDay[] = [];
      for(let i = 1; i <= countOfWeekday; i++) {
        week.push({
          date: start.date(),
          isCurrentMonth: start.month() === this.now.month(),
          isNextMonth: start.month() > this.now.month() || start.year() > this.now.year(),
          isPrevMonth: start.month() < this.now.month() || start.year() < this.now.year(),
          isToday: start.isSame(this.today, 'day'),
          moment: start.clone(),
          disabled: this.isNeedLimit && (this.minMoment && start.isBefore(this.minMoment))
            || (this.maxMoment && start.isAfter(this.maxMoment))
        });
        start = start.add(1, 'days');
      }
      this.weeks.push(week);
    }
  }

}
