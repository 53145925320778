import { map, filter, withLatestFrom } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Directive, Host, OnDestroy, Optional } from "@angular/core";
import { InputComponent } from "../components/input/input.component";

/**
 * Директива для удаления лишних пробелов после эвента OnBlur.
 * Применяется на app-input.
 */
@Directive({
    selector: '[trimOnBlur]'
})
export class TrimOnBlurDirective implements OnDestroy {

    private subscription: Subscription;
    private lastTrimmedValue: string;

    constructor(
        @Host()
        @Optional()
        private input?: InputComponent
    ) {
        if (input instanceof InputComponent) {
            this.subscription = this.input.OnBlur
                .pipe(
                    withLatestFrom(this.input.OnChange),
                    map(res => res[1]),
                    filter(res => res != this.lastTrimmedValue)
                )
                .subscribe((res) => {
                    this.lastTrimmedValue = String(res)?.trim();
                    this.input.model = this.lastTrimmedValue;
                });
        }
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
}