<div class="simpleDictionaryClass">

  <!--Для фильтров, открытие по клику на заголовок фильтра-->
  <div *ngIf="forFilter"
       class="simple-dictionary-for-filter"
       [class.disabled]="disabled">
    <div class="simple-dictionary-head">
      <div class="simple-dictionary-title" (click)="open()">{{title}}</div>
    </div>
    <div *ngIf="selected"
         class="simple-dictionary-value"
         (click)="removeSelection()">
      <div class="simple-dictionary-selected" [title]="selected.value">
        <span>{{selected.value}}</span>
      </div>
      <div class="simple-dictionary-remove">
        <span class="font-icons icon-clear"></span>
      </div>
    </div>
  </div>

  <!--Для полей, открытие по клику на выбранное значение или плейсхолдер-->
  <div *ngIf="!forFilter"
       class="simple-dictionary-for-field"
       [class.disabled]="disabled">
    <div class="simple-dictionary-for-field-value"
         [class.with-icon]="(withRemove && selected && !disabled) || (selected && disabled) && !forAddRow">
      <div *ngIf="title && !selected && !forAddRow"
           class="simple-dictionary-placeholder"
           (click)="open()">
        {{title}}
      </div>
      <div *ngIf="selected && selected.key && !forAddRow"
           class="simple-dictionary-selected"
           (click)="open()">
        <span [title]="selected.value">{{selected.value}}</span>
      </div>
      <div *ngIf="forAddRow"
           class="simple-dictionary-add"
           (click)="open()">
        <span class="font-icons icon-plus"></span>
      </div>
    </div>
    <div *ngIf="withRemove && selected && selected.key && !disabled && !forAddRow"
         class="simple-dictionary-for-field-remove"
         (click)="removeSelection()">
      <span class="font-icons icon-clear"></span>
    </div>
    <div *ngIf="selected && selected.key && disabled && !forAddRow"
         class="simple-dictionary-for-field-block">
      <span class="font-icons icon-lock"></span>
    </div>
  </div>
</div>
