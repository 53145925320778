import { Injectable, Injector } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap/modal/modal-config";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";

@Injectable({
  // свой инстанс в каждом модуле
  // чтобы гриды собирались внутри модалки
  providedIn: 'any'
})
export class OpenModalService {

  constructor(
    private modalService: NgbModal,
    private injector: Injector
  ) { }

  /**
   * показ модального окна
   * @param component - компонент окна
   * @param config - входные параметры для компонента
   * @param closeFunctionName - название функции перед закрытием модального окна - для выполнения при закрытии по фону
   * @param {NgbModalOptions} options -- конфигурация модального окна
   */
  public show(component: any, config: any, closeFunctionName?: string, options?: NgbModalOptions): NgbModalRef {
    options.injector = options.injector ?? this.injector;
    const modalRef = this.modalService.open(component, options);
    if (config) {
      for (const key in config) {
        if (config.hasOwnProperty(key)) {
          modalRef.componentInstance[key] = config[key];
        }
      }
      modalRef.result.catch(() => {
        if (closeFunctionName && config[closeFunctionName]) {
          config[closeFunctionName]();
        }
      })
    }
    return modalRef;
  }

}
