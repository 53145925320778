import {Component, Input, Output, EventEmitter, forwardRef, OnChanges} from '@angular/core';
import {IMonthYear} from "@core/interfaces/select-item";
import * as dayjs from 'dayjs';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-month-select',
  templateUrl: './month-select.component.html',
  styleUrls: ['./month-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonthSelectComponent),
      multi: true
    }
  ]
})
export class MonthSelectComponent implements OnChanges, ControlValueAccessor {

  private currentYear = dayjs().year(); //текущий год
  private currentMonth = dayjs().month() + 1; //текущий год
  public monthList: string[] = []; // список месяцев

  @Input() selected: IMonthYear = {month: this.currentMonth, year: this.currentYear}; // текущее значение
  @Input() minMonth: number = 1; // минимальный месяц в минимальном году
  @Input() maxMonth: number = this.currentMonth; // максимальный месяц в максимальном году
  @Input() minYear: number = this.currentYear - 4; // минимальный год
  @Input() maxYear: number = this.currentYear + 4; // максимальный год
  @Input() withIcon: boolean = true; // с иконкой календаря
  @Input() forCalendar: boolean = false; // для календаря
  @Input() disabled: boolean = false; // блокировка
  @Input() isNeedLimitMonth: boolean = true; // флаг необходимости ограничения месяцев по minMonth и maxMonth
  @Input() openDirection: string = 'bottom'; // направление открытия
  @Output() OnSelect: EventEmitter<IMonthYear> = new EventEmitter<IMonthYear>(); // выбор месяца/года

  public selectedMonth: string; // название выбранного месяца
  public openedSelect: boolean = false; // флаг открытия выпадающего списка
  public enableClickOutside: boolean = false; // Флаг управления работы outsideClickEvent

  public _onChange: any = () => {};
  public _onTouch: any = () => {};

  constructor(
    private translateService: TranslateService
  ) {
    this.translateService.get('DATE.MONTHS').subscribe((result) => {
      const months = result;
      for (const key in months) {
        if (months.hasOwnProperty(key)) {
          this.monthList.push(months[key]);
        }
      }
    });
    this.selectedMonth = this.monthList[this.currentMonth - 1];
  }

  ngOnChanges() {
    if (!this.selected) {
      this.selected = {month: this.currentMonth, year: this.currentYear};
    }
    this.changeValue(this.selected);
  }

  private changeValue(value: IMonthYear) {
    if (value) {
      this.selectedMonth = this.monthList[value.month - 1];
    }
    this._onChange(value);
  }

  // функция открытия выпадающего списка с месяцами
  public openMonthSelect(event, open: boolean): void {
    if (this.disabled === true) {
      return;
    }
    this.openedSelect = open;
    this.enableClickOutside = open;
  }

  // функция выбора значения года
  public onSelectYear(year: number): void {
    this.selected.year = year;
    if (this.isNeedLimitMonth) {
      if (year === this.maxYear && this.selected.month > this.maxMonth) {
        this.selected.month = this.maxMonth;
      } else if (year === this.minYear && this.selected.month < this.minMonth) {
        this.selected.month = this.minMonth;
      }
    }
    this._onChange(this.selected);
    this.OnSelect.emit(this.selected);
    this.writeValue(this.selected);
  }

  // функция выбора значения месяца
  public onSelectMonth(month: number): void {
    if (this.isNeedLimitMonth) {
      if (this.selected.year === this.maxYear && month > this.maxMonth) {
        this.selected.month = this.maxMonth;
      } else if (this.selected.year === this.minYear && month < this.minMonth) {
        this.selected.month = this.minMonth;
      } else {
        this.selected.month = month;
      }
    } else {
      this.selected.month = month;
    }
    this._onChange(this.selected);
    this.OnSelect.emit(this.selected);
    this.openedSelect = false;
    this.enableClickOutside = false;
    this.writeValue(this.selected);
  }

  writeValue(value: IMonthYear): void {
    this.selected = value ? value : {month: this.currentMonth, year: this.currentYear};
    this.changeValue(this.selected);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
