<div class="tabsClass">
  <div class="tabs-block">
  <div *ngFor="let tab of tabs" class="tab tab-{{tab.key.toString()}}"
       [class.active]="selectedTab && tab.key.toString() === selectedTab.toString()"
       [class.disabled]="tab.disabled"
       (click)="change(tab)"
  >{{tab.value}}</div>
  </div>
</div>
<ng-content class="tabs-content"></ng-content>
