import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-no-select-stub',
  templateUrl: './no-select-stub.component.html',
  styleUrls: ['./no-select-stub.component.scss']
})
export class NoSelectStubComponent implements OnInit {

  @Input() text: string = null;

  constructor() { }

  ngOnInit() {
  }

}
