import {AbstractControl, ValidatorFn} from "@angular/forms";
import {IValidator} from "@core/interfaces/validator";

export function NoWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): IValidator => {
    const isWhitespace = control.value && control.value.length > 0 ? control.value.trim().length === 0 : false;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}
