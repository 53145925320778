import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit {

  public loaderTitle: string;

  @Input() title: string; // текст
  @Input() spinnerSize: number = 80; // размер спиннера
  @Input() show: boolean = false; // флаг показа
  @Input() noFon: boolean = false; // флаг отсутствие затемнения фона

  constructor() { }

  ngOnInit() {
    this.loaderTitle = this.title ? this.title : null;
  }
}
