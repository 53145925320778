import { ApplicationRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((module) => {
    if (!environment.production) {
      import('@angular/platform-browser').then(pb => {
        pb.enableDebugTools(
          module.injector.get(ApplicationRef).components[0]
        );
        // ввести в консоль браузера ng.profiler.timeChangeDetection();
        // чтобы посмотреть циклы проверки изменений
      });
    }
  })
  .catch(err => console.error(err));
