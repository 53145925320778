import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import {ISelectItem} from "@core/interfaces/select-item";
import {DictionaryApiService} from "@core/services/api/dictionary-api.service";
import {IDictionaryField} from "@core/interfaces/dictionary";
import {DictionaryService} from "@shared/services/dictionary.service";
import {ColType, FilterType} from "@app/app.enums";
import {IUniversalGuideInfoField} from "@shared/components/universal-guide/universal-guide.interface";
import {IExtendFilter} from "@shared/components/base/ag-base-grid/ag-base-grid.interface";
import {TranslateService} from "@ngx-translate/core";
import {share} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UniversalGuideUtilsService {

  private booleanList: ISelectItem[] = [];
  private actualList: ISelectItem[] = [];
  private activeList: ISelectItem[] = [];

  constructor(
    private dictionaryApiService: DictionaryApiService,
    private dictionaryService: DictionaryService,
    private translateService: TranslateService,
  ) {
    this.translateService.get(['GENERAL']).subscribe(result => {
      this.booleanList = [
        {key: "true", value: result['GENERAL']['YES']},
        {key: "false", value: result['GENERAL']['NO']}
      ];
      this.actualList = [
        {key: "true", value: result['GENERAL']['ACTUAL']},
        {key: "false", value: result['GENERAL']['NO_ACTUAL']}
      ];
      this.activeList = [
        {key: "true", value: result['GENERAL']['ACTIVE']},
        {key: "false", value: result['GENERAL']['NO_ACTIVE']}
      ];
    });
  }

  public getDictionaryType(dictCode: string): string {
    let type: string = FilterType.simpleDictionary;
    const selectDictionaries = ['DEV_TYPE', 'ADDRESS_OBJECT_LEVEL', 'DEVICE_METER_TYPE', 'TRANSFORMATION_RATIO_TYPE',
      'RES_TYPE', 'MEASURE_PARAM_TYPE', 'MEASURE_UNIT', 'USD_DEV_TYPE', 'MEASURE_OBJECT_CATEGORY'];
    if (selectDictionaries.includes(dictCode)) {
      type = FilterType.select;
    }
    return type;
  }

  public getSelectList(dictCode: string): Observable<ISelectItem[]>{
    let result: Observable<ISelectItem[]> = of([]);
    switch (dictCode) {
      case 'DEV_TYPE': {
        result = this.dictionaryApiService.getDeviceTypeList(null, false);
        break;
      }
      case 'ADDRESS_OBJECT_LEVEL': {
        result = this.dictionaryApiService.getAddressObjectLevelList(true);
        break;
      }
      case 'DEVICE_METER_TYPE': {
        result = this.dictionaryApiService.getDeviceMeterType();
        break;
      }
      case 'TRANSFORMATION_RATIO_TYPE': {
        result = this.dictionaryApiService.getTransformationRatioType();
        break;
      }
      case 'RES_TYPE': {
        result = this.dictionaryApiService.getResourceTypeList([], false);
        break;
      }
      case 'RES_TYPE_SHORT': {
        result = this.dictionaryApiService.getResourceTypeList([], true);
        break;
      }
      case 'MEASURE_PARAM_TYPE': {
        result = this.dictionaryApiService.getMeasureParamTypeList();
        break;
      }
      case 'MEASURE_UNIT': {
        result = this.dictionaryApiService.getMeasureUnitList();
        break;
      }
      case 'USD_DEV_TYPE': {
        result = this.dictionaryApiService.getDeviceTypeList(['USPD', 'UPD'], true);
        break;
      }
      case 'MEASURE_OBJECT_CATEGORY': {
        result = this.dictionaryApiService.getMeasureObjectCategoryList()
      }
    }
    return result.pipe(share());
  }

  public getDictionary(dictCode: string): IDictionaryField {
    let result: IDictionaryField = null;
    switch (dictCode) {
      case 'MEASURE_TOOL_NAME': {
        result = {
          dictParams: {...this.dictionaryService.getDefaultParams(), ...{onlyActual: false, deviceTypeId: null}},
          dictLoading: false,
          dictList: this.dictionaryService.getDefaultList(),
          dictListRequest$: (params) => this.dictionaryApiService.getMeasureToolsDictionary(params)
        };
        break;
      }
      case 'MEASURE_TOOL_TYPE': {
        result = {
          dictParams: {...this.dictionaryService.getDefaultParams(), ...{deviceTypeIds: null, measureToolId: null}},
          dictLoading: false,
          dictList: this.dictionaryService.getDefaultList(),
          dictListRequest$: (params) => this.dictionaryApiService.getMeasureToolTypeDictionary(params)
        };
        break;
      }
      case 'VENDOR': {
        result = {
          dictParams: {...this.dictionaryService.getDefaultParams(), ...{onlyActual: false}},
          dictLoading: false,
          dictList: this.dictionaryService.getDefaultList(),
          dictListRequest$: (params) => this.dictionaryApiService.getVendorsDictionary(params)
        };
        break;
      }
      case 'ADDRESS_BUILDING_STATE': {
        result = {
          dictParams: this.dictionaryService.getDefaultParams(),
          dictLoading: false,
          dictList: this.dictionaryService.getDefaultList(),
          dictListRequest$: (params) => this.dictionaryApiService.getAddressBuildingStateDictionary(params)
        };
        break;
      }
      case 'ADDRESS-BUILDING-STATE': {
        result = {
          dictParams: this.dictionaryService.getDefaultParams(),
          dictLoading: false,
          dictList: this.dictionaryService.getDefaultList(),
          dictListRequest$: (params) => this.dictionaryApiService.getAddressBuildingStateDictionary(params)
        };
        break;
      }
      case 'ADDRESS_BUILDING_TYPE': {
        result = {
          dictParams: this.dictionaryService.getDefaultParams(),
          dictLoading: false,
          dictList: this.dictionaryService.getDefaultList(),
          dictListRequest$: (params) => this.dictionaryApiService.getAddressBuildingTypeDictionary(params)
        };
        break;
      }
      case 'ADDRESS_OBJECT_TYPE': {
        result = {
          dictParams: this.dictionaryService.getDefaultParams(),
          dictLoading: false,
          dictList: this.dictionaryService.getDefaultList(),
          dictListRequest$: (params) => this.dictionaryApiService.getAddressObjectTypeDictionary(params)
        };
        break;
      }
      case 'ADDRESS_ESTATE_TYPE': {
        result = {
          dictParams: this.dictionaryService.getDefaultParams(),
          dictLoading: false,
          dictList: this.dictionaryService.getDefaultList(),
          dictListRequest$: (params) => this.dictionaryApiService.getAddressEstateTypeDictionary(params)
        };
        break;
      }
      case 'ADDRESS_MEASURE_OBJECT_TYPE': {
        result = {
          dictParams: this.dictionaryService.getDefaultParams(),
          dictLoading: false,
          dictList: this.dictionaryService.getDefaultList(),
          dictListRequest$: (params) => this.dictionaryApiService.getMeasureObjectTypeDictionary(params)
        };
        break;
      }
      case 'ORGANIZATION': {
        result = {
          dictParams: this.dictionaryService.getDefaultParams(),
          dictLoading: false,
          dictList: this.dictionaryService.getDefaultList(),
          dictListRequest$: (params) => this.dictionaryApiService.getOrganizationsDictionary(params)
        };
        break;
      }
      case 'ADDRESS': {
        result = {
          dictParams: this.dictionaryService.getDefaultParams(),
          dictLoading: false,
          dictList: this.dictionaryService.getDefaultList(),
          dictListRequest$: (params) => this.dictionaryApiService.getAddressDictionary(params)
        };
        break;
      }
    }
    return result;
  }

  public getExtendFilters(fields: IUniversalGuideInfoField[]): IExtendFilter[] {
    const result: IExtendFilter[] = [];
    if (fields && fields.length > 0) {
      fields.forEach((field: IUniversalGuideInfoField) => {
        if (field.colFiltered || (!field.colFiltered && field.refDictCode && field.refFields
          && field.refFields.length > 0 && field.refFields[0].colFiltered)) {
          const refDictMode = field.refDictCode ? this.getDictionaryType(field.refDictCode) : null;
          const refList = refDictMode && refDictMode === FilterType.select
            ? this.getSelectList(field.refDictCode)
            : null;
          const refDictionary = refDictMode && refDictMode === FilterType.simpleDictionary
            ? this.getDictionary(field.refDictCode)
            : null;
          result.push(
            {
              field: field.colAliasDb,
              name: field.refFields && field.refFields.length > 0
                ? field.refFields[0].colNameUi
                : field.colNameUi,
              criterias: field.colAvailableFilterCriteria
                ? field.colAvailableFilterCriteria
                : field.refFields && field.refFields.length > 0
                  ? field.refFields[0].colAvailableFilterCriteria
                  : [],
              type: refDictMode ? FilterType[refDictMode] : this.getExtendFilterType(field.colType),
              step: !refDictMode ? this.getExtendFilterInputStep(field.colType) : null,
              format: field.colFormat,
              values: field.colType === ColType.BOOL ? this.booleanList : [],
              listField: refList,
              dictionaryField: refDictionary
            }
          );
        }
      });
    }
    return result;
  }

  private getExtendFilterType(colType: ColType): FilterType {
    if (colType === ColType.TIMESTAMP) {
      return FilterType.date;
    } else if (colType === ColType.VARCHAR) {
      return FilterType.text;
    } else if (colType === ColType.NUMBER || colType === ColType.INTEGER || colType === ColType.BIGINT) {
      return FilterType.number;
    } else if (colType === ColType.BOOL) {
      return FilterType.select;
    }
  }

  private getExtendFilterInputStep(colType: ColType): number {
    if (colType === ColType.NUMBER) {
      return 0.1;
    } else if (colType === ColType.INTEGER || colType === ColType.BIGINT) {
      return 1;
    } else {
      return null;
    }
  }

  public getBooleanList(): ISelectItem[] {
    return this.booleanList;
  }

  public getActualList(): ISelectItem[] {
    return this.actualList;
  }

  public getActiveList(): ISelectItem[] {
    return this.activeList;
  }
}
