<div class="timeSelectInputClass" [class.with-icon]="withIcon" [class.with-seconds]="withSeconds">
  <div *ngIf="withIcon" class="time-select-input-icon">
    <span class="font-icons icon-calendar_time"></span>
  </div>
  <div class="time-select-input-value">
    <app-input class="time-select-input-value-input for-time"
               [ngModel]="hour"
               [disablePlaceholderAnimation]="true"
               [inputId]="'time_hour-' + id"
               [mask]="mask"
               [textMaskConfigs]="hourMaskConfig"
               [disabled]="disabled"
               [withReplace]="true"
               (OnChange)="changeHour($event)"
               (OnBlur)="sendHour()"
               (keydown.arrowDown)="changeHourByArrow('minus')"
               (keydown.arrowUp)="changeHourByArrow('plus')"
    ></app-input>
    <span class="time-select-input-value-separator">:</span>
    <app-input *ngIf="withMinutes || (!withMinutes && withSeconds)"
               class="time-select-input-value-input for-time"
               [ngModel]="minute"
               [disablePlaceholderAnimation]="true"
               [inputId]="'time_minute-' + id"
               [mask]="mask"
               [textMaskConfigs]="minuteMaskConfig"
               [disabled]="disabled"
               [withReplace]="true"
               (OnChange)="changeMinute($event)"
               (OnBlur)="sendMinute()"
               (keydown.arrowDown)="changeMinuteByArrow('minus')"
               (keydown.arrowUp)="changeMinuteByArrow('plus')"
    ></app-input>
    <span *ngIf="!withMinutes && !withSeconds"
          class="time-select-input-value-text">{{minute}}</span>
    <span *ngIf="withSeconds" class="time-select-input-value-separator">:</span>
    <app-input *ngIf="withSeconds"
               class="time-select-input-value-input for-time"
               [ngModel]="second"
               [disablePlaceholderAnimation]="true"
               [inputId]="'time_second-' + id"
               [mask]="mask"
               [textMaskConfigs]="secondMaskConfig"
               [disabled]="disabled"
               [withReplace]="true"
               (OnChange)="changeSecond($event)"
               (OnBlur)="sendSecond()"
               (keydown.arrowDown)="changeSecondByArrow('minus')"
               (keydown.arrowUp)="changeSecondByArrow('plus')"
    ></app-input>
  </div>
</div>
