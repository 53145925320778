import {Component, Input, OnInit} from '@angular/core';
import {SimpleDictionaryModalComponent} from "@shared/components/simple-dictionary/simple-dictionary-modal/simple-dictionary-modal.component";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {OpenModalService} from "@shared/services/open-modal.service";

@Component({
  selector: 'app-address-simple-dictionary-modal',
  templateUrl: './address-simple-dictionary-modal.component.html',
  styleUrls: ['./address-simple-dictionary-modal.component.scss']
})
export class AddressSimpleDictionaryModalComponent extends SimpleDictionaryModalComponent implements OnInit {

  @Input() withAdd: boolean = false; // с функцией добавления

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: OpenModalService
  ) {
    super(activeModal);
  }

  public openAddModal() {

  }

  private afterAdd() {
    this.params.page.pageNumber = 1;
    if (this.dictList) {
      this.dictList.nativeElement.scrollTo(0, 0);
    }
    if (this.OnLoadList) {
      this.OnLoadList(this.params);
    }
  }
}
