import {Component, forwardRef, Input, EventEmitter, Output, OnChanges} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements OnChanges, ControlValueAccessor {

  @Input() value: boolean;  // выбранное значение
  @Input() name: string; // имя чекбокса
  @Input() required: boolean; // обязательность выбора
  @Input() disabled: boolean = false; // блокировка
  @Input() readonly : boolean = false; // блокировка
  @Input() withThreeValue: boolean = false; // чекбокс с 3 состояниями
  @Input() valueFull: boolean = false; // чекбокс с 3 состояниями - полный выбор
  @Input() title: string; // заголовок рядом с чекбоксом
  @Input() icon: string = null; // иконка рядом с чекбоксом
  @Input() withSelectTitle: boolean = false; // с выделение заголовка у выбранного чекбокса (для списков)
  @Output() OnChange: EventEmitter<boolean> = new EventEmitter<boolean>(); // событие при смене состояния

  public _onChange: any = () => {};
  public _onTouch: any = () => {};

  constructor() { }

  ngOnChanges() {
    this.changeValue(this.value);
  }

  private changeValue(value: boolean) {
    this.value = value;
    this._onChange(this.value);
  }

  writeValue(value: boolean) {
    if (value !== this.value) this.changeValue(value);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  public change(e): void {
    if (!this.disabled && !this.readonly) {
      this.changeValue(e.target.checked);
      this.OnChange.emit(e.target.checked);
    }
  }
}
