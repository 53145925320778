import { Injectable } from "@angular/core";
import {
  MeasureObjectState,
  MeasureObjectStateIcon, ResourceType,
  ResourceTypeCode,
  ResourceTypeColor,
  ResourceTypeIcon
} from "@app/app.enums";
import { IFileName } from "@core/interfaces/file";

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  /**
   * иконка статуса объекта учета, оборудования и точки учета
   * @param stateCode
   */
  public getMeasureObjectStateIcon(stateCode: MeasureObjectState): MeasureObjectStateIcon {
    return MeasureObjectStateIcon[stateCode] ? MeasureObjectStateIcon[stateCode] : null;
  }

  /**
   * иконка типа точки учета
   * @param resourceTypeCode
   */
  public getResourceTypeIcon(resourceTypeCode: ResourceTypeCode): ResourceTypeIcon {
    return ResourceTypeIcon[resourceTypeCode] ? ResourceTypeIcon[resourceTypeCode] : null;
  }

  /**
   * название типа точки учета
   * @param resourceTypeCode
   */
  public getResourceTypeName(resourceTypeCode: ResourceTypeCode): ResourceType {
    return ResourceType[resourceTypeCode] ? ResourceType[resourceTypeCode] : null;
  }

  /**
   * цвет типа точки учета
   * @param resourceTypeCode
   */
  public getResourceTypeColor(resourceTypeCode: ResourceTypeCode): ResourceTypeColor {
    return ResourceTypeColor[resourceTypeCode] ? ResourceTypeColor[resourceTypeCode] : null;
  }

  /**
   * получение наименования и расширения файла из его полного названия
   * @param fileName
   */
  public getFileNameWithExtension(fileName: string): IFileName {
    const arr: string[] = fileName && fileName.length > 0 ? fileName.split('.') : [fileName];
    let name: string = null;
    let ext: string = null;
    if (arr && arr.length > 1) {
      ext = arr[arr.length - 1];
      name = fileName.substr(0, fileName.length - ext.length - 1);
    } else {
      name = fileName;
    }
    return { name: name, extension: ext };
  }

  /**
   * Сравнить объекты по ключам.
   * True - равны, false - не равны, null - оба объекта null.
   * @param a объект номер раз
   * @param b объект номер два
   * @param strict строгое или не строгое сравнение полей
   */
  public compareObjects(a: Object, b: Object, strict: boolean = false): boolean {
    if (!a && !b) {
      return null;
    }
    if (!a || !b) {
      return false;
    }
    let keys = Object.keys(a);
    // при строгом сравнении возвращаем false, если кол-во полей не совпадает
    if (strict && keys.length != Object.keys(b).length) {
      return false;
    }
    for (let key of keys) {
      // пропускаем пустые поля при не строгом сравнении
      if (!strict && !a[key] && !b[key]) {
        continue;
      }
      // проверяем вложенность объектов
      if (typeof a[key] == 'object' && typeof b[key] == 'object') {
        let res = this.compareObjects(a[key], b[key], strict);
        if (res) {
          continue;
        }
        else {
          return false;
        }
      }
      else {
        if (!strict && a[key] != b[key]) {
          return false;
        }
        if (strict && a[key] !== b[key]) {
          return false;
        }
      }
    }
    return true;
  }
}
