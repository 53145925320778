import { fromEvent, Subscription } from 'rxjs';
import { Component, EventEmitter, Input, Output, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent implements AfterViewInit, OnDestroy {

  @Input() value: string = null; //значение
  @Input() searchId: string = 'search'; //ид поля
  @Input() placeholder: string = 'Поиск'; // плейсхолдер
  @Input() searchWithDebounce: boolean = true; // поиск с зажержкой после ввода
  @Input() debounce: number = 3000; // время задержки в миллисекундах
  @Input() withTransparentBackground: boolean = false; // для отображения с прозрачным фоном
  @Input() type: 'number' | 'text' = 'text';
  @Output() OnSearch: EventEmitter<string> = new EventEmitter<string>(); //применение значения по кнопке Поиск, по Enter, при очистке

  @ViewChild('inputField') private inputField: ElementRef;
  private onInput$: Subscription = new Subscription();

  ngAfterViewInit(): void {
    if (this.searchWithDebounce) {
      this.onInput$ = fromEvent(this.inputField.nativeElement, 'input')
        .pipe(
          debounceTime(this.debounce),
          map((event: any) => <string>event.target.value)
        )
        .subscribe(
          (res) => {
            if (res == '') {
              res = null;
            }
            if (this.value !== res) {
              this.value = res;
              this.OnSearch.emit(res);
            }
          }
        );
    }
  }

  ngOnDestroy(): void {
    this.onInput$.unsubscribe();
  }

  /**
   * Ручной поиск по нажатию Enter / клику на иконку
   */
  public search() {
    let res = this.inputField.nativeElement.value;
    this.value = res && res != '' ? res : null;
    this.OnSearch.emit(this.value);
  }

  public clear() {
    this.value = null;
    this.OnSearch.emit(null);
  }
}
