<div class="buttonClass"
     [class.disabled]="disabled"
     (click)="onClickBtn($event)">
  <span *ngIf="icon && iconSlot == 'start'"
        class="font-icons {{icon}}"
        [style.font-size.px]="iconSize"
        [style.color]="iconColor"
  ></span>
  <span class="text"
        [innerHtml]="title"
  ></span>
  <span *ngIf="icon && iconSlot == 'end'"
        class="font-icons {{icon}}"
        [style.font-size.px]="iconSize"
        [style.color]="iconColor"
  ></span>
</div>
