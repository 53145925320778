<div class="AddressElementCardClass">
    <div class="address-element-header">
        <ng-container *ngFor="let item of headerPaths">
            {{item}}
            <span class="font-icons icon-forward-long"></span>
        </ng-container>
        {{header}}
    </div>
    <div class="address-element-content scroll-block">
        <app-tabs *ngIf="regime == 'add' && element.position != 0"
                  [tabs]="tabsItems"
                  [selected]="activeTabKey"
                  (OnSelect)="changeActiveTab($event)">
        </app-tabs>
        <ng-container *ngTemplateOutlet="activeTabKey=='FIAS' ? fiasTemplate : manualTemplate"></ng-container>
    </div>
</div>

<ng-template #fiasTemplate>
    <!-- ПОИСК В СПРАВОЧНИКЕ И УДАЛЕНИЕ ВЫБРАННОГО ИЗ СПРАВОЧНИКА
    ДОСТУПНО ТОЛЬКО В РЕЖИМЕ СОЗДАНИЯ -->
    <ng-container *ngIf="regime == 'add'">
        <app-address-search *ngIf="!element.richData"
                            [displayItems]="searchResults"
                            (OnSearch)="searchElements($event)"
                            (OnSelectValue)="selectElement($event)">
        </app-address-search>
        <div *ngIf="element.richData"
             class="element-clear-button"
             [class.not-region]="element.position != 0">
            <span class="text">{{element.richData.name}}</span>
            <span class="font-icons icon-clear element-clear-button-icon" (click)="removeSelectedElement()"></span>
        </div>
    </ng-container>
    <!-- ОТОБРАЖЕНИЕ ВЫБРАННОГО -->
    <div *ngIf="element.richData" class="address-element-view">
        <div class="address-element-view-item">
            <div class="address-element-view-item-label">
                {{'ADDRESS_CARD.ELEMENT_FIELDS.NAME' | translate}}
            </div>
            <div class="address-element-view-item-value">
                {{element.richData.name}}
            </div>
        </div>
        <div class="break-flex-row"></div>

        <div class="address-element-view-item">
            <div class="address-element-view-item-label">
                {{'ADDRESS_CARD.ELEMENT_FIELDS.TYPE' | translate}}
            </div>
            <div class="address-element-view-item-value">
                {{element.richData.objectTypeFullName}}
            </div>
        </div>
        <div class="address-element-view-item">
            <div class="address-element-view-item-label">
                {{'ADDRESS_CARD.ELEMENT_FIELDS.LEVEL' | translate}}
            </div>
            <div class="address-element-view-item-value">
                {{element.richData.level}}
            </div>
        </div>
        <div class="address-element-view-item">
            <div class="address-element-view-item-label">
                {{'ADDRESS_CARD.ELEMENT_FIELDS.POSTCODE' | translate}}
            </div>
            <div class="address-element-view-item-value">
                {{element.richData.postalCode}}
            </div>
        </div>
        <div class="break-flex-row"></div>

        <div class="address-element-view-item">
            <div class="address-element-view-item-label">
                {{'ADDRESS_CARD.ELEMENT_FIELDS.START_DATE' | translate}}
            </div>
            <div class="address-element-view-item-value">
                {{element.richData.startDate}}
            </div>
        </div>
        <div class="address-element-view-item">
            <div class="address-element-view-item-label">
                {{'ADDRESS_CARD.ELEMENT_FIELDS.END_DATE' | translate}}
            </div>
            <div class="address-element-view-item-value">
                {{element.richData.endDate}}
            </div>
        </div>
        <div class="address-element-view-item">
            <div class="address-element-view-item-label">
                {{'GENERAL.ACTUAL' | translate}}
            </div>
            <div class="address-element-view-item-value">
                <ng-container *ngIf="element.richData.isActual == 1; else notActualText">
                    {{'GENERAL.YES' | translate}}
                </ng-container>
                <ng-template #notActualText>
                    {{'GENERAL.NO' | translate}}
                </ng-template>
            </div>
        </div>
        <div class="address-element-view-item">
            <div class="address-element-view-item-label">
                {{'GENERAL.ACTIVE' | translate}}
            </div>
            <div class="address-element-view-item-value">
                <ng-container *ngIf="element.richData.liveStatus == 1; else notAliveText">
                    {{'GENERAL.YES' | translate}}
                </ng-container>
                <ng-template #notAliveText>
                    {{'GENERAL.NO' | translate}}
                </ng-template>
            </div>
        </div>

        <div class="address-element-view-item">
            <div class="address-element-view-item-label">
                {{'ADDRESS_CARD.ELEMENT_FIELDS.ID' | translate}}
            </div>
            <div class="address-element-view-item-value">
                {{element.richData.id}}
            </div>
        </div>
        <div class="address-element-view-item two-field">
            <div class="address-element-view-item-label">
                {{'ADDRESS_CARD.ELEMENT_FIELDS.UID' | translate}}
            </div>
            <div class="address-element-view-item-value">
                {{element.richData.systemGuid ? element.richData.systemGuid : element.richData.globalId}}
            </div>
        </div>
        <div class="address-element-view-item">
            <div class="address-element-view-item-label">
                {{'ADDRESS_CARD.ELEMENT_FIELDS.SOURCE' | translate}}
            </div>
            <div class="address-element-view-item-value">
                {{element.richData.source}}
            </div>
        </div>

        <div class="address-element-view-item">
            <div class="address-element-view-item-label">
                {{'ADDRESS_CARD.ELEMENT_FIELDS.ADD_DATE' | translate}}
            </div>
            <div class="address-element-view-item-value">
                {{element.richData.created}}
            </div>
        </div>
        <div class="address-element-view-item">
            <div class="address-element-view-item-label">
                {{'ADDRESS_CARD.ELEMENT_FIELDS.UPDATE_DATE' | translate}}
            </div>
            <div class="address-element-view-item-value">
                {{element.richData.modified}}
            </div>
        </div>
    </div>
</ng-template>

<!-- СОЗДАНИЕ ВРУЧНУЮ -->
<ng-template #manualTemplate>
    <form class="address-element-form" [formGroup]="form">
        <div class="address-element-form-top-border"></div>
        <div class="address-element-form-item one-field">
            <div class="address-element-form-item-label required">
                <span class="form-field-label">{{'ADDRESS_CARD.ELEMENT_FIELDS.MANUAL_TYPE' | translate}}</span>
            </div>
            <div class="address-element-form-item-field type">
                <ng-container
                    *ngTemplateOutlet="elementTypeManual===false ? elementTypeDictionaryBlock : elementTypeManualBlock">
                </ng-container>
                <ng-template #elementTypeDictionaryBlock>
                    <ng-container
                      *ngTemplateOutlet="!form.controls['objectTypeFullName'].value ? elementTypeNotSelectedBlock : elementTypeSelectedBlock">
                    </ng-container>
                </ng-template>

                <ng-template #elementTypeManualBlock>
                    <app-input formControlName="objectTypeShortName"
                               class="address-element-form-item-input type-short"
                               [inputId]="'objectTypeShortName'"
                               [placeholder]="'ADDRESS_CARD.ELEMENT_FIELDS.MANUAL_TYPE_SHORT_NAME' | translate">
                    </app-input>
                    <app-input formControlName="objectTypeFullName"
                               class="address-element-form-item-input type-long"
                               [inputId]="'objectTypeFullName'"
                               [placeholder]="'ADDRESS_CARD.ELEMENT_FIELDS.MANUAL_TYPE_FULL_NAME' | translate">
                    </app-input>
                    <div class="break-flex-row"></div>
                    <div class="element-clickable-text cancel-manual-type" (click)="changeElementTypeSelection()">
                        {{'ADDRESS_CARD.ELEMENT_FIELDS.CLOSE_ADD_MANUAL_TYPE' | translate}}
                    </div>
                </ng-template>

                <ng-template #elementTypeNotSelectedBlock>
                    <app-simple-dictionary
                      class="address-element-card-form-item-select"
                      [titleDictionary]="'ADMIN_NSI.ADDRESS_ELEMENT.ELEMENT_TYPE' | translate"
                      [withRemove]="true"
                      [title]="'GENERAL.SELECT' | translate"
                      [loadingInProgress]="elementTypeDictionary.dictLoading"
                      [params]="elementTypeDictionary.dictParams"
                      [list]="elementTypeDictionary.dictList"
                      (OnLoadList)="updateDictionaryList($event, elementTypeDictionary)"
                      (OnSelect)="changeElementType($event)">
                    </app-simple-dictionary>
                    <div class="element-clickable-text" (click)="changeElementTypeSelection()">
                        {{'GENERAL.CREATE_MANUAL' | translate}}
                    </div>
                </ng-template>

                <ng-template #elementTypeSelectedBlock>
                    <div class="element-clear-button type">
                        <span class="text">{{form.controls['objectTypeFullName'].value}}</span>
                        <span class="font-icons icon-clear element-clear-button-icon"
                            (click)="removeSelectedElementType()"></span>
                    </div>
                </ng-template>
            </div>
        </div>

        <div class="address-element-form-item one-field">
            <div class="address-element-form-item-label required">
                <span class="form-field-label">{{'ADDRESS_CARD.ELEMENT_FIELDS.NAME' | translate}}</span>
            </div>
            <div class="address-element-form-item-field">
                <app-input formControlName="name"
                           class="address-element-form-item-input long"
                           [inputId]="'name'"
                           [disablePlaceholderAnimation]="true">
                </app-input>
            </div>
        </div>

        <div class="break-flex-row"></div>
        <div class="address-element-form-item">
            <div class="address-element-form-item-label required">
                <span class="form-field-label">{{'ADDRESS_CARD.ELEMENT_FIELDS.START_DATE' | translate}}</span>
            </div>
            <div class="address-element-form-item-field">
                <app-calendar formControlName="startDate"
                              class="address-element-form-item-input"
                              [id]="'startDate'"
                              [isNeedLimit]="false"
                              [format]="'DD.MM.YYYY'">
                </app-calendar>
            </div>
        </div>
        <div class="address-element-form-item">
            <div class="address-element-form-item-label">
                <span class="form-field-label">{{'ADDRESS_CARD.ELEMENT_FIELDS.END_DATE' | translate}}</span>
            </div>
            <div class="address-element-form-item-field">
                <app-calendar formControlName="endDate"
                              class="address-element-form-item-input"
                              [id]="'endDate'"
                              [isNeedLimit]="false"
                              [format]="'DD.MM.YYYY'">
                </app-calendar>
            </div>
        </div>
        <div class="address-element-form-item">
            <div class="address-element-form-item-label">
                <span class="form-field-label">{{'ADDRESS_CARD.ELEMENT_FIELDS.POSTCODE' | translate}}</span>
            </div>
            <div class="address-element-form-item-field">
                <app-input formControlName="postalCode"
                           class="address-element-form-item-input"
                           [inputId]="'postalCode'"
                           [disablePlaceholderAnimation]="true"
                           [maxLength]="6">
                </app-input>
            </div>
        </div>

        <div class="break-flex-row"></div>
        <div class="address-element-form-item">
            <app-switch formControlName="isActual" [title]="'GENERAL.ACTUAL' | translate">
            </app-switch>
        </div>
        <div class="address-element-form-item">
            <app-switch formControlName="liveStatus" [title]="'GENERAL.ACTIVE' | translate">
            </app-switch>
        </div>
    </form>
</ng-template>
