<div class="multiDictionaryClass">

  <!--Для фильтров, открытие по клику на заголовок фильтра-->
  <div *ngIf="forFilter"
       class="multi-dictionary-for-filter"
       [class.disabled]="disabled">
    <div class="multi-dictionary-head">
      <div class="multi-dictionary-title" (click)="open()">{{title}}</div>
    </div>
    <div class="multi-dictionary-values" *ngIf="selected && selected.length > 0">
      <div *ngFor="let item of selected | slice: 0: countShowValuesForFilter"
           class="multi-dictionary-value"
           [class.with-remove]="selected.length > 1"
           (click)="removeSelectItem(item)">
        <div class="multi-dictionary-selected" [title]="item.value">
          <span>{{item.value}}</span>
        </div>
        <div class="multi-dictionary-remove">
          <span class="font-icons icon-clear"></span>
        </div>
      </div>
      <div *ngIf="selected.length > 1"
           class="multi-dictionary-remove-all"
           (click)="removeSelection()">
        <span>{{selected.length}}</span>
        <span class="font-icons icon-clear"></span>
      </div>
    </div>
  </div>

  <!--Для полей, открытие по клику на выбранное значение или плейсхолдер-->
  <div *ngIf="!forFilter"
       class="multi-dictionary-for-field"
       [class.disabled]="disabled">
    <div class="multi-dictionary-for-field-value" [class.with-icon]="selected && selected.length > 0">
      <div *ngIf="title && (!selected || selected.length === 0)"
           class="multi-dictionary-placeholder"
           (click)="open()">
        {{title}}
      </div>
      <div *ngIf="selected && selected.length > 0"
           class="multi-dictionary-selected"
           (click)="open()">
        <span class="multi-dictionary-selected-text" [title]="selectedItemsValue.join(', ')">{{selectedItemsValue.join(', ')}}</span>
        <span class="multi-dictionary-selected-count">({{selectedItemsValue.length}})</span>
      </div>
    </div>
    <div *ngIf="selected && selected.length > 0 && !disabled"
         class="multi-dictionary-for-field-remove"
         (click)="removeSelection()">
      <span class="font-icons icon-clear"></span>
    </div>
    <div *ngIf="selected && selected.length > 0 && disabled"
         class="multi-dictionary-for-field-block">
      <span class="font-icons icon-lock"></span>
    </div>
  </div>

</div>
