import { AddressCardService } from './../../address-card.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressApiService } from '@core/services/api/address/address-api.service';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { IAddressObjectSearchFtsResult, ICreateAddressTreeElement, ICreateAddressTreeObject } from '@app/core/interfaces/address';
import { map, switchMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-import-address',
  templateUrl: './import-address.component.html',
  styleUrls: ['./import-address.component.scss']
})
export class ImportAddressComponent implements OnInit, OnDestroy {

  @Input() debounceTime: number = 1000;

  /**
   * Событие импорта адреса. [0] - объект, [1] - дерево.
   */
  @Output() OnApplyEvent: EventEmitter<[ICreateAddressTreeObject, ICreateAddressTreeElement[]]> = new EventEmitter<[ICreateAddressTreeObject, ICreateAddressTreeElement[]]>();
  @Output() OnApply: Function; // функция импорта адреса

  public searchValue: string; // значение поля поиска
  public searchResults: IAddressObjectSearchFtsResult[] = []; // результаты поиска
  public searchLoading: boolean = false; // флаг загрузки списка
  public selectedItem: IAddressObjectSearchFtsResult; // выбранный из списка элемент
  public importLoading: boolean = false; // флаг загрузки импорта

  constructor(
    private addressApiService: AddressApiService,
    private addressCardService: AddressCardService,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Поиск подходящих объектов по подстроке
   * @param value поисковое значение
   */
  public searchObjects(value: string) {
    if (!value || value == '') {
      this.selectedItem = null;
      this.searchResults = [];
      return;
    }
    this.searchLoading = true;
    this.addressApiService.objectSearchFts(20, value).subscribe(
      (res) => {
        this.searchResults = res;
        this.searchLoading = false;
      },
      (error) => {
        this.searchLoading = false;
        this.toastr.error(
          this.translateService.instant('ADDRESS_CARD.ERRORS.SEARCH_OBJECT'),
          this.translateService.instant('GENERAL.ERROR_LOAD')
        );
      }
    )
  }

  /**
   * Подтвердить выбор, получить подробные данные по объекту и дереву
   */
  public applySelectedObject() {
    this.importLoading = true;
    this.addressApiService.getObjectTreeGlobal(this.selectedItem.id)
      .pipe(
        // разделяем объект и дерево, сортируем дерево
        map(res => {
          let obj: ICreateAddressTreeObject = {
            addressManual: null,
            addressRegistryId: res.addressRegistryId,
            sourceType: null,
            name: res.name,
            objectTypeFullName: this.translateService.instant('ADDRESS_CARD.ADD_OBJECT')
          };
          let tree: ICreateAddressTreeElement[] = res.nsiAddressTreeList.map(item => {
            return {
              addressTreeManual: null,
              addressRegistryId: item.addressRegistryId,
              localId: item.localId,
              position: res.nsiAddressTreeList.length - item.treeOrder,
              sourceType: null,
              name: item.name,
              objectTypeFullName: item.objectTypeFullName,
              parentId: item.parentLocalId ? item.parentLocalId : item.parentAddressRegistryId,
              systemGuid: item.systemGuid
            }
          }).sort((a, b) => a.position - b.position);
          return <[ICreateAddressTreeObject, ICreateAddressTreeElement[]]>[obj, tree];
        }),
        // получаем подробную информацию по объекту и элементам
        switchMap(res => {
          let oReq = this.addressCardService.getObjectRichData(res[0])
            .pipe(
              map(richData => {
                return { ...res[0], richData, sourceType: richData.source } as ICreateAddressTreeObject;
              })
            );
          let eReq = forkJoin(
            res[1].map(item => {
              return this.addressCardService.getElementRichData(item)
                .pipe(
                  map(res => {
                    return { ...item, richData: res, sourceType: res.source } as ICreateAddressTreeElement;
                  })
                )
            })
          )
          return forkJoin([oReq, eReq]);
        })
      )
      .subscribe(
        (res) => {
          this.importLoading = false;
          this.apply(res[0], res[1]);
        },
        (error) => {
          this.importLoading = false;
          this.toastr.error(
            this.translateService.instant('ADDRESS_CARD.ERRORS.IMPORT_ADDRESS'),
            this.translateService.instant('GENERAL.ERROR_LOAD')
          );
        }
      );
  }

  /**
   * Применить импорт
   * @param obj объект
   * @param tree дерево элементов
   */
  private apply(obj: ICreateAddressTreeObject, tree: ICreateAddressTreeElement[]) {
    if (this.OnApply) {
      this.OnApply(obj, tree);
    }
    if (this.OnApplyEvent) {
      this.OnApplyEvent.next([obj, tree])
    }
    this.closeModal();
  }

  public closeModal() {
    this.activeModal.dismiss();
  }
}
