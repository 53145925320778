<div class="switchClass">
  <label class="switch-box"
    [class.disabled]="disabled">
    <input type="checkbox"
      [ngModel]="value"
      [attr.id]="name"
      [attr.name]="name"
      [disabled]="disabled"
      (change)="change($event)">
    <span class="checkmark"
      [class.with-state-title]="stateTitle"
      [ngClass]="{'checked': value, 'unchecked':value == false, 'disabled': disabled}">
      <span *ngIf="stateTitle"
        class="state-title">
        {{value ? 'ВКЛ' : 'ВЫКЛ'}}
      </span>
    </span>
    <span *ngIf="title"
      class="switch-title"
      [class.with-select-title]="withSelectTitle"
      [class.disabled]="disabled"
      [class.checked]="value">{{title}}</span>
  </label>
</div>