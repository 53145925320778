<div class="monthSelectClass" [class.for-calendar]="forCalendar">
  <div class="month-select"
       [class.disabled]="disabled">
    <div *ngIf="withIcon" class="month-select-icon">
      <span class="font-icons icon-calendar_date"></span>
    </div>
    <div class="month-select-value"
         [class.with-icon]="withIcon"
         (clickOutside)="openMonthSelect($event, !enableClickOutside)"
         [clickOutsideEnabled]="enableClickOutside">
      <div *ngIf="selectedMonth"
           class="month-select-selected"
           [class.opened]="openedSelect"
           (click)="openMonthSelect($event, !openedSelect)">
        <span>{{selectedMonth}}</span>
      </div>
      <ng-container [ngTemplateOutlet]="balloon"></ng-container>
    </div>
    <app-year-select class="month-select-year"
                     [minYear]="minYear"
                     [maxYear]="maxYear"
                     [withIcon]="false"
                     [disabled]="disabled"
                     [forCalendar]="forCalendar"
                     [selected]="selected && selected.year ? selected.year : null"
                     [openDirection]="openDirection"
                     (OnSelect)="onSelectYear($event)"
    ></app-year-select>
  </div>
</div>

<!--Шаблон выпадающего списка-->
<ng-template #balloon>
  <div class="month-select-balloon {{openDirection}}"
       [class.opened]="openedSelect">
    <div *ngIf="monthList && monthList.length > 0" class="month-select-list scroll-block">
      <div *ngFor="let item of monthList; let ind = index"
           class="month-select-list-item"
           [class.active]="selected && ind + 1 === selected.month"
           [class.disabled]="isNeedLimitMonth && selected && selected.year
           && ((selected.year === minYear && ind + 1 < minMonth)
           || (selected.year === maxYear && ind + 1 > maxMonth))"
      ><span (click)="onSelectMonth(ind + 1)">{{item}}</span></div>
    </div>
    <div *ngIf="!monthList || monthList.length === 0" class="month-select-list-empty"> </div>
  </div>
</ng-template>
