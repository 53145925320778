import {Component} from '@angular/core';

@Component({
  selector: 'app-search-address-full-form',
  templateUrl: './search-address-full-form.component.html',
  styleUrls: ['./search-address-full-form.component.scss']
})
export class SearchAddressFullFormComponent {

  constructor() { }

}
