import { catchError } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { UniversalGuideApiService } from "@core/services/api/universal-guide-api.service";
import {
  IUniversalGridRequest,
  IUniversalGuideInfo,
  IUniversalGuideUpdateRequest
} from "@shared/components/universal-guide/universal-guide.interface";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { AdminNsiDictionaryMode } from "@app/app.enums";
import { IExtendFilter, IPagedResponse } from "@shared/components/base/ag-base-grid/ag-base-grid.interface";
import { UniversalGuideUtilsService } from "@shared/components/universal-guide/universal-guide-utils.service";

@Injectable({
  providedIn: 'root'
})
export class UniversalGuideService {

  private guideInfo: BehaviorSubject<IUniversalGuideInfo> = new BehaviorSubject<IUniversalGuideInfo>(null);
  private guideExtendFilters: BehaviorSubject<IExtendFilter[]> = new BehaviorSubject([]);
  private readOnlyMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private errorRequest: string = ''; // текст ошибки выполнения запроса

  constructor(
    private universalGuideApiService: UniversalGuideApiService,
    private universalGuideUtilsService: UniversalGuideUtilsService,
    private translateService: TranslateService,
    private toastr: ToastrService,
  ) {
    this.translateService.get(['GENERAL'])
      .subscribe((result: string[]) => {
        this.errorRequest = result['GENERAL']['ERROR_REQUEST'];
      });
  }

  public getGuideInfo(code: string) {
    return !!code
      ? this.universalGuideApiService.getInfo(code)
        .pipe(
          tap(res => {
            this.guideInfo.next(res);
            this.guideExtendFilters.next(this.universalGuideUtilsService.getExtendFilters(res?.fields ?? null));
            this.readOnlyMode.next(res?.oper_mode === AdminNsiDictionaryMode.READ_ONLY);
          }),
          catchError(error => {
            this.guideInfo.next(null);
            this.guideExtendFilters.next([]);
            this.readOnlyMode.next(true);
            this.toastr.error(error?.error?.message ?? '',
              this.errorRequest);
            return throwError(error);
          })
        ).toPromise()
      : Promise.resolve(null);
  }

  public getGrid(request: IUniversalGridRequest): Observable<IPagedResponse> {
    return this.universalGuideApiService.getGrid(request);
  }

  public getGridRow(code: string, id: number): Observable<any> {
    return this.universalGuideApiService.getRow(code, id);
  }

  public updateGridRow(params: IUniversalGuideUpdateRequest, regime: string): Observable<any> {
    if (regime === 'add') {
      return this.universalGuideApiService.addRow(params);
    } else {
      return this.universalGuideApiService.updateRow(params);
    }
  }

  public deleteGridRow(code: string, rowId: number): Observable<any> {
    return this.universalGuideApiService.deleteRow(code, rowId);
  }

  public getGuideInformation(): Observable<IUniversalGuideInfo> {
    return this.guideInfo.asObservable();
  }

  public getReadOnlyMode(): Observable<boolean> {
    return this.readOnlyMode.asObservable();
  }

  public getExtendFilters(): Observable<IExtendFilter[]> {
    return this.guideExtendFilters.asObservable();
  }
}
