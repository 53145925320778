import {Component, EventEmitter, forwardRef, Input, OnChanges, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import * as dayjs from 'dayjs';
import {DateTimeService} from "@shared/services/date-time.service";
import {IRangeDate} from "@core/interfaces/calendar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-range-calendar',
  templateUrl: './range-calendar.component.html',
  styleUrls: ['./range-calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeCalendarComponent),
      multi: true
    }
  ]
})
export class RangeCalendarComponent implements OnChanges, ControlValueAccessor {

  private dateFormat = this.dateTimeService.getDateFormat('date');
  private timeFormat = this.dateTimeService.getDateFormat('time');
  public timeWithSecondFormat = this.dateTimeService.getDateFormat('timeWithSecond');
  private dateTimeFormat = this.dateTimeService.getDateFormat('dateTimeWithSecond');

  private currentYear = dayjs().year(); //текущий год
  private currentMonth = dayjs().month() + 1; //текущий год

  public withTime: boolean = false;
  public withSeconds: boolean = false;
  public startMinDateForCalendar: string = null;
  public startMaxDateForCalendar: string = null;
  public endMinDateForCalendar: string = null;
  public endMaxDateForCalendar: string = null;

  public openedCalendar: boolean = false;
  public enableClickOutside: boolean = false;

  public hasErrorStartInner: boolean = false;
  public errorStartInner: string = null;
  private hasErrorPeriod: boolean = false;
  private hasErrorMinDate: boolean = false;
  public hasErrorMaxDate: boolean = false;
  private errorText: string = null;
  private errorMinDate: string = null;
  public errorMaxDate: string = null;

  private errorPeriod: string = null;
  private errorMinDateText: string = null;
  private errorMaxDateText: string = null;

  @Input() selected: IRangeDate = {start: null, end: null};
  @Input() id: string = null;
  @Input() format: string = this.dateFormat;
  @Input() withoutClear: boolean = false;
  @Input() noManualInput: boolean = false;
  @Input() disabled: boolean = false;
  @Input() hasErrorStart: boolean = false;
  @Input() hasErrorEnd: boolean = false;
  @Input() errorStart: string = null;
  @Input() errorEnd: string = null;
  @Input() withMinutes: boolean = true;
  @Input() minDate: string = null; // минимальная дата в формате format для периода
  @Input() maxDate: string = null; // максимальная дата в формате format для периода
  @Input() startMinMonth: number = 1; // минимальный месяц в минимальном году для начала периода
  @Input() startMaxMonth: number = this.currentMonth; // максимальный месяц в максимальном году для начала периода
  @Input() startMinYear: number = this.currentYear - 20; // минимальный год для начала периода
  @Input() startMaxYear: number = this.currentYear; // максимальный год для начала периода
  @Input() endMinMonth: number = 1; // минимальный месяц в минимальном году для начала периода
  @Input() endMaxMonth: number = this.currentMonth; // максимальный месяц в максимальном году для начала периода
  @Input() endMinYear: number = this.currentYear - 20; // минимальный год для начала периода
  @Input() endMaxYear: number = this.currentYear; // максимальный год для начала периода
  @Input() isNeedLimit: boolean = true;
  @Input() openDirection: string = 'bottom left';
  @Output() OnSelect: EventEmitter<IRangeDate> = new EventEmitter<IRangeDate>(); // выбор дат

  public _onChange: any = () => {};
  public _onTouch: any = () => {};

  constructor(
    private dateTimeService: DateTimeService,
    private translateService: TranslateService
  ) {
    this.translateService.get('GENERAL').subscribe((result) => {
      this.errorPeriod = result['ERROR_PERIOD'];
      this.errorMinDateText = result['ERROR_PERIOD_START_MIN'];
      this.errorMaxDateText = result['ERROR_PERIOD_END_MAX'];
    });
  }

  ngOnChanges() {
    this.setMinMax();
    this.checkTimeMask();
    this.changeValue(this.selected);
  }

  private setMinMax() {
    if (this.isNeedLimit) {
      if (this.minDate) {
        this.startMinDateForCalendar = dayjs(this.minDate, this.format).format(this.dateFormat);
        this.startMinMonth = dayjs(this.minDate, this.format).month() + 1;
        this.startMinYear = dayjs(this.minDate, this.format).year();
        this.endMinDateForCalendar = dayjs(this.minDate, this.format).format(this.dateFormat);
        this.endMinMonth = dayjs(this.minDate, this.format).month() + 1;
        this.endMinYear = dayjs(this.minDate, this.format).year();
      }
      if (this.maxDate) {
        this.startMaxDateForCalendar = dayjs(this.maxDate, this.format).format(this.dateFormat);
        this.startMaxMonth = dayjs(this.maxDate, this.format).month() + 1;
        this.startMaxYear = dayjs(this.maxDate, this.format).year();
        this.endMaxDateForCalendar = dayjs(this.maxDate, this.format).format(this.dateFormat);
        this.endMaxMonth = dayjs(this.maxDate, this.format).month() + 1;
        this.endMaxYear = dayjs(this.maxDate, this.format).year();
      }
    }
  }

  private setMinMaxFromChange() {
    if (this.isNeedLimit) {
      const start = this.selected && this.selected.start ? dayjs(this.selected.start, this.format) : null;
      const end = this.selected && this.selected.end ? dayjs(this.selected.end, this.format) : null;
      if (start) {
        this.endMinDateForCalendar = start.format(this.dateFormat);
        this.endMinMonth = start.month() + 1;
        this.endMinYear = start.year();
      }
      if (end) {
        this.startMaxDateForCalendar = end.format(this.dateFormat);
        this.startMaxMonth = end.month() + 1;
        this.startMaxYear = end.year();
      }
    }
  }

  private checkTimeMask() {
    const timeM: string = this.format && this.format !== this.dateFormat ? this.format.split(' ')[1] : null;
    this.withTime = !!timeM && (timeM === this.timeWithSecondFormat || timeM === this.timeFormat);
    this.withSeconds = !!timeM && timeM === this.timeWithSecondFormat;
  }

  private changeValue(value: IRangeDate) {
    this.selected = value ? value : {start: null, end: null};
    this.setMinMaxFromChange();
    this.checkDate();
    this._onChange(this.selected);
  }

  public selectDateTime(value: string, type: string) {
    if (type === 'start') {
      this.selected.start = value ? dayjs(value, this.dateTimeFormat).format(this.format) : null;
    } else {
      this.selected.end = value ? dayjs(value, this.dateTimeFormat).format(this.format) : null;
    }
    this.writeValue(this.selected);
    this.OnSelect.emit(this.selected);
    event.stopPropagation();
  }

  public changeDate(value: string, type: string) {
    if (type === 'start') {
      this.selected.start = value;
    } else {
      this.selected.end = value;
    }
    this.writeValue(this.selected);
    this.OnSelect.emit(this.selected);
  }

  public openCalendar(event, open: boolean) {
    if (this.disabled === true) {
      return;
    }
    this.openedCalendar = open;
    this.enableClickOutside = open;
  }

  private checkDate() {
    if (this.selected && this.selected.start && this.minDate) {
      const start = dayjs(this.selected.start, this.format);
      const minMoment = dayjs(this.minDate, this.format);
      this.hasErrorMinDate = start.isBefore(minMoment);
      this.errorMinDate = start.isBefore(minMoment) ? `${this.errorMinDateText} ${this.minDate}` : null;
    } else {
      this.hasErrorMinDate = false;
      this.errorMinDate = null;
    }
    if (this.selected && this.selected.end && this.maxDate) {
      const end = dayjs(this.selected.end, this.format);
      const maxMoment = dayjs(this.maxDate, this.format);
      this.hasErrorMaxDate = end.isAfter(maxMoment);
      this.errorMaxDate = end.isAfter(maxMoment) ? `${this.errorMaxDateText} ${this.maxDate}` : null;
    } else {
      this.hasErrorMaxDate = false;
      this.errorMaxDate = null;
    }
    if (this.selected && this.selected.start && this.selected.end) {
      const start = dayjs(this.selected.start, this.format);
      const end = dayjs(this.selected.end, this.format);
      this.hasErrorPeriod = end.isSameOrBefore(start);
      this.errorText = end.isSameOrBefore(start) ? this.errorPeriod : null;
    } else {
      this.hasErrorPeriod = false;
      this.errorText = null;
    }
    this.hasErrorStartInner = this.hasErrorMinDate || this.hasErrorPeriod;
    this.errorStartInner = this.errorMinDate ? this.errorMinDate : this.errorText;
  }

  writeValue(value: IRangeDate): void {
    this.selected = value ? value : {start: null, end: null};
    this.changeValue(this.selected);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
