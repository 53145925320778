import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { tap, catchError } from 'rxjs/operators';

export interface ITokenResponse {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number; //в секундах
  scope: string;
}

const GRANT_TYPE_PASSWORD: string = "password";
const GRANT_TYPE_REFRESH_TOKEN: string = "refresh_token";

@Injectable({
  providedIn: "root"
})
export class OAuth2Service implements AuthService {

  private tokenResponse: ITokenResponse;

  constructor(
    private HttpClient: HttpClient,
    private localStorageService: LocalStorageService
  ) {
    this.tokenResponse = this.getTokenResponseFromCookie();
  }

  public logout() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.getToken()}`
    });
    return this.HttpClient.post(`${environment.API_URL}/public/revoke`, {}, { headers })
      .pipe(tap(() => this.clearCredentials()));
  }

  public refreshToken(authUrl: string): Observable<ITokenResponse> {
    const formData = new FormData();
    formData.append("grant_type", GRANT_TYPE_REFRESH_TOKEN);
    formData.append("refresh_token", this.tokenResponse.refresh_token);
    return this.HttpClient.post<ITokenResponse>(`${authUrl}/ws-auth/security/oauth/token`, formData)
      .pipe(
        tap(res => this.setCredentials(res)),
        catchError(error => {
          this.clearCredentials();
          return throwError(error);
        })
      );
  }

  public getAuthCodeUrl(url: string = null): string {
    const redirect_uri: string = `${document.location.origin}/ws-web-backend/public/auth`;
    const state: string = url && url !== '' ? btoa(url) : null;
    this.localStorageService.setObjectByName('state', state);
    this.saveRedirectUrl(url);
    return `${environment.API_URL}/public/authorize?response_type=code&state=${state}&redirect_uri=${redirect_uri}`;
  }

  public getTokenFromCode(code: string): Observable<ITokenResponse> {
    const redirect_uri: string = `${document.location.origin}/ws-web-backend/public/auth`;
    const formData = new FormData();
    formData.append("code", code);
    formData.append("redirect_uri", redirect_uri);
    return this.HttpClient.post<ITokenResponse>(`${environment.API_URL}/public/token`, formData)
      .pipe(
        tap(res => this.setCredentials(res)),
        catchError(error => {
          this.clearCredentials();
          return throwError(error);
        })
      );
  }

  public getToken(): string {
    return this.tokenResponse ? this.tokenResponse.access_token : null;
  }

  public setCredentials(tokenResponse: ITokenResponse): void {
    this.tokenResponse = tokenResponse;
    this.setTokenResponseToCookie(tokenResponse);
  }

  public clearCredentials(): void {
    this.tokenResponse = null;
    this.removeTokenResponseFromCookie();
  }

  public isAuthorized(): Boolean {
    return !!this.tokenResponse;
  }

  public getAppAuthorizationCode(): string {
    return btoa(`${environment.AUTH_USERNAME}:${environment.AUTH_PASSWORD}`);
  }

  private getTokenResponseFromCookie(): ITokenResponse {
    return JSON.parse(this.localStorageService.getObjectByName('auth'));
  }

  private setTokenResponseToCookie(tokenResponse: ITokenResponse): void {
    this.localStorageService.setObjectByName('auth', JSON.stringify(tokenResponse));
  }

  private removeTokenResponseFromCookie(): void {
    this.localStorageService.removeObjectByName('auth');
  }

  private saveRedirectUrl(url: string) {
    const redirectTo = url ? url.replace(document.location.origin, '') : null;
    this.localStorageService.setObjectByName('redirectTo',
      redirectTo && redirectTo !== '' && redirectTo !== '/' ? redirectTo : null);
  }
}
