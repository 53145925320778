<div class="RangeTimeSelectClass">
    <div class="range-time-select-inputs">
        <app-date-time-input class="range-time-select-input"
            [selected]="selected.start"
            [disabled]="disabled"
            id="start-{{id}}"
            [withoutClear]="withoutClear"
            [format]="timeFormat"
            (OnSelect)="enterTime($event, 'start')">
        </app-date-time-input>
        <span class="range-time-select-separator">—</span>
        <app-date-time-input class="range-time-select-input"
            [selected]="selected.end"
            [disabled]="disabled"
            id="end-{{id}}"
            [withoutClear]="withoutClear"
            [format]="timeFormat"
            (OnSelect)="enterTime($event, 'end')">
        </app-date-time-input>
    </div>
</div>