import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IAddressElementSearchResult, IAddressObjectSearchResult } from '@core/interfaces/address';
import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, ElementRef, OnDestroy, OnChanges } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss']
})
export class AddressSearchComponent implements AfterViewInit, OnDestroy, OnChanges {

  /**
   * Данные для отображения
   */
  @Input('displayItems') set _displayItems(value: IAddressObjectSearchResult[] | IAddressElementSearchResult[]) {
    this.displayItems = value ?? [];
    this.preselectedValue = null;
  }
  public displayItems: IAddressObjectSearchResult[] | IAddressElementSearchResult[] = [];
  /**
   * Время задержки поиска.
   * Если 0, автопоиск не работает
   */
  @Input() debounceTime: number = 1000;
  /**
   * Событие поиска, строка
   */
  @Output() OnSearch: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Событие выбора значения из списка
   */
  @Output() OnSelectValue: EventEmitter<IAddressElementSearchResult | IAddressObjectSearchResult> = new EventEmitter<IAddressElementSearchResult | IAddressObjectSearchResult>();

  public value: string; // поисковое значение
  public loading: boolean = false; // флаг загрузки

  public preselectedValue: IAddressElementSearchResult | IAddressObjectSearchResult = null;

  @ViewChild('AddressSearchField') addressSearchField: ElementRef;

  private onDestroy$ = new Subject<void>();

  constructor() { }

  ngAfterViewInit(): void {
    if (this.debounceTime > 0) {
      fromEvent(this.addressSearchField.nativeElement, 'input')
        .pipe(
          debounceTime(this.debounceTime),
          takeUntil(this.onDestroy$)
        )
        .subscribe(
          () => {
            this.search();
          }
        );
    }

    fromEvent(this.addressSearchField.nativeElement, 'keydown')
      .pipe(
        filter(e => e instanceof KeyboardEvent),
        takeUntil(this.onDestroy$)
      )
      .subscribe((e: KeyboardEvent) => {
        if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
          e.preventDefault();
          if (this.displayItems.length === 0) {
            return;
          }
          if (!this.preselectedValue) {
            this.preselectedValue = e.key === 'ArrowDown'
              ? this.displayItems[0]
              : this.displayItems[this.displayItems.length - 1];
            return;
          }
          let currentIndex = this.displayItems.findIndex(item => item === this.preselectedValue);
          this.preselectedValue = e.key === 'ArrowUp'
            ? currentIndex > 0
              ? this.displayItems[currentIndex - 1]
              : null
            : currentIndex < this.displayItems.length - 1
              ? this.displayItems[currentIndex + 1]
              : null;
        }
        if (e.key === 'Enter') {
          e.preventDefault();
          !!this.preselectedValue
            ? this.select(this.preselectedValue)
            : this.search();
        }
      })
  }

  ngOnChanges() {
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public search() {
    if (this.value && this.value != '') {
      this.loading = true;
      this.OnSearch.next(this.value);
    }
    else {
      this.displayItems = [];
    }
  }

  /**
   * Очистка данных
   */
  public clear() {
    this.value = '';
    this.displayItems = [];
  }

  /**
   * Выбор значения из списка
   * @param item значение
   */
  public select(item: IAddressElementSearchResult | IAddressObjectSearchResult) {
    this.OnSelectValue.next({ ...item });
  }
}
