<div class="addressSimpleDictionaryModalClass">
  <div class="simple-dictionary-modal-head">
    <div class="simple-dictionary-modal-title">
      <span class="text">{{title}}</span>
      <span *ngIf="withAdd" class="font-icons icon-plus" (click)="openAddModal()"></span>
    </div>
    <div class="simple-dictionary-modal-search">
      <app-search-field class="simple-dictionary-modal-search-input"
                        [placeholder]="''"
                        [value]="params && params.search ? params.search : null"
                        [searchId]="'simple-dictionary-search'"
                        [debounce]="1000"
                        (OnSearch)="search($event)"
      ></app-search-field>
      <span class="font-icons" [class.icon-sort_asc]="!params || params.sorting === 'asc'"
            [class.icon-sort_desc]="params && params.sorting === 'desc'"
            (click)="changeSorting()"
      ></span>
    </div>
  </div>
  <div class="simple-dictionary-modal-body">
    <app-loader [spinnerSize]="80" [show]="loadingInProgress"></app-loader>
    <div *ngIf="list && list.elements && list.elements.length > 0"
         class="simple-dictionary-modal-content scroll-block"
         #dictList
         (scroll)="onScrollList($event)">
      <div class="simple-dictionary-list">
        <div *ngFor="let item of list.elements"
             class="simple-dictionary-list-item"
             [class.selected]="selectedItem && selectedItem.key && item.key === selectedItem.key"
             (click)="onSelectItem(item)">
          <div class="simple-dictionary-list-item-content">
            <span class="simple-dictionary-list-item-title">{{item.value}}</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!list || !list.elements || list.elements.length === 0"
         class="simple-dictionary-modal-empty">
      {{'GENERAL.EMPTY_LIST' | translate}}
    </div>
  </div>
  <div class="simple-dictionary-modal-footer">
    <div class="simple-dictionary-modal-count">{{list && list.elements ? list.elements.length : 0}} из {{list?.totalElements}}</div>
    <div class="simple-dictionary-modal-btns">
      <app-button class="simple-dictionary-modal-btn"
                  [title]="'GENERAL.CANCEL' | translate"
                  (OnClick)="cancel()"
      ></app-button>
      <app-button class="simple-dictionary-modal-btn blue"
                  [title]="'GENERAL.APPLY' | translate"
                  [disabled]="!selectedItem || !activeApply"
                  (OnClick)="apply()"
      ></app-button>
    </div>
  </div>
</div>
