import {Component, Input, OnInit, Output} from '@angular/core';
import {
  IUniversalCardField,
  IUniversalGuideInfo,
  IUniversalGuideInfoField,
  IUniversalGuideUpdateRequest
} from "@shared/components/universal-guide/universal-guide.interface";
import {UniversalGuideService} from "@shared/components/universal-guide/universal-guide.service";
import {Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {OpenModalService} from "@shared/services/open-modal.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmModalComponent} from "@shared/components/confirm-modal/confirm-modal.component";
import {ColType, FilterType} from "@app/app.enums";
import {DateTimeService} from "@shared/services/date-time.service";
import {UniversalGuideUtilsService} from "@shared/components/universal-guide/universal-guide-utils.service";
import {IDictionaryField, IDictionaryParams} from "@core/interfaces/dictionary";
import {DictionaryService} from "@shared/services/dictionary.service";
import {NoWhitespaceValidator} from "@shared/validators/no-whitespace.validator";

@Component({
  selector: 'app-universal-card',
  templateUrl: './universal-card.component.html',
  styleUrls: ['./universal-card.component.scss']
})
export class UniversalCardComponent implements OnInit {

  @Input() cardInfo: any = null; // информация выбранной записи
  @Input() guideCode: string = null; // код справочника
  @Input() regime: string = 'view'; // режим - view, edit, add
  @Output() OnSave: Function; // функция после обновления
  @Output() OnClose: Function; // функция после закрытия

  private subscriptions: Subscription[] = []; // подписки
  private cardFields: IUniversalGuideInfoField[] = [];
  public fields: IUniversalCardField[] = []; // поля формы
  public title: string = ''; // заголовок формы
  public colType = ColType;
  public filterType = FilterType;
  private primaryKey: string = 'id'; // ключ записи
  public dateFormat: string = this.dateTimeService.getDateFormat('date'); // формат даты
  public form: FormGroup = new FormGroup({}); // форма
  public hasUnsavedFields: boolean = false; // флаг наличия несохраненных данных
  public isSubmitted: boolean = false; // флаг проверки формы перед сохранением
  public isLoading: boolean = false; // флаг загрузки
  private errorRequired: string = ''; // текст ошибки для обязательных полей
  private errorValue: string = ''; // текст ошибки при некорректном поле
  private errorWhitespace: string = ''; // текст ошибки при некорректном поле
  private confirmUnsaved: string = ''; // текст для подтверждения при несохраненных данных
  private confirmApplyUnsaved: string = ''; // текст кнопки Продолжить для подтверждения при несохраненных данных
  private confirmCancelUnsaved: string = ''; // текст кнопки Не сохранять для подтверждения при несохраненных данных
  private successSaveCard: string = ''; // текст успешного сохранения/редактирования
  private errorSaveCard: string = ''; // текст ошибки сохранения/редактирования
  private successDeleteCard: string = ''; // текст успешного удаления
  private errorDeleteCard: string = ''; // текст ошибки удаления
  private confirmDelete: string = ''; // текст для подтверждения удаления
  private confirmDeleteButton: string = ''; // текст кнопки для подтверждения удаления

  constructor(
    private universalGuideService: UniversalGuideService,
    private universalGuideUtilsService: UniversalGuideUtilsService,
    private dictionaryService: DictionaryService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private modalService: OpenModalService,
    public activeModal: NgbActiveModal,
    private dateTimeService: DateTimeService,
  ) {
    this.translateService.get(['GENERAL', 'ADMIN_NSI.ERRORS.ERROR_VALUE'])
      .subscribe((result: string[]) => {
        this.confirmUnsaved = result['GENERAL']['NO_SAVE_DATA'];
        this.confirmApplyUnsaved = result['GENERAL']['CONTINUE_EDIT'];
        this.confirmCancelUnsaved = result['GENERAL']['NO_SAVE'];
        this.errorRequired = result['GENERAL']['REQUIRED_FIELDS'];
        this.successSaveCard = result['GENERAL']['SUCCESS_SAVE'];
        this.errorSaveCard = result['GENERAL']['ERROR_SAVE'];
        this.successDeleteCard = result['GENERAL']['SUCCESS_DELETE'];
        this.errorDeleteCard = result['GENERAL']['ERROR_DELETE'];
        this.confirmDeleteButton = result['GENERAL']['DELETE'];
        this.confirmDelete = result['GENERAL']['DELETE_QUESTION'];
        this.errorValue = result['ADMIN_NSI.ERRORS.ERROR_VALUE'];
        this.errorWhitespace = result['GENERAL']['WHITESPACE_ERROR_FIELDS'];
      });
    this.subscriptions.push(
      this.universalGuideService.getGuideInformation()
        .subscribe((result: IUniversalGuideInfo) => {
          this.title = result.shortName;
          this.primaryKey = result.primaryKeyFieldName;
          this.cardFields = result ? result.fields : [];
          this.fields = this.prepareFields(this.cardFields);
          this.form = this.prepareForm(this.fields);
        })
    );
  }

  ngOnInit() {
    this.fields = this.prepareFields(this.prepareVisibleFields(this.cardFields));
    this.form = this.prepareForm(this.fields);
    if (this.cardInfo) {
      this.prepareFormValues();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private prepareVisibleFields(fields: IUniversalGuideInfoField[] = []): IUniversalGuideInfoField[] {
    let result = fields;
    if (fields && fields.length > 0 && this.regime === 'add') {
      result = fields.filter((field: IUniversalGuideInfoField) => !field.colReadOnly);
    }
    return result;
  }

  /**
   * получение списка полей
   * @param fields
   */
  private prepareFields(fields: IUniversalGuideInfoField[] = []): IUniversalCardField[] {
    const result: IUniversalCardField[] = [];
    if (fields && fields.length > 0) {
      fields.forEach((field: IUniversalGuideInfoField) => {
        if (field.colVisible || (!field.colVisible && field.refDictCode && field.refFields && field.refFields.length > 0)) {
          const refDictMode = field.refDictCode ? this.universalGuideUtilsService.getDictionaryType(field.refDictCode) : null;
          const refList = refDictMode && refDictMode === FilterType.select
            ? this.universalGuideUtilsService.getSelectList(field.refDictCode)
            : null;
          const refDictionary = refDictMode && refDictMode === FilterType.simpleDictionary
            ? this.universalGuideUtilsService.getDictionary(field.refDictCode)
            : null;
          const pattern = (field.colType === ColType.VARCHAR || field.colType === ColType.BIGINT
            || field.colType === ColType.INTEGER || field.colType === ColType.NUMBER) && !refDictMode && field.colFormat
            ? new RegExp(field.colFormat)
            : null;
          result.push(
            {
              code: field.colAliasDb,
              name: field.refFields && field.refFields.length > 0 ? field.refFields[0].colNameUi : field.colNameUi,
              required: field.colMandatory,
              readonly: field.colReadOnly,
              format: field.colFormat,
              pattern: pattern,
              type: field.colType,
              refDictCode: field.refDictCode,
              refDictMode: refDictMode,
              refList: refList,
              refDictionary: refDictionary,
              refFieldCode: field.refFields && field.refFields.length > 0 ? field.refFields[0].colAliasDb : null
            }
          );
        }
      });
    }
    return result;
  }

  /**
   * подготовка полей формы
   * @param fields
   */
  private prepareForm(fields: IUniversalCardField[] = []): FormGroup {
    const form = new FormGroup({});
    fields.forEach((field: IUniversalCardField) => {
      const validators = [];
      if (field.required) {
        validators.push(Validators.required);
      }
      if (field.pattern) {
        validators.push(Validators.pattern(field.pattern));
      }
      if ((field.type === ColType.VARCHAR || field.type === ColType.BIGINT
        || field.type === ColType.INTEGER || field.type === ColType.NUMBER) && !field.refDictCode) {
        validators.push(NoWhitespaceValidator());
      }
      const formControl = new FormControl({value: null, disabled: field.readonly}, validators);
      form.addControl(field.code, formControl);
    });
    return form;
  }

  /**
   * подготовка значений полей формы
   */
  private prepareFormValues(){
    this.fields.forEach((field: IUniversalCardField) => {
      let value: any = null;
      if (!field.refDictCode && field.type !== ColType.TIMESTAMP){
        value = this.cardInfo[field.code];
      } else if (!field.refDictCode && field.type === ColType.TIMESTAMP) {
        value = this.dateTimeService.getDateFromBackend(this.cardInfo[field.code], this.dateFormat);
      } else if (field.refDictCode) {
        value = {key: this.cardInfo[field.code], value: this.cardInfo[field.refFieldCode]};
      }
      this.form.controls[field.code].setValue(value);
    });
  }

  /**
   * изменение полей формы
   * @param value
   * @param field
   */
  public changeField(value: any, field: string) {
    this.checkUnsavedFields();
  }

  /**
   * подгрузить список значений справочника
   * @param params
   * @param dictionary
   */
  public updateDictionaryList(params: IDictionaryParams, dictionary: IDictionaryField) {
    this.dictionaryService.updateList(dictionary, params);
  }

  /**
   * проверка наличия несохраненных данных
   */
  private checkUnsavedFields() {
    const currentForm = this.getValues();
    if (this.regime === 'add') {
      let unsavedFields: number = 0;
      this.fields.forEach((field: IUniversalCardField) => {
        if (!!currentForm[field.code]) {
          unsavedFields++;
        }
      });
      this.hasUnsavedFields = unsavedFields > 0;
    } else if (this.regime === 'edit') {
      let unsavedFields: number = 0;
      this.fields.forEach((field: IUniversalCardField) => {
        if (!!this.cardInfo && this.cardInfo[field.code] !== currentForm[field.code]) {
          unsavedFields++;
        }
      });
      this.hasUnsavedFields = unsavedFields > 0;
    }
  }

  /**
   * кнопка Отмена с подтверждением при наличии несохраненных данных
   */
  public close() {
    if (this.hasUnsavedFields) {
      this.modalService.show(
        ConfirmModalComponent,
        {
          question: this.confirmUnsaved,
          applyTitle: this.confirmApplyUnsaved,
          cancelTitle: this.confirmCancelUnsaved,
          onApply: () => {},
          onCancel: () => {
            this.isSubmitted = false;
            this.hasUnsavedFields = false;
            if (this.OnClose) {
              this.OnClose();
            }
            this.activeModal.close();
          }
        },
        'onCancel',
        {
          centered: true,
          windowClass: 'modal-confirm',
        });
    } else {
      this.hasUnsavedFields = false;
      this.isSubmitted = false;
      if (this.OnClose) {
        this.OnClose();
      }
      this.activeModal.close();
    }
  }

  /**
   * кнопка Сохранить
   */
  public saveCard() {
    this.isSubmitted = true;
    if (this.form.invalid) {
      this.checkFields();
    } else {
      let values = this.getValues();
      if (this.regime === 'edit') {
        values = {...values, ...{[this.primaryKey]: this.cardInfo[this.primaryKey]}};
      }
      const params: IUniversalGuideUpdateRequest = {code: this.guideCode, values: values};
      this.isLoading = true;
      this.universalGuideService.updateGridRow(params, this.regime)
        .subscribe((result) => {
          this.isLoading = false;
          this.isSubmitted = false;
          this.hasUnsavedFields = false;
          if (this.OnSave) {
            this.OnSave();
          }
          this.activeModal.close();
          this.toastr.success(this.successSaveCard);
          }, (error) => {
          this.isLoading = false;
          this.isSubmitted = false;
          this.toastr.error(this.errorSaveCard);
        });
    }
  }

  /**
   * кнопка Удалить
   */
  public deleteCard() {
    this.modalService.show(
      ConfirmModalComponent,
      {
        question: this.confirmDelete,
        applyTitle: this.confirmDeleteButton,
        onApply: () => {
          this.deleteRow();
        }
      },
      null,
      {
        centered: true,
        windowClass: 'modal-confirm',
      });
  }

  /**
   * функция Удаления
   */
  private deleteRow() {
    this.isLoading = true;
    this.universalGuideService.deleteGridRow(this.guideCode, this.cardInfo[this.primaryKey])
      .subscribe((result) => {
        this.isLoading = false;
        if (this.OnSave) {
          this.OnSave();
        }
        this.activeModal.close();
        this.toastr.success(this.successDeleteCard);
      }, error => {
        this.isLoading = false;
        if (error.error && error.error['message']) {
          const errorText = error.error['message'];
          this.toastr.error(errorText, this.errorDeleteCard);
        } else {
          this.toastr.error(this.errorDeleteCard);
        }
    });
  }

  /**
   * получение параметров для сохранения/редактировния
   */
  private getValues() {
    let values = {};
    this.fields.forEach((field: IUniversalCardField) => {
      let value = this.form.get(field.code).value;
      if (field.type === ColType.TIMESTAMP) {
        value = value && value !== ''
          ? this.dateTimeService.getBackendDateFromString(value, this.dateFormat)
          : null;
      } else if (field.refDictMode === 'dictionary') {
        value = value && value.key ? value.key : null;
      } else {
        value = value && value !== '' ? value : null;
      }
      values[field.code] = value;
    });
    return values;
  }

  /**
   * проверка обязательных полей для вывода ошибок
   */
  private checkFields() {
    let invalidFields: number = 0;
    let invalidValues: number = 0;
    let invalidWhitespaces: number = 0;
    this.fields.forEach((field: IUniversalCardField) => {
      if (this.form.get(field.code).errors && this.form.get(field.code).errors['required']) {
        invalidFields++;
      }
      if (this.form.get(field.code).errors && this.form.get(field.code).errors['whitespace']) {
        invalidWhitespaces++;
      }
      if (this.form.get(field.code).errors && this.form.get(field.code).errors['pattern']) {
        invalidValues++;
      }
    });
    if (invalidFields > 0) {
      this.toastr.error(this.errorRequired);
    } else if (invalidWhitespaces > 0) {
      this.toastr.error(this.errorWhitespace);
    } else if (invalidValues > 0) {
      this.toastr.error(this.errorValue);
    }
  }
}
