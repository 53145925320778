import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {share} from "rxjs/operators";
import {ICreateDocument} from "@core/interfaces/measure-object";
import {FileService} from "@core/services/file.service";

@Injectable({
  providedIn: 'root'
})
export class GuideApiService {

  constructor(
    private HttpClient: HttpClient,
    private fileService: FileService
  ) {
  }

  /**
   * Добавить файл руководства пользователя
   * @param file файл
   */
  public addGuideFile(file: File): Observable<number> {
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);
    return this.HttpClient
      .post<number>(`${environment.API_URL}/admin/user/manual/upload`,
        formData)
      .pipe(share());
  }

  /**
   * Скачать руководство пользователя
   */
  public getGuideFile() {
    this.fileService.downloadFile(
      `${environment.API_URL}/admin/user/manual/download`,
      null, {}, true);
  }

  /**
   * Загрузить руководство пользователя
   */
  public addGuide(fileId: number): Observable<number> {
    return this.HttpClient
      .post<number>(`${environment.API_URL}/admin/user/manual`, {fileId: fileId})
      .pipe(share());
  }
}
