<div class="checkboxListClass"
     [class.as-line-row]="asLineRow"
     [class.as-too-columns]="asTooColumns">
  <app-checkbox *ngFor="let item of checkboxList"
                class="checkbox-list-item"
                [class.for-dictionary]="forDictionary"
                [class.for-multi-select]="forMultiSelect"
                [ngModel]="item.checked"
                [name]="item.key"
                [title]="item.value"
                [icon]="item.iconClass"
                [disabled]="item.disabled || disabled"
                [withSelectTitle]="withSelectTitle"
                (OnChange)="onSelectItem($event, item)">
  </app-checkbox>
</div>
