<div class="checkboxClass">
  <label class="checkbox-block" [class.disabled]="disabled">
    <input type="checkbox"
           [(ngModel)]="value"
           [attr.id]="name"
           [attr.name]="name"
           [required]="required"
           [disabled]="disabled || readonly"
           (change)="change($event)"/>
    <span class="font-icons checkmark"
          [ngClass]="{
          'icon-check': (value && !withThreeValue) || (valueFull && withThreeValue),
          'icon-check_not_full': value && !valueFull && withThreeValue,
          'disabled': disabled,
          'readonly': readonly
          }">
      <span class="path1"></span>
      <span class="path2"></span>
    </span>
    <span *ngIf="title"
          class="checkbox-title"
          [class.with-select-title]="withSelectTitle"
          [class.disabled]="disabled"
          [class.readonly]="readonly"
          [class.checked]="value"
    >
      <span *ngIf="icon" class="checkbox-title-icon font-icons {{icon}}"></span>
      <span class="checkbox-title-text">{{title}}</span>
    </span>
  </label>
</div>
