import {Injectable} from "@angular/core";
import {IDictionaryBody, IDictionaryField, IDictionaryParams} from "@core/interfaces/dictionary";
import * as cloneDeep from 'lodash.clonedeep';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {

  constructor() {}

  private defaultList: IDictionaryBody = {
    elements: [],
    totalElements: 0,
    pageSize: 20,
    pageNumber: 1,
    totalPages: 0
  };

  private defaultParams: IDictionaryParams = {
    page: {
      pageSize: 20,
      pageNumber: 1
    },
    search: '',
    sorting: 'asc'
  };

  public getDefaultList(): IDictionaryBody {
    return cloneDeep(this.defaultList);
  }

  public getDefaultParams(): IDictionaryParams {
    return cloneDeep(this.defaultParams);
  }

  public updateList(dictionary: IDictionaryField, params: IDictionaryParams) {
    window.setTimeout(() => {
      dictionary.dictLoading = true;
      dictionary.dictParams = params;
      dictionary.dictListRequest$(dictionary.dictParams).subscribe(
        (data: IDictionaryBody) => {
          if (dictionary.dictParams.page && dictionary.dictParams.page.pageNumber === 1) {
            dictionary.dictList = data;
          } else {
            dictionary.dictList.elements = [...dictionary.dictList.elements, ...data.elements];
            dictionary.dictList.pageNumber = data.pageNumber;
          }
          dictionary.dictLoading = false;
        },
        error => {
          if (dictionary.dictParams.search !== '') {
            dictionary.dictList = cloneDeep(this.defaultList);
          }
          dictionary.dictLoading = false;
        }
      );
    });
  }
}
