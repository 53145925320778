<div class="breadcrumsClass" *ngIf="breadcrums && breadcrums.length > 0">
  <div *ngFor="let breadcrum of breadcrums; let ind = index" class="breadcrum-item">
    <span class="breadcrum-item-arrow font-icons icon-forward-long"></span>
    <div *ngIf="ind < breadcrums.length - 1"
         class="breadcrum-item-title"
         [routerLink]="breadcrum.withComponent && breadcrum.url ? breadcrum.url : null"
         [class.no-router-link]="!breadcrum.withComponent"
    >{{breadcrum.label}}</div>
    <div *ngIf="ind === breadcrums.length - 1 && list.length === 0"
         class="breadcrum-item-title"
         [routerLink]="breadcrum.url"
    >{{breadcrum.label}}</div>
    <app-simple-select *ngIf="ind === breadcrums.length - 1 && list.length > 0"
                       class="breadcrum-list"
                       [selected]="breadcrum.url"
                       [list]="list"
                       [withStr]="true"
                       (OnSelect)="changePage($event)"
    ></app-simple-select>
  </div>
</div>
