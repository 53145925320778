import { SystemParamsService } from '@core/services/system-params.service';
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from "@angular/router";
import {Location} from "@angular/common";
import {AuthService} from "@core/services/auth/auth.service";
import {LocalStorageService} from "@core/services/local-storage.service";
import {IUserInfo} from "@core/interfaces/user";
import {ToastrService} from "ngx-toastr";
import {VisibilityService} from "@core/services/visibility.service";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "@core/services/user.service";
import {UserApiService} from "@core/services/api/user-api.service";
import {MenuService} from "@shared/services/menu.service";
import {SYSTEM_PARAMETER_NAME} from "@app/app.enums";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable()
export class AuthorizationGuard implements CanActivateChild {

  private errors: string[];

  constructor(
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private localStorage: LocalStorageService,
    private toastr: ToastrService,
    private visibilityService: VisibilityService,
    private translateService: TranslateService,
    private userService: UserService,
    private userApiService: UserApiService,
    private menuService: MenuService,
    private systemParamsService: SystemParamsService
  ) {
    this.translateService.get('LOGIN.ERRORS').subscribe((result: string[]) => {
      this.errors = result;
    });
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const code: string = route.queryParamMap.get('code');
    const stateFromRedirect: string = route.queryParamMap.get('state');
    const stateString: string = this.localStorage.getObjectByName('state');
    if (code && stateFromRedirect && stateString && stateString === stateFromRedirect) {
      this.authService.getTokenFromCode(code).subscribe(
        (res) => {
          this.systemParamsService.updateSystemParams();
          this.menuService.setUserMenu(null);
          this.visibilityService.getAccessObjects();
          this.userApiService.getUserInfo().subscribe(
            (userInfo: IUserInfo) => {
              this.userService.userInfo = userInfo;
              this.navigateToRedirect();
              return false;
            }, (error) => {
              this.toastr.error(this.errors['ERROR_USER']);
              this.navigateToRedirect();
              return false;
            });
        }, (error) => {
          if (error instanceof HttpErrorResponse && error.status === 400) {
            this.navigateToNoAccess(state.url);
          } else {
            this.navigateToLogin();
          }
          return false;
        });
    } else {
      this.navigateToLogin();
      return false;
    }
  }

  private navigateToNoAccess(url: string): void {
    this.router.navigate(['/no-access']).then(() => {
      this.location.replaceState(url);
    });
  }

  private navigateToLogin(): void {
    const authUrl: string = this.localStorage.getObjectByName(SYSTEM_PARAMETER_NAME.AUTH_URL);
    if (authUrl) {
      document.location.href = `${authUrl}/auth`;
    }
  }

  private navigateToRedirect() {
    const redirectTo = this.localStorage.getObjectByName('redirectTo');
    this.localStorage.setObjectByName('redirectTo', null);
    this.router.navigate([redirectTo ? redirectTo : '/main']);
  }
}
