import {Component, EventEmitter, forwardRef, Input, OnChanges, Output} from '@angular/core';
import * as dayjs from 'dayjs';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-year-select',
  templateUrl: './year-select.component.html',
  styleUrls: ['./year-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => YearSelectComponent),
      multi: true
    }
  ]
})
export class YearSelectComponent implements OnChanges, ControlValueAccessor {

  private currentYear = dayjs().year(); //текущий год

  @Input() selected: number = null; // выбранное значение
  @Input() minYear: number = this.currentYear - 4; // минимальный год
  @Input() maxYear: number = this.currentYear; // максимальный год
  @Input() withRemove: boolean = false; // с возможностью очистки
  @Input() withIcon: boolean = true; // с иконкой календаря
  @Input() disabled: boolean = false; // блокировка
  @Input() placeholder: string = null; // плейсхолдер
  @Input() forCalendar: boolean = false; // для календаря
  @Input() openDirection: string = 'bottom'; // направление открытия
  @Output() OnSelect: EventEmitter<number> = new EventEmitter<number>(); // выбор из списка

  public list: number[] = []; // список значений
  public openedSelect: boolean = false; // флаг открытия выпадающего списка
  public enableClickOutside: boolean = false; // Флаг управления работы outsideClickEvent

  public _onChange: any = () => {};
  public _onTouch: any = () => {};

  constructor() { }

  ngOnChanges() {
    this.changeValue(this.selected);
  }

  private changeValue(year: number) {
    this._onChange(year);
  }

  // функция открытия выпадающего списка
  public openSelect(event, open: boolean): void {
    if (this.disabled === true) {
      return;
    }
    if (open === true && this.minYear && this.maxYear) {
      this.list = [];
      for (let year = this.minYear; year <= this.maxYear; year++) {
        this.list.push(year);
      }
    }
    this.openedSelect = open;
    this.enableClickOutside = open;
  }

  public removeSelection(): void {
    this.writeValue(null);
    this.OnSelect.emit(null);
  }

  // функция выбора значения
  public onSelectYear(year: number): void {
    this._onChange(year);
    this.OnSelect.emit(year);
    this.openedSelect = false;
    this.enableClickOutside = false;
    this.writeValue(year);
  }

  writeValue(year: number): void {
    this.selected = year;
    this.changeValue(this.selected);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
