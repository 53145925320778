import {Component, EventEmitter, forwardRef, Input, OnChanges, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {IMultiSelectItem} from "@core/interfaces/select-item";

@Component({
  selector: 'app-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxListComponent),
      multi: true
    }
  ]
})
export class CheckboxListComponent implements OnChanges, ControlValueAccessor {

  @Input() selected: Array<string | number> = []; // массив выбранных ключей
  @Input() checkListName: string; // имя
  @Input() asLineRow: boolean = false; // отобразить в виде строки
  @Input() asTooColumns: boolean = false; // отобразить в виде 2х колонок
  @Input() required: boolean; // обязательность выбора
  @Input() disabled: boolean; // блокировка
  @Input() checkboxList: IMultiSelectItem[] = []; // список значений
  @Input() forDictionary: boolean = false; // для отображения в справочниках
  @Input() forMultiSelect: boolean = false; // для отображения в мульти-выборе
  @Input() withSelectTitle: boolean = false; // с выделение заголовка у выбранного чекбокса (для списков)
  @Output() OnChange: EventEmitter<Array<string | number>> = new EventEmitter<Array<string | number>>(); // событие при смене состояния

  public _onChange: any = () => {};
  public _onTouch: any = () => {};

  constructor() { }

  ngOnChanges() {
    this.changeValue(this.selected);
    this.prepareList(this.selected);
  }

  /**
   * подготовка списка
   * @param selectedItems
   */
  private prepareList(selectedItems: Array<string | number>) {
    if (this.checkboxList && this.checkboxList.length > 0) {
      this.checkboxList = this.checkboxList.map((listItem: IMultiSelectItem) => {
        if (selectedItems && selectedItems.length > 0) {
            const selectItem = selectedItems.find((item: string | number) => item.toString() === listItem.key.toString());
            listItem.checked = !!selectItem;
        } else {
          listItem.checked = false;
        }
        return listItem;
      });
    }
  }

  private changeValue(items: Array<string | number>) {
    this.selected = items;
    this._onChange(this.selected);
  }

  writeValue(items: Array<string | number>) {
    this.changeValue(items);
    this.prepareList(items);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  /**
   * выбор/снятие элемента
   * @param checked
   * @param item
   */
  public onSelectItem(checked: boolean, item: IMultiSelectItem): void {
    if (!this.disabled && !item.disabled) {
      let selectedKeys: Array<string | number> = this.selected ? this.selected : [];
      if (checked === true) {
        selectedKeys.push(item.key);
      } else {
        selectedKeys = selectedKeys.filter((key: string | number) => key.toString() !== item.key.toString());
      }
      if (selectedKeys.length > 0) {
        selectedKeys.sort((a, b) => a.toString().localeCompare(b.toString(), 'string', { numeric: true }));
      }
      this.writeValue(selectedKeys);
      this.OnChange.emit(selectedKeys);
    }
  }
}
