<div *ngIf="item"
     class="AccordionItemClass">
    <div class="accordion-item-title"
         (click)="toggle()">
        <div class="accordion-item-title-text">{{item.title}}</div>
        <div class="accordion-item-title-icon">
            <span class="font-icons icon-str_down"
                  [class.str_up]="item.open"></span>
        </div>
    </div>
    <div class="accordion-item-content"
         [class.open]="item.open">
        <ng-content></ng-content>
    </div>
</div>