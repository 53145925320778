import {Component, EventEmitter, forwardRef, Input, OnChanges, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {IMultiSelectItem} from "@core/interfaces/select-item";

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true
    }
  ]
})
export class MultiSelectComponent implements OnChanges {

  @Input() selected: Array<string | number> = null; // выбранное значение
  @Input() list: IMultiSelectItem[] = []; // список значений
  @Input() title: string = null; // тайтл при отсутствии выбранного значения (placeholder)
  @Input() forFilter: boolean = false; // флаг типа селекта - для панели фильтров или для полей
  @Input() disabled: boolean = false; // блокировка
  @Input() countShowValuesForFilter: number = 1; // кол-во отображаемых выбранных значений для панели фильтров
  @Input() openDirection: string = 'bottom center'; // направление открытия
  @Output() OnSelect: EventEmitter<IMultiSelectItem[]> = new EventEmitter<IMultiSelectItem[]>();

  public selectedItems: IMultiSelectItem[] = null; // выбранные значения
  public selectedItemsValue: string[] = []; // выбранные значения - value
  public checkedKeys: Array<string | number> = null; // выбранные значения key в списке
  public openedSelect: boolean = false; // флаг открытия выпадающего списка
  public enableClickOutside: boolean = false; // Флаг управления работы outsideClickEvent

  public _onChange: any = () => {};
  public _onTouch: any = () => {};

  constructor() { }

  ngOnChanges() {
    this.changeValue(this.selected);
  }

  private changeValue(keys: Array<string | number>) {
    if(this.list && this.list.length > 0) {
      if (!!keys && keys.length > 0) {
        this.selectedItems = [];
        keys.forEach((key: string | number) => {
          const listItem = this.list.find((item: IMultiSelectItem) => item.key.toString() === key.toString());
          if (listItem) {
            this.selectedItems.push(listItem);
          }
        });
      } else {
        this.selectedItems = null;
      }
      this.selectedItemsValue = this.getSelectedValues();
      this.checkedKeys = this.getSelectedKeys();
      this._onChange(this.checkedKeys);
    }
  }

  /**
   * функция открытия выпадающего списка
   */
  public openSelect(event, open: boolean): void {
    if (this.disabled && (!this.selectedItems || this.selectedItems.length === 0)) {
      return;
    }
    this.openedSelect = open;
    this.enableClickOutside = open;
  }

  /**
   * очистить все выбранные значения
   */
  public removeSelection(): void {
    this.writeValue(null);
    this.OnSelect.emit(null);
  }

  /**
   * удалить конкретное выбранное значение
   * @param item
   */
  public removeSelectItem(item: IMultiSelectItem): void {
    this.selectedItems = this.selectedItems.filter((selectItem:IMultiSelectItem) => selectItem.key.toString() !== item.key.toString());
    this.selectedItemsValue = this.getSelectedValues();
    this.checkedKeys = this.getSelectedKeys();
    this.writeValue(this.checkedKeys);
    this.OnSelect.emit(this.selectedItems);
  }

  /**
   * получить список выбранных keys
   */
  private getSelectedKeys() {
    return this.selectedItems && this.selectedItems.length > 0
      ? this.selectedItems.map((item: IMultiSelectItem) => {return item.key})
      : null;
  }

  /**
   * получить список выбранных values
   */
  private getSelectedValues() {
    return this.selectedItems && this.selectedItems.length > 0
      ? this.selectedItems.map((item: IMultiSelectItem) => {return item.value})
      : [];
  }

  /**
   * применить выбор
   */
  public onApply(): void {
    this._onChange(this.checkedKeys);
    this.openedSelect = false;
    this.enableClickOutside = false;
    this.writeValue(this.checkedKeys);
    this.OnSelect.emit(this.selectedItems);
  }

  public changeCheckedKeys(keys: Array<string | number>) {
    this.checkedKeys = keys;
  }


  writeValue(values: any): void {
    const selectedKeys = [];
    this.selectedItems = [];
    if (values && values.length > 0) {
      values.forEach((value: any) => {
        if (typeof value === 'object') {
          this.selectedItems.push(value);
          selectedKeys.push(value.key);
        } else {
          if (this.list && this.list.length > 0) {
            const item = this.list.find((listItem: IMultiSelectItem) => listItem.key.toString() === value.toString());
            this.selectedItems.push(item);
          } else {
            this.selectedItems.push({key: value, value: ''});
          }
          selectedKeys.push(value);
        }
      });
    } else {
      this.selectedItems = null;
    }
    this.selectedItemsValue = this.getSelectedValues();
    this.selected = selectedKeys && selectedKeys.length > 0 ? selectedKeys : null;
    this.changeValue(this.selected);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
