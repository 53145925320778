import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnInit {
  @Input() title: string = ''; // текст кнопки
  @Input() disabled: boolean = false; // флаг блокировки кнопки
  @Input() icon: string; // класс иконки для кнопки
  @Input() iconSize: number; // размер иконки для кнопки
  @Input() iconColor: string; // цвет иконки для кнопки
  @Input() iconSlot: 'start' | 'end' = 'start';
  @Output() OnClick: EventEmitter<any> = new EventEmitter(); // событие клика

  constructor() { }

  ngOnInit() {
  }

  onClickBtn($event = null): void {
    if (this.disabled && $event) {
      $event.stopPropagation();
    } else {
      this.OnClick.emit($event);
    }
  }

}
