<div class="radioboxListClass" [class.as-line-row]="asLineRow">
  <div *ngFor="let item of radioList"
       class="radiobox-item"
       [class.checked]="selected && selected.toString() === item.key.toString()"
       [class.disabled]="item.disabled || disabled">
    <label class="radiobox-block" [class.disabled]="disabled">
      <input type="radio"
             [(ngModel)]="selected"
             id="{{radioListName}}_{{item.key}}"
             [attr.name]="radioListName"
             [value]="item.key"
             [required]="required"
             [disabled]="item.disabled || disabled"
             (change)="changeRadio(item)"/>
      <span class="font-icons checkmark"
            [ngClass]="{'icon-radio_check': selected && selected.toString() === item.key.toString(),
            'icon-radio_nocheck': selected && selected.toString() !== item.key.toString() || !selected,
            'disabled': item.disabled || disabled}">
    </span>
      <span *ngIf="item.value"
            class="radiobox-title"
            [class.with-select-title]="withSelectTitle"
            [class.disabled]="item.disabled || disabled"
            [class.checked]="selected && selected.toString() === item.key.toString()"
      >{{item.value}}</span>
    </label>
  </div>
</div>

