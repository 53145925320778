import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MenuService } from "@shared/services/menu.service";
import { IUserMenu } from "@core/interfaces/user";
import { Observable, Subscription } from "rxjs";
import { debounceTime, delay } from "rxjs/operators";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit, OnDestroy {

  public menu: IUserMenu[] = [];
  public isOpenMenu: Observable<boolean> = this.menuService.getOpenedMenu().pipe(delay(0));
  public isMenuCollapseOpen: boolean = false;
  private menuSubscription: Subscription;

  constructor(
    private menuService: MenuService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.menuSubscription = this.menuService.getUserMenu()
      .pipe(debounceTime(100))
      .subscribe((result: IUserMenu[]) => {
        this.menu = result;
        this.checkCollapseMenu();
        this.changeDetectorRef.detectChanges();
      })
  }

  ngOnDestroy() {
    this.menuSubscription.unsubscribe();
  }

  public collapseMenu() {
    this.isMenuCollapseOpen = !this.isMenuCollapseOpen;
    this.menuService.collapseMenu(this.isMenuCollapseOpen);
  }

  public changeItemOpen() {
    this.checkCollapseMenu();
    this.changeDetectorRef.detectChanges();
  }

  public closeMenu() {
    this.menuService.setOpenedMenu(false);
  }

  private checkCollapseMenu() {
    if (this.menu && this.menu.length > 0) {
      const openedFirstLevel = this.menu.filter((item: IUserMenu) => item.isOpened);
      this.isMenuCollapseOpen = openedFirstLevel && openedFirstLevel.length > 0;
    } else {
      this.isMenuCollapseOpen = false;
    }
  }
}
