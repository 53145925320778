<div class="AddressTreeClass">
    <div class="address-tree-header">
        {{ 'ADDRESS_CARD.TREE_TITLE' | translate}}
    </div>
    <div class="address-tree-body scroll-block">
        <div class="address-element-in-tree" *ngFor="let item of tree; let i = index">
            <div class="element-body" [class.oe-selected]="i == selectedIndex">
                <div class="element-text" (click)="selectElement(i)">
                    <div class="oe-header">
                        {{firstLatterToUpperCase(item.objectTypeFullName)}}
                    </div>
                    <div class="oe-name">
                        {{item.name}}
                    </div>
                </div>
                <div class="element-delete">
                    <span *ngIf="canRemoveElementFromTree(item)"
                          class="font-icons icon-clearer"
                          (click)="removeElement(i)">
                    </span>
                </div>
            </div>
            <div class="insert-element-container" *ngIf="canInsertElement(i); else borderBlock">
                <div class="insert-element-line"></div>
                <div class="insert-element-plus-container" (click)="insertElement(i)">
                    <span class="font-icons icon-plus"></span>
                </div>
            </div>
            <ng-template #borderBlock>
                <div class="element-border-container">
                    <div class="element-border"></div>
                </div>
            </ng-template>
        </div>

        <div class="address-element-in-tree" *ngIf="object">
            <div class="element-body" [class.oe-selected]="selectedIndex == 'object'">
                <div class="element-text" (click)="selectObject()">
                    <div class="oe-header">
                        {{firstLatterToUpperCase(object.objectTypeFullName)}}
                    </div>
                    <div class="oe-name">
                        {{object.name}}
                    </div>
                </div>
                <div class="element-delete">
                    <span *ngIf="regime === 'add'"
                          class="font-icons icon-clearer"
                          (click)="removeObject()">
                    </span>
                </div>
            </div>
        </div>

        <div class="address-tree-add-oe" *ngIf="canAddElementOrObject()">
            <div class="oe-header">
                {{'GENERAL.ADD' | translate}}
            </div>
            <app-button class="add-oe-button black element"
                        [title]="'ADDRESS_CARD.ADD_ELEMENT' | translate"
                        (click)="addElement()">
            </app-button>
            <app-button *ngIf="canAddObject()"
                        class="add-oe-button black"
                        [title]="'ADDRESS_CARD.ADD_OBJECT' | translate"
                        (click)="addObject()">
            </app-button>
        </div>
    </div>
</div>
