<div class="inputClass">
  <input id="{{inputId}}"
         #input
         class="input-component"
         name="{{inputId}}"
         [maxlength]="maxLength"
         [minlength]="minLength"
         [max]="maxValue"
         [min]="minValue"
         [style.height.px]="inputHeight"
         [class.not-empty]="!!model && !disablePlaceholderAnimation"
         [class.border-without-label]="disablePlaceholderAnimation"
         [(ngModel)]="model"
         [type]="type"
         [step]="step"
         (focus)="emitFocusEvent()"
         (blur)="emitBlurEvent()"
         [disabled]="disabled"
         [placeholder]="placeholder"
         [autocomplete]="autocomplete"
         [readonly]="readonly"
         [textMask]="maskInputConfigs"
         [pattern]="pattern"
  >
  <span class="input-label-container" *ngIf="!disablePlaceholderAnimation">
    <label for="{{inputId}}" class="input-label">
      {{placeholder}}
    </label>
  </span>
</div>

