import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {IAccordionItem} from "@core/interfaces/accordion";

@Component({
  selector: 'app-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss']
})
export class AccordionItemComponent implements OnInit {

  @Input() item: IAccordionItem = null;

  @Output() setOpen: EventEmitter<IAccordionItem> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  public toggle() {
    this.item.open = !this.item.open;
    this.setOpen.emit(this.item);
  }

}
