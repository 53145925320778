import { ErrorHandler, Injectable } from "@angular/core";

@Injectable()
export class ChunkLoadErrorHandler implements ErrorHandler {

    private readonly chunkFailedTemplate: RegExp = /Loading chunk [\d]+ failed/;

    handleError(error: any): void {
        if (this.chunkFailedTemplate.test(error.message)) {
            window.location.reload();
        }
    }
}