<div class="AddressSearchClass">
    <input #AddressSearchField class="address-search-field" [class.filled]="displayItems && displayItems.length > 0"
    [placeholder]="'ADDRESS_CARD.SEARCH_PLACEHOLDER' | translate" [(ngModel)]="value" type="text">
    <div class="address-search-icon" *ngIf="value && !loading" >
        <span class="font-icons icon-calendar_clear" (click)="clear()"></span>
    </div>
    <div class="address-search-icon" *ngIf="!value && !loading" >
        <span class="font-icons icon-search"></span>
    </div>
    <div class="address-search-icon refresh-animation" *ngIf="loading">
        <span class="font-icons icon-refresh"></span>
    </div>
    <div class="address-search-filled-border" *ngIf="displayItems && displayItems.length > 0" ></div>
    <div class="address-search-list scroll-block" *ngIf="displayItems && displayItems.length > 0">
        <div *ngFor="let item of displayItems" class="address-search-list-item" (click)="select(item)" [class.preselected]="item === preselectedValue">
            {{item.name}}
        </div>
    </div>
</div>