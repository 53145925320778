<div class="searchFieldClass"
     [class.transparent]="withTransparentBackground">
  <input #inputField
         class="search-field-input"
         id="{{searchId}}"
         name="{{searchId}}"
         [placeholder]="placeholder"
         [ngModel]="value"
         maxlength="255"
         (keydown.enter)="search()"
         [type]="type" />
  <div class="search-field-poisk"
       (click)="search()">
    <span class="font-icons icon-search"></span>
  </div>
  <div *ngIf="value && value !== ''"
       class="search-field-clear"
       (click)="clear()">
    <span class="font-icons icon-clear"></span>
  </div>
</div>