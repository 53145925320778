<div class="simpleSelectClass" (clickOutside)="openSelect($event, !enableClickOutside)"
     [clickOutsideEnabled]="enableClickOutside">

  <!--Для фильтров без стрелки, открытие по клику на заголовок фильтра-->
  <div *ngIf="forFilter"
       class="simple-select-for-filter"
       [class.disabled]="disabled">
    <div class="simple-select-head" [class.opened]="openedSelect">
      <div class="simple-select-title" (click)="openSelect($event, !openedSelect)">{{title}}</div>
      <ng-container [ngTemplateOutlet]="balloon"></ng-container>
    </div>
    <div *ngIf="selectedValue"
         class="simple-select-value"
         (click)="removeSelection()">
      <div class="simple-select-selected" [title]="selectedValue">
        <span>{{selectedValue}}</span>
      </div>
      <div class="simple-select-remove">
        <span class="font-icons icon-clear"></span>
      </div>
    </div>
  </div>

  <!--Для полей со стрелки, открытие по клику на стрелку-->
  <div *ngIf="!forFilter && withStr"
       class="simple-select-for-field"
       [class.disabled]="disabled"
       [class.with-str]="withStr">
    <div *ngIf="title && !selectedValue" class="simple-select-placeholder">{{title}}</div>
    <div *ngIf="selectedValue" class="simple-select-selected">{{selectedValue}}</div>
    <div class="simple-select-arrow" [class.opened]="openedSelect">
      <span class="font-icons icon-arrow_down" (click)="openSelect($event, !openedSelect)"></span>
      <ng-container [ngTemplateOutlet]="balloon"></ng-container>
    </div>
  </div>

  <!--Для полей без стрелки, открытие по клику на выбранное значение или плейсхолдер-->
  <div *ngIf="!forFilter && !withStr"
       class="simple-select-for-field"
       [class.disabled]="disabled">
    <div class="simple-select-for-field-value"
         [class.with-icon]="(withRemove && selectedValue && !disabled) || (selectedValue && disabled)"
         [class.with-many-row]="withManyRow">
      <div *ngIf="title && !selectedValue"
           class="simple-select-placeholder"
           [class.opened]="openedSelect"
           (click)="openSelect($event, !openedSelect)">
        {{title}}
      </div>
      <div *ngIf="selectedValue"
           class="simple-select-selected"
           [class.opened]="openedSelect"
           (click)="openSelect($event, !openedSelect)">
        <span [title]="selectedValue">{{selectedValue}}</span>
      </div>
      <ng-container [ngTemplateOutlet]="balloon"></ng-container>
    </div>
    <div *ngIf="withRemove && selectedValue && !disabled"
         class="simple-select-for-field-remove"
         (click)="removeSelection()">
      <span class="font-icons icon-clear"></span>
    </div>
    <div *ngIf="selectedValue && disabled"
         class="simple-select-for-field-block">
      <span class="font-icons icon-lock"></span>
    </div>
  </div>
</div>

<!--Шаблон выпадающего списка-->
<ng-template #balloon>
  <div class="simple-select-balloon {{openDirection}}"
       [class.opened]="openedSelect"
       [class.for-filter]="forFilter"
       [class.with-str]="withStr">
    <div *ngIf="list && list.length > 0" class="simple-select-list scroll-block">
      <ng-container [ngTemplateOutlet]="withChildren ? listWithChildren : simpleList"></ng-container>
    </div>
    <div *ngIf="!list || list.length === 0"
         class="simple-select-list-empty"
    >{{'GENERAL.EMPTY_LIST' | translate}}</div>
  </div>
</ng-template>

<ng-template #simpleList>
  <div *ngFor="let item of list"
       class="simple-select-list-item"
       [class.active]="selectedKey && item.key.toString() === selectedKey.toString()"
       (click)="onSelectItem(item)"
  >{{item.value}}</div>
</ng-template>

<ng-template #listWithChildren>
  <div *ngFor="let item of list" class="simple-select-list-parent">
    <div class="simple-select-list-parent-item">{{item.value}}</div>
    <div class="simple-select-list-children">
      <div *ngFor="let child of item.children"
           class="simple-select-list-children-item"
           [class.active]="selectedKey && child.key.toString() === selectedKey.toString()"
           (click)="onSelectItem(child)"
      >{{child.value}}</div>
    </div>
  </div>
</ng-template>
