<div class="headerClass">
  <div class="header-left">
    <div class="header-menu"
         (click)="toogleMenu(true)">
      <span class="font-icons icon-menu"></span>
    </div>
    <a [href]="homeUrl"
       class="header-home"><span class="font-icons icon-home"></span></a>
    <span class="font-icons icon-forward-long"></span>
    <span [routerLink]="mainUrl"
          class="header-logo"><span class="font-icons icon-logo2_min"></span></span>
    <app-breadcrums class="header-breadcrums"></app-breadcrums>
  </div>
  <div class="header-right">
    <div *ngIf="!isAdmin"
         class="header-docs font-icons icon-guide_header"
         (click)="downloadGuide()"></div>
    <div *ngIf="isAdmin"
         class="header-docs font-icons icon-guide_header"
         [class.opened]="showGuideBalloon"
         (click)="toggleGuideBalloon($event, !guideBalEnableClickOutside)"
         (clickOutside)="toggleGuideBalloon($event, !guideBalEnableClickOutside)"
         [clickOutsideEnabled]="guideBalEnableClickOutside">
      <ng-container *ngIf="showGuideBalloon"
                    [ngTemplateOutlet]="guideBalloon"></ng-container>
    </div>
    <div class="header-support font-icons icon-support_header"
         [class.opened]="showSupportBalloon"
         (click)="toggleSupportBalloon($event, !supBalEnableClickOutside)"
         (clickOutside)="toggleSupportBalloon($event, !supBalEnableClickOutside)"
         [clickOutsideEnabled]="supBalEnableClickOutside">
      <ng-container *ngIf="showSupportBalloon"
                    [ngTemplateOutlet]="supportBalloon"></ng-container>
    </div>
    <div class="header-user font-icons icon-user_logo"
         [class.opened]="showUserBalloon"
         (click)="toggleUserBalloon($event, !userBalEnableClickOutside)"
         (clickOutside)="toggleUserBalloon($event, !userBalEnableClickOutside)"
         [clickOutsideEnabled]="userBalEnableClickOutside">
      <ng-container *ngIf="showUserBalloon"
                    [ngTemplateOutlet]="userBalloon"></ng-container>
    </div>
    <span class="header-notification font-icons icon-notification"
          [class.unread]="hasUnreadNotifications">
      <span class="path1"></span>
      <span class="path2"></span>
    </span>
  </div>


</div>
<app-menu></app-menu>

<ng-template #supportBalloon>
  <div class="header-support-balloon"
       (click)="openSupportBalloon($event)">
    <div class="header-support-balloon-item"
         (click)="openSupportBalloon($event)">
      <span class="font-icons icon-phone"></span>
      <span class="text">{{supportPhone}}</span>
    </div>
    <div class="header-support-balloon-item"
         (click)="openSupportBalloon($event)">
      <span class="font-icons icon-mail"></span>
      <a href="mailTo:{{supportEmail}}"
         target="_blank"
         class="outer-href">{{supportEmail}}</a>
    </div>
  </div>
</ng-template>

<ng-template #userBalloon>
  <div class="header-user-balloon"
       (click)="openUserBalloon($event)">
    <div class="header-user-balloon-item"
        *ngIf="user?.name"
         (click)="openUserBalloon($event)">
      <span class="text user-name"
            [innerHTML]="user.name"></span>
    </div>
    <div *ngIf="user?.phoneNumber"
         class="header-user-balloon-item"
         (click)="openUserBalloon($event)">
      <span class="font-icons icon-phone"></span>
      <span class="text"
            [innerHTML]="user.phoneNumber"></span>
    </div>
    <div *ngIf="user?.email"
         class="header-user-balloon-item"
         (click)="openUserBalloon($event)">
      <span class="font-icons icon-mail"></span>
      <span class="text"
            [innerHTML]="user.email"></span>
    </div>
    <div class="header-user-balloon-item">
      <a class="inner-href"
         (click)="exit()">{{'GENERAL.EXIT' | translate}}</a>
    </div>
  </div>
</ng-template>

<ng-template #guideBalloon>
  <div class="header-guide-balloon"
       (click)="openGuideBalloon($event)">
    <div class="header-guide-balloon-item">
      <a class="inner-href"
         (click)="downloadGuide()">{{'GUIDE.DOWNLOAD_GUIDE' | translate}}</a>
    </div>
    <div class="header-guide-balloon-item">
      <a class="inner-href"
         (click)="loadGuide()">{{'GUIDE.LOAD_GUIDE' | translate}}</a>
    </div>
  </div>
</ng-template>