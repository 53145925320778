import { Subscription } from 'rxjs';
import { Component, ComponentFactoryResolver, OnInit, AfterViewInit, OnDestroy, ComponentFactory, ViewChild, ViewContainerRef, ViewRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-base-page-with-childs',
  templateUrl: './base-page-with-childs.component.html',
  styleUrls: ['./base-page-with-childs.component.scss']
})
export class BasePageWithChildsComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('basePageWithChildsOutlet', { read: ViewContainerRef }) outlet: ViewContainerRef;

  private paramKey: string = 'id'; // ключ url параметра
  private paramAllowNumbers: boolean = true;  // можно ли передавать цифры в параметре
  private paramStringOptions: string[] = ['new']; // доступные строковые значения параметра

  private factory: ComponentFactory<any>; // фабрика основного компонента
  private routerSub: Subscription; // подписка на события роутера
  private mainViewRef: ViewRef; // ссылка на вью основного компонента

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private resolver: ComponentFactoryResolver
  ) { }

  ngOnInit() {
    let data = this.activatedRoute.snapshot.data;
    this.paramKey = data.paramKey
      ? data.paramKey
      : this.paramKey;
    this.paramStringOptions = data.paramStringOptions
      ? data.paramStringOptions
      : this.paramStringOptions;
    this.paramAllowNumbers = 'paramAllowNumbers' in data
      ? data.paramAllowNumbers
      : this.paramAllowNumbers;
    this.factory = this.resolver.resolveComponentFactory(
      data.mainComponent
    );
  }

  ngAfterViewInit() {
    this.routerSub = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateView();
      });
    setTimeout(() => {
      this.updateView();
    }, 0);
  }

  ngOnDestroy() {
    this.routerSub.unsubscribe();
  }

  private updateView() {
    let param = this.activatedRoute.firstChild?.snapshot.paramMap.get(this.paramKey);
    // Если нет параметра, то грузим основной компонент
    if (!param) {
      this.mainViewRef = this.outlet.createComponent(this.factory).hostView;
    }
    // Если есть параметр, удаляем основной компонент
    // Роутер сам грузит дочерний в outlet
    else if (this.paramStringOptions.includes(param) || (this.paramAllowNumbers && !!Number(param))) {
      this.mainViewRef?.destroy();
    }
    // Если параметры не подходят, то редирект
    else {
      this.router.navigate(['/page-not-found']);
    }
  }
}
