import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {IFileName} from "@core/interfaces/file";
import {UtilsService} from "@shared/services/utils.service";
import {SYSTEM_PARAMETER_NAME} from "@app/app.enums";
import {SystemParamsService} from "@core/services/system-params.service";
import {ToastrService} from "ngx-toastr";
import {Subscription} from "rxjs";
import {GuideApiService} from "@core/services/api/admin/guide-api.service";

@Component({
  selector: 'app-add-guide-modal',
  templateUrl: './add-guide-modal.component.html',
  styleUrls: ['./add-guide-modal.component.scss']
})
export class AddGuideModalComponent implements OnInit, OnDestroy {

  @ViewChild('uploadInput') uploadInput: ElementRef;

  public file: File = null;
  public fileName: IFileName;
  public fileUrl: any = null;

  private successLoad: string = '';
  private errorLoad: string = '';
  private errorFileSize: string = '';
  private errorFileType: string = '';

  public isLoading: boolean = false;
  public maxFileSize: number = 50;
  private systemParams$: Subscription = new Subscription();

  constructor(
    public translateService: TranslateService,
    public activeModal: NgbActiveModal,
    private utilsService: UtilsService,
    private systemParamsService: SystemParamsService,
    private guideApiService: GuideApiService,
    private toastr: ToastrService,
  ) {
    this.translateService.get(['GUIDE']).subscribe(res => {
      this.successLoad = res['GUIDE']['SUCCESS_LOAD_GUIDE'];
      this.errorLoad = res['GUIDE']['ERROR_LOAD_GUIDE'];
      this.errorFileSize = res['GUIDE']['ERROR_FILE_SIZE'];
      this.errorFileType = res['GUIDE']['ERROR_FILE_TYPE'];
    });
  }

  ngOnInit() {
    this.systemParams$ = this.systemParamsService.getSystemParams()
      .subscribe((res: any) => {
        this.maxFileSize = res && res[SYSTEM_PARAMETER_NAME.FILE_SIZE]
          ? Number(res[SYSTEM_PARAMETER_NAME.FILE_SIZE])
          : 50;
      });
  }

  ngOnDestroy() {
    this.systemParams$.unsubscribe();
  }

  /**
   * Добавляем файл
   * @param event евент
   */
  public addFile(event: any) {
    let files: File[] = event.files;
    if (files && files.length > 0) {
      this.file = this.checkFileType(files[0]) && this.checkFileSize(files[0]) ? files[0] : null;
      this.fileName = this.utilsService.getFileNameWithExtension(files[0].name);
      if (this.file) {
        let reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = (_event) => {
          this.fileUrl = reader.result;
        }

      }
    }
    this.uploadInput.nativeElement.value = null;
  }

  /**
   * Удаляем локальный файл
   */
  public deleteFile() {
    this.file = null;
    this.fileUrl = null;
    this.fileName = null;
  }

  /**
   * Проверка расширения файла
   * @param file
   */
  private checkFileType(file: File): boolean {
    let types = ['docx', 'pdf', 'doc'];
    let type = this.utilsService.getFileNameWithExtension(file.name).extension;
    if (file && type && type != '' &&
      (types.find(t => type.includes(t))) ||
      types.find(t => t.includes(type))) {
      return true;
    }
    else {
      this.toastr.error(this.errorFileType);
      return false;
    }
  }

  /**
   * Проверка размера файла
   * @param file файл
   */
  private checkFileSize(file: File): boolean {
    if (file && file.size) {
      if (file.size <= this.maxFileSize * 1000000) {
        return true;
      }
      else {
        this.toastr.error(this.errorFileSize);
        return false;
      }
    }
    else {
      return false;
    }
  }

  public cancel(): void {
    this.activeModal.close();
  }

  public apply(): void {
    this.isLoading = true;
    this.guideApiService.addGuideFile(this.file)
      .subscribe((fileId: number) => {
        this.guideApiService.addGuide(fileId)
          .subscribe(result => {
            this.isLoading = false;
            this.toastr.success(this.successLoad);
            this.activeModal.close();
            }, error => {
            this.isLoading = false;
            if (error.error && error.error['message']) {
              this.toastr.error(error.error['message'], this.errorLoad);
            } else {
              this.toastr.error(this.errorLoad);
            }
          });
        }, error => {
        this.isLoading = false;
        if (error.error && error.error['message']) {
          this.toastr.error(error.error['message'], this.errorLoad);
        } else {
          this.toastr.error(this.errorLoad);
        }
      });
  }

}
