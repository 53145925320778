export const password_pattern = /[^А-Яа-яЁё\s]/;
export const login_pattern = /[a-z0-9]/;
export const name_pattern = /[А-Яа-яЁё]/;
export const number_pattern = /[0-9]/;
export const float_pattern = /[0-9.]/;
export const ipv4_pattern = /^(?!\.)((^|\.)([1-9]?\d|1\d\d|2(5[0-5]|[0-4]\d))){4}$/;
export const date_full_ISO_pattern = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d[.:][0-5]\d|Z)$/;
export const date_ISO_pattern = /^\d{4}-[01]\d-[0-3]\d$/;

export enum ACCESS_TREE_OBJECT_TYPE {
  MENU = 'MENU',
  GRID = 'GRID',
  G_ATTR = 'G_ATTR',
  G_FUNC = 'G_FUNC',
  CARD = 'CARD',
  C_TAB = 'C_TAB',
  C_FUNC = 'C_FUNC',
  G_ATTR_R = 'G_ATTR_R',
  G_FUNC_R = 'G_FUNC_R',
  C_TAB_R = 'C_TAB_R',
  C_FUNC_R = 'C_FUNC_R',
}

export enum SYSTEM_PARAMETER_NAME {
  PASSWORD_RECOVERY_LINK_LIFETIME = 'PASSWORD_RECOVERY_LINK_LIFETIME',
  SUPPORT_PHONE = 'SUPPORT_PHONE',
  SUPPORT_EMAIL = 'SUPPORT_EMAIL',
  AUTH_URL = 'AUTH_URL',
  FILE_SIZE = 'FILE_SIZE',
  ARCHIVE_REREAD_DEPTH = 'ARCHIVE_REREAD_DEPTH',
  CONNECTION_STATUS_LIFETIME = 'CONNECTION_STATUS_LIFETIME',
  NSI_ADDRESS_READONLY = 'NSI_ADDRESS_READONLY',
  MEASURE_OBJECT_READONLY = 'MEASURE_OBJECT_READONLY',
  NSI_ORGANIZATION_READONLY = 'NSI_ORGANIZATION_READONLY'
}

export enum GRID_ID {
  "ADMIN.USERS" = "admin.users",
  "ADMIN.GROUPS" = "admin.groups",
  "ADMIN.ROLES" = "admin.roles",
  "ADMIN.SYSTEM_PARAMETERS" = "admin.system_parameters",
  "ADMIN.TASK_SCHEDULE" = "admin.task_schedule",
  "ADMIN.SOFTWARE_RENT_CONTRACT" = "admin.software_rent_contract",
  "ADMIN.SOFTWARE_RENT_CONTRACT_VERSIONS" = "admin.software_rent_contract_versions",
  "ADMIN.SOFTWARE_RENT_CONTRACT_VERSIONS_ADD_USERS" = "admin.software_rent_contract_versions_add_users",
  "ADMIN.SOFTWARE_RENT_CONTRACT_VERSIONS_ADD_OBJECTS" = "admin.software_rent_contract_versions_add_objects",
  "ADMIN.GROUPS.ADDRESS" = "admin.groups.address",
  "ADMIN.MONITORING.DB_TESTS_LIST" = "admin.monitoring.db-tests-list",
  "ADMIN.MONITORING.DB_TESTS_RESULTS" = "admin.monitoring.db-tests-results",
  "ADMIN.MONITORING.UPLOADS_JOURNAL" = "admin.monitoring.uploads-journal",
  "ADMIN.MONITORING.UPLOADS_JOURNAL_RESULTS" = "admin.monitoring.uploads-journal-results",
  "ENERGY_RESOURCES.MEASURE_OBJECTS" = "energy_resources.measure_objects",
  "ENERGY_RESOURCES.MEASURE_OBJECT.HISTORY_CONTRACT_VERSIONS" = "energy_resources.measure_objects.history_contract_versions",
  "ENERGY_RESOURCES.DEVICES" = "energy_resources.devices",
  "ENERGY_RESOURCES.MEASURE_POINTS" = "energy_resources.measure_points",
  "ENERGY_RESOURCES.CONNECT_USPD" = "energy_resources.connect_uspd",
  "ENERGY_RESOURCES.CONNECT_UPD" = "energy_resources.connect_upd",
  "ENERGY_RESOURCES.CONNECT_IU" = "energy_resources.connect_iu",
  "ENERGY_RESOURCES.CONNECT_TU" = "energy_resources.connect_tu",
  "ENERGY_RESOURCES.CONNECT_PU" = "energy_resources.connect_pu",
  "ENERGY_RESOURCES.HISTORY_CONNECTION_STATES" = "energy_resources.history_connection-states",
  "ENERGY_RESOURCES.HISTORY_STATES" = "energy_resources.history_states",
  "ENERGY_RESOURCES.HISTORY_CONNECT_USD_TO_OU" = "energy_resources.history_connect_usd_to_ou",
  "ENERGY_RESOURCES.HISTORY_CONNECT_TU_TO_PU_ON_OU" = "energy_resources.history_connect_tu_to_pu_on_ou",
  "ENERGY_RESOURCES.HISTORY_CONNECT_IU_TO_TU_ON_OU" = "energy_resources.history_connect_iu_to_tu_on_ou",
  "ENERGY_RESOURCES.HISTORY_CONNECT_USPD_TO_OU" = "energy_resources.history_connect_uspd_to_ou",
  "ENERGY_RESOURCES.HISTORY_CONNECT_PU_TO_USPD" = "energy_resources.history_connect_pu_to_uspd",
  "ENERGY_RESOURCES.HISTORY_CONNECT_UPD_TO_OU" = "energy_resources.history_connect_upd_to_ou",
  "ENERGY_RESOURCES.HISTORY_CONNECT_PU_TO_UPD" = "energy_resources.history_connect_pu_to_upd",
  "ENERGY_RESOURCES.HISTORY_CONNECT_TU_TO_PU_ON_PU" = "energy_resources.history_connect_tu_to_pu_on_pu",
  "ENERGY_RESOURCES.HISTORY_CONNECT_PU_TO_USD" = "energy_resources.history_connect_pu_to_usd",
  "ENERGY_RESOURCES.HISTORY_CONNECT_IU_TO_TU_ON_IU" = "energy_resources.history_connect_iu_to_tu_on_iu",
  "ENERGY_RESOURCES.HISTORY_CONNECT_TU_TO_PU_ON_TU" = "energy_resources.history_connect_tu_to_pu_on_tu",
  "ENERGY_RESOURCES.HISTORY_CONNECT_IU_TO_TU_ON_TU" = "energy_resources.history_connect_iu_to_tu_on_tu",
  "ENERGY_RESOURCES.EVENT_LOG" = "energy_resources.event_log",
  "ENERGY_RESOURCES.INSTANT_PARAMS" = "energy_resources.instant_params",
  "ENERGY_RESOURCES.ARCHIVE_PARAMS" = "energy_resources.archive_params",
  "ENERGY_RESOURCES.CONFIG_JOURNAL" = "energy_resources.config_journal",
  "ENERGY_RESOURCES.MEASURE_OBJECT.PICTURES" = "energy_resources.measure_objects.pictures",
  "ENERGY_RESOURCES.MEASURE_OBJECT.DOCUMENTS" = "energy_resources.measure_objects.documents",
  "ENERGY_RESOURCES.DEVICES.DOCUMENTS" = "energy_resources.devices.documents",
  "ENERGY_RESOURCES.WARRANTY" = "energy_resources.warranty",
  "EVENT_LOG" = "event_log",
  "EVENT_LOG_IVK" = "ivk_events"
}

export enum USER_ACCESS {
  "ADMIN" = "m_Admin",
  "ROLE" = "g_Role",
  "ROLE.card" = "c_Role",
  "ROLE.card_add" = "c_Role.cf_add",
  "ROLE.card_edit" = "c_Role.cf_edit",
  "ROLE.card_del" = "c_Role.cf_del",
  "GROUP" = "g_Group",
  "GROUP.card" = "c_Group",
  "GROUP.card_add" = "c_Group.cf_add",
  "GROUP.card_edit" = "c_Group.cf_edit",
  "GROUP.card_del" = "c_Group.cf_del",
  "USER" = "g_User",
  "USER.card" = "c_User",
  "USER.card_edit" = "c_User.cf_edit",
  "USER.card_software_rent_contract_version" = "c_UserSoftwareRentalContractVersion",
  "USER.card_software_rent_contract_version_add" = "c_UserSoftwareRentalContractVersion.cf_add",
  "USER.card_software_rent_contract_version_edit" = "c_UserSoftwareRentalContractVersion.cf_edit",
  "USER.card_software_rent_contract_version_del" = "c_UserSoftwareRentalContractVersion.cf_del",
  "USER.card_software_rent_contract_version_finish_now" = "c_UserSoftwareRentalContractVersion.cf_FinishNow",
  "USER.card_software_rent_contract_version_finish_date" = "c_UserSoftwareRentalContractVersion.cf_FinishDate",
  "USER.grid_repl" = "g_User.gf_repl",
  "SYSTEM" = "g_System",
  "SYSTEM.card" = "c_System",
  "SYSTEM.card_edit" = "c_System.cf_edit",
  "TASKSCHEDULE" = "g_TaskSchedule",
  "TASKSCHEDULE.card" = "c_TaskSchedule",
  "TASKSCHEDULE.card_edit" = "c_TaskSchedule.cf_edit",
  "EVENT_LOG" = "g_EventLog",
  "DB_TESTS_MONITORING" = "g_DBTests",
  "EVENT_LOG_IVK" = "g_IVKEvents",
  "NSI_CATALOG" = "g_Catalog",
  "UPLOADS_JOURNAL" = "g_Uploads",
  "SOFTWARE_RENT_CONTRACT" = "g_SoftwareRentalContract",
  "SOFTWARE_RENT_CONTRACT.card" = "c_SoftwareRentalContract",
  "SOFTWARE_RENT_CONTRACT.card_add" = "c_SoftwareRentalContract.cf_add",
  "SOFTWARE_RENT_CONTRACT.card_edit" = "c_SoftwareRentalContract.cf_edit",
  "SOFTWARE_RENT_CONTRACT.card_del" = "c_SoftwareRentalContract.cf_del",
  "SOFTWARE_RENT_CONTRACT_VERSION.card" = "c_SoftwareRentalContractVersion",
  "SOFTWARE_RENT_CONTRACT_VERSION.card_add" = "c_SoftwareRentalContractVersion.cf_add",
  "SOFTWARE_RENT_CONTRACT_VERSION.card_edit" = "c_SoftwareRentalContractVersion.cf_edit",
  "SOFTWARE_RENT_CONTRACT_VERSION.card_del" = "c_SoftwareRentalContractVersion.cf_del",
  "SOFTWARE_RENT_CONTRACT_VERSION.card_finish_date" = "c_SoftwareRentalContractVersion.cf_FinishDate",
  "SOFTWARE_RENT_CONTRACT_VERSION.card_add_user" = "c_SoftwareRentalContractVersion.cf_addUser",
  "SOFTWARE_RENT_CONTRACT_VERSION.card_del_user" = "c_SoftwareRentalContractVersion.cf_delUser",
  "SOFTWARE_RENT_CONTRACT_VERSION.card_transfer_user" = "c_SoftwareRentalContractVersion.cf_transferUser",
  "SOFTWARE_RENT_CONTRACT_VERSION.card_disconnect_user_now" = "c_SoftwareRentalContractVersion.cf_disconnectUserNow",
  "SOFTWARE_RENT_CONTRACT_VERSION.card_disconnect_user_date" = "c_SoftwareRentalContractVersion.cf_disconnectUserDate",
  "SOFTWARE_RENT_CONTRACT_VERSION.card_add_object" = "c_SoftwareRentalContractVersion.cf_addObject",
  "SOFTWARE_RENT_CONTRACT_VERSION.card_del_object" = "c_SoftwareRentalContractVersion.cf_delObject",
  "SOFTWARE_RENT_CONTRACT_VERSION.card_transfer_object" = "c_SoftwareRentalContractVersion.cf_transferObject",
  "SOFTWARE_RENT_CONTRACT_VERSION.card_disconnect_object_now" = "c_SoftwareRentalContractVersion.cf_disconnectObjectNow",
  "SOFTWARE_RENT_CONTRACT_VERSION.card_disconnect_object_date" = "c_SoftwareRentalContractVersion.cf_disconnectObjectDate",
  "ENERGY_RESOURCES" = "m_ЕnergyResources",
  "MEASURE_OBJECT" = "g_MeasureObjects",
  "MEASURE_OBJECT.card" = "c_MeasureObject",
  "MEASURE_OBJECT.card.history" = "c_MeasureObject.ct_History",
  "MEASURE_OBJECT.card.documents" = "c_MeasureObject.ct_Documents",
  "MEASURE_OBJECT.card.pictures" = "c_MeasureObject.ct_Pictures",
  "MEASURE_OBJECT.card_add" = "c_MeasureObject.cf_add",
  "MEASURE_OBJECT.card_edit" = "c_MeasureObject.cf_edit",
  "MEASURE_OBJECT.card_del" = "c_MeasureObject.cf_del",
  "MEASURE_OBJECT.card_contract_versions" = "c_MeasureObject.cf_SoftwareRentalContract",
  "MEASURE_OBJECT.card_to_archive" = "c_MeasureObject.cf_StatusToArchive",
  "MEASURE_OBJECT.card_from_archive" = "c_MeasureObject.cf_StatusFromArchive",
  "MEASURE_OBJECT.card.pictures_add" = "c_MeasureObject.cf_AddPicture",
  "MEASURE_OBJECT.card.pictures_del" = "c_MeasureObject.cf_DeletePicture",
  "MEASURE_OBJECT.card.documents_add" = "c_MeasureObject.cf_AddDocument",
  "MEASURE_OBJECT.card.documents_edit" = "c_MeasureObject.cf_EditDocument",
  "MEASURE_OBJECT.card.documents_del" = "c_MeasureObject.cf_DeleteDocument",
  "DEVICE_TRANSFER.card" = "c_DeviceTransfer",
  "DEVICE_TRANSFER.card.history" = "c_DeviceTransfer.ct_History",
  "DEVICE_TRANSFER.card.documents" = "c_DeviceTransfer.ct_Documents",
  "DEVICE_TRANSFER.card.config" = "c_DeviceTransfer.ct_Config",
  "DEVICE_TRANSFER.card.warranty" = "c_DeviceTransfer.ct_Warranty",
  "DEVICE_TRANSFER.card_add" = "c_DeviceTransfer.cf_add",
  "DEVICE_TRANSFER.card_edit" = "c_DeviceTransfer.cf_edit",
  "DEVICE_TRANSFER.card_del" = "c_DeviceTransfer.cf_del",
  "DEVICE_TRANSFER.card_to_service" = "c_DeviceTransfer.cf_StatusToService",
  "DEVICE_TRANSFER.card_to_archive" = "c_DeviceTransfer.cf_StatusToArchive",
  "DEVICE_TRANSFER.card_from_archive" = "c_DeviceTransfer.cf_StatusFromArchive",
  "DEVICE_TRANSFER.connect_to_measure_object" = "c_DeviceTransfer.cf_ConnectToMeasureObject",
  "DEVICE_TRANSFER.disconnect_from_measure_object" = "c_DeviceTransfer.cf_DisconnectFromMeasureObject",
  "DEVICE_TRANSFER.del_connect_to_measure_object" = "c_DeviceTransfer.cf_DeleteConnectToMeasureObject",
  "DEVICE_TRANSFER.check_connect" = "c_DeviceTransfer.cf_CheckConnectionUSPD",
  "DEVICE_TRANSFER.write_uspd_params" = "c_DeviceTransfer.cf_WriteUspdParam",
  "DEVICE_TRANSFER.read_uspd_tree" = "c_DeviceTransfer.cf_ReadUspdTree",
  "DEVICE_TRANSFER.get_instant_data" = "c_DeviceTransfer.cf_GetInstantMeterDataUSPD",
  "DEVICE_TRANSFER.restart_uspd" = "c_DeviceTransfer.cf_RestartUSPD",
  "DEVICE_TRANSFER.write_uspd_tree" = "c_DeviceTransfer.cf_WriteUspdTree",
  "DEVICE_TRANSFER.reread_instant_data" = "c_DeviceTransfer.cf_RereadMeterDataUSPD",
  "DEVICE_TRANSFER.generate_passport" = "c_DeviceTransfer.cf_GeneratePassportUSPD",
  "DEVICE_TRANSFER.card.warranty_add" = "c_DeviceTransfer.cf_AddWarranty",
  "DEVICE_TRANSFER.card.warranty_edit" = "c_DeviceTransfer.cf_EditWarranty",
  "DEVICE_TRANSFER.card.warranty_del" = "c_DeviceTransfer.cf_DeleteWarranty",
  "DEVICE_TRANSFER.card.documents_add" = "c_DeviceTransfer.cf_AddDocument",
  "DEVICE_TRANSFER.card.documents_edit" = "c_DeviceTransfer.cf_EditDocument",
  "DEVICE_TRANSFER.card.documents_del" = "c_DeviceTransfer.cf_DeleteDocument",
  "DEVICE_MODEM.card" = "c_DeviceModem",
  "DEVICE_MODEM.card.history" = "c_DeviceModem.ct_History",
  "DEVICE_MODEM.card.documents" = "c_DeviceModem.ct_Documents",
  "DEVICE_MODEM.card.config" = "c_DeviceModem.ct_Config",
  "DEVICE_MODEM.card.warranty" = "c_DeviceModem.ct_Warranty",
  "DEVICE_MODEM.card_add" = "c_DeviceModem.cf_add",
  "DEVICE_MODEM.card_edit" = "c_DeviceModem.cf_edit",
  "DEVICE_MODEM.card_del" = "c_DeviceModem.cf_del",
  "DEVICE_MODEM.card_to_service" = "c_DeviceModem.cf_StatusToService",
  "DEVICE_MODEM.card_to_archive" = "c_DeviceModem.cf_StatusToArchive",
  "DEVICE_MODEM.card_from_archive" = "c_DeviceModem.cf_StatusFromArchive",
  "DEVICE_MODEM.connect_to_measure_object" = "c_DeviceModem.cf_ConnectToMeasureObject",
  "DEVICE_MODEM.disconnect_from_measure_object" = "c_DeviceModem.cf_DisconnectFromMeasureObject",
  "DEVICE_MODEM.del_connect_to_measure_object" = "c_DeviceModem.cf_DeleteConnectToMeasureObject",
  "DEVICE_MODEM.check_connect" = "c_DeviceModem.cf_CheckConnectionModem",
  "DEVICE_MODEM.write_modem_params" = "c_DeviceModem.cf_WriteModemParam",
  "DEVICE_MODEM.read_modem_tree" = "c_DeviceModem.cf_ReadModemTree",
  "DEVICE_MODEM.get_instant_data" = "c_DeviceModem.cf_GetInstantMeterDataModem",
  "DEVICE_MODEM.restart_modem" = "c_DeviceModem.cf_RestartModem",
  "DEVICE_MODEM.write_modem_tree" = "c_DeviceModem.cf_WriteModemTree",
  "DEVICE_MODEM.reread_instant_data" = "c_DeviceModem.cf_RereadMeterDataModem",
  "DEVICE_MODEM.generate_passport" = "c_DeviceModem.cf_GeneratePassportModem",
  "DEVICE_MODEM.card.warranty_add" = "c_DeviceModem.cf_AddWarranty",
  "DEVICE_MODEM.card.warranty_edit" = "c_DeviceModem.cf_EditWarranty",
  "DEVICE_MODEM.card.warranty_del" = "c_DeviceModem.cf_DeleteWarranty",
  "DEVICE_MODEM.card.documents_add" = "c_DeviceModem.cf_AddDocument",
  "DEVICE_MODEM.card.documents_edit" = "c_DeviceModem.cf_EditDocument",
  "DEVICE_MODEM.card.documents_del" = "c_DeviceModem.cf_DeleteDocument",
  "DEVICE_METER.card" = "c_DeviceMeter",
  "DEVICE_METER.card.history" = "c_DeviceMeter.ct_History",
  "DEVICE_METER.card.documents" = "c_DeviceMeter.ct_Documents",
  "DEVICE_METER.card.warranty" = "c_DeviceMeter.ct_Warranty",
  "DEVICE_METER.card_add" = "c_DeviceMeter.cf_add",
  "DEVICE_METER.card_edit" = "c_DeviceMeter.cf_edit",
  "DEVICE_METER.card_del" = "c_DeviceMeter.cf_del",
  "DEVICE_METER.card_to_service" = "c_DeviceMeter.cf_StatusToService",
  "DEVICE_METER.card_to_work" = "c_DeviceMeter.cf_StatusToWork",
  "DEVICE_METER.card_to_archive" = "c_DeviceMeter.cf_StatusToArchive",
  "DEVICE_METER.card_from_archive" = "c_DeviceMeter.cf_StatusFromArchive",
  "DEVICE_METER.connect_to_usd" = "c_DeviceMeter.cf_ConnectToUSD",
  "DEVICE_METER.disconnect_from_usd" = "c_DeviceMeter.cf_DisconnectFromUSD",
  "DEVICE_METER.del_connect_to_usd" = "c_DeviceMeter.cf_DeleteConnectToUSD",
  "DEVICE_METER.card.warranty_add" = "c_DeviceMeter.cf_AddWarranty",
  "DEVICE_METER.card.warranty_edit" = "c_DeviceMeter.cf_EditWarranty",
  "DEVICE_METER.card.warranty_del" = "c_DeviceMeter.cf_DeleteWarranty",
  "DEVICE_METER.card.documents_add" = "c_DeviceMeter.cf_AddDocument",
  "DEVICE_METER.card.documents_edit" = "c_DeviceMeter.cf_EditDocument",
  "DEVICE_METER.card.documents_del" = "c_DeviceMeter.cf_DeleteDocument",
  "DEVICE_MEASURE.card" = "c_DeviceMeasure",
  "DEVICE_MEASURE.card.history" = "c_DeviceMeasure.ct_History",
  "DEVICE_MEASURE.card.documents" = "c_DeviceMeasure.ct_Documents",
  "DEVICE_MEASURE.card.warranty" = "c_DeviceMeasure.ct_Warranty",
  "DEVICE_MEASURE.card_add" = "c_DeviceMeasure.cf_add",
  "DEVICE_MEASURE.card_edit" = "c_DeviceMeasure.cf_edit",
  "DEVICE_MEASURE.card_del" = "c_DeviceMeasure.cf_del",
  "DEVICE_MEASURE.card_to_service" = "c_DeviceMeasure.cf_StatusToService",
  "DEVICE_MEASURE.card_to_archive" = "c_DeviceMeasure.cf_StatusToArchive",
  "DEVICE_MEASURE.card_from_archive" = "c_DeviceMeasure.cf_StatusFromArchive",
  "DEVICE_MEASURE.connect_to_measure_point" = "c_DeviceMeasure.cf_ConnectToMeasurePoint",
  "DEVICE_MEASURE.disconnect_from_measure_point" = "c_DeviceMeasure.cf_DisconnectFromMeasurePoint",
  "DEVICE_MEASURE.del_connect_to_measure_point" = "c_DeviceMeasure.cf_DeleteConnectToMeasurePoint",
  "DEVICE_MEASURE.card.warranty_add" = "c_DeviceMeasure.cf_AddWarranty",
  "DEVICE_MEASURE.card.warranty_edit" = "c_DeviceMeasure.cf_EditWarranty",
  "DEVICE_MEASURE.card.warranty_del" = "c_DeviceMeasure.cf_DeleteWarranty",
  "DEVICE_MEASURE.card.documents_add" = "c_DeviceMeasure.cf_AddDocument",
  "DEVICE_MEASURE.card.documents_edit" = "c_DeviceMeasure.cf_EditDocument",
  "DEVICE_MEASURE.card.documents_del" = "c_DeviceMeasure.cf_DeleteDocument",
  "MEASURE_POINT" = "g_MeasurePoints",
  "MEASURE_POINT.card" = "c_MeasurePoint",
  "MEASURE_POINT.card.history" = "c_MeasurePoint.ct_History",
  "MEASURE_POINT.card_add" = "c_MeasurePoint.cf_add",
  "MEASURE_POINT.card_edit" = "c_MeasurePoint.cf_edit",
  "MEASURE_POINT.card_del" = "c_MeasurePoint.cf_del",
  "MEASURE_POINT.card_to_archive" = "c_MeasurePoint.cf_StatusToArchive",
  "MEASURE_POINT.card_from_archive" = "c_MeasurePoint.cf_StatusFromArchive",
  "MEASURE_POINT.connect_to_device_meter" = "c_MeasurePoint.cf_ConnectToDeviceMeter",
  "MEASURE_POINT.disconnect_from_device_meter" = "c_MeasurePoint.cf_DisconnectFromDeviceMeter",
  "MEASURE_POINT.del_connect_to_device_meter" = "c_MeasurePoint.cf_DeleteConnectToDeviceMeter",
  "MEASURE_POINT.open_instant_journal" = "c_MeasurePoint.cf_OpenInstantJournal",
  "MEASURE_POINT.open_archive_journal" = "c_MeasurePoint.cf_OpenArchiveJournal",
  "MEASURE_POINT.get_instant_meter_data" = "c_MeasurePoint.cf_GetInstantMeterData",
  "MEASURE_POINT.reread_instant_data" = "c_MeasurePoint.cf_RereadMeterDataTU",
  "DEVICE" = "g_Devices",
  "DEVICE.usd_to_ou.card" = "c_TransferToObjectConnectionHist",
  "DEVICE.usd_to_ou.card_edit" = "c_TransferToObjectConnectionHist.cf_edit",
  "DEVICE.pu_to_usd.card" = "c_MeterToTransferConnectionHist",
  "DEVICE.pu_to_usd.card_edit" = "c_MeterToTransferConnectionHist.cf_edit",
  "DEVICE.tu_to_pu.card" = "c_PointToMeterConnectionHist",
  "DEVICE.tu_to_pu.card_edit" = "c_PointToMeterConnectionHist.cf_edit",
  "DEVICE.iu_to_tu.card" = "c_MeasureToPointConnectionHist",
  "DEVICE.iu_to_tu.card_edit" = "c_MeasureToPointConnectionHist.cf_edit",
  "DEVICE.uploadDeviceList" = "g_Devices.gf_UploadDeviceList"
}

export enum Criteria {
  equals = "equals",
  EQUALS_IGNORE_CASE = "EQUALS_IGNORE_CASE",
  EQUALS_OR_NULL = "EQUALS_OR_NULL",
  notEquals = "notEquals",
  greaterThan = "greaterThan",
  lessThan = "lessThan",
  greaterOrEquals = "greaterOrEquals",
  lessOrEquals = "lessOrEquals",
  between = "between",
  in = "in",
  notIn = "notIn",
  like = "like",
  notLike = "notLike",
  startFrom = "startFrom",
  notStartFrom = "notStartFrom",
  endTo = "endTo",
  notEndTo = "notEndTo",
  isNull = "isNull",
  isNotNull = "isNotNull",
  inOrNull = "inOrNull"
}

// типы фильтров
export enum FilterType {
  text = "text",
  number = 'number',
  date = "date",
  time = "time",
  dateRange = "dateRange",
  select = "select",
  multiSelect = "multiSelect",
  simpleDictionary = 'simpleDictionary',
  multiDictionary = 'multiDictionary'
}

export enum GroupType {
  COMMON = 'COMMON',
  PERSONAL = 'PERSONAL',
  NO_GROUP = 'NO_GROUP',
}

export enum RoleType {
  COMMON = 'COMMON',
  PERSONAL = 'PERSONAL',
  NO_ROLE = 'NO_ROLE',
}

export enum TaskScheduleType {
  DATABASE = "DATABASE",
  BACKEND = "BACKEND"
}

export enum ResourceType {
  HOT_WATER = 'ГВС',
  HEATING = 'ЦО',
  VENTILATION = 'ВЕНТ',
  HEAT_ENERGY = 'ТЭ',
  COLD_WATER = 'ХВС',
  ELECTRIC_ENERGY = 'ЭЭ',
  GAS = 'ГАЗ'
}

export enum ResourceTypeCode {
  HOT_WATER = 'HOT_WATER',
  HEATING = 'HEATING',
  VENTILATION = 'VENTILATION',
  HEAT_ENERGY = 'HEAT_ENERGY',
  COLD_WATER = 'COLD_WATER',
  ELECTRIC_ENERGY = 'ELECTRIC_ENERGY',
  GAS = 'GAS'
}

export enum MeasureObjectType {
  OU = 'MEASURE_OBJECT',
  USPD = 'DEVICE_TRANSFER',
  UPD = 'DEVICE_MODEM',
  PU = 'DEVICE_METER',
  IU = 'DEVICE_MEASURE',
  TU = 'MEASURE_POINT'
}

export enum MeasureObjectTypeName {
  OU = 'ОУ',
  USPD = 'УСПД',
  UPD = 'УПД',
  PU = 'ПУ',
  IU = 'ИУ',
  TU = 'ТУ'
}

export enum MeasureObjectNameType {
  'ОУ' = 'OU',
  'УСПД' = 'USPD',
  'УПД' = 'UPD',
  'ПУ' = 'PU',
  'ИУ' = 'IU',
  'ТУ' = 'TU'
}

export enum ResourceTypeIcon {
  HOT_WATER = 'icon-gvs',
  HEATING = 'icon-co',
  VENTILATION = 'icon-vent',
  HEAT_ENERGY = 'icon-heat_energy',
  COLD_WATER = 'icon-hvs',
  ELECTRIC_ENERGY = 'icon-electro',
  GAS = 'icon-gaz'
}

export enum ResourceTypeColor {
  HOT_WATER = '#f18d4a',
  HEATING = '#facd61',
  VENTILATION = '#8ce5fb',
  HEAT_ENERGY = '#e0633d',
  COLD_WATER = '#78c7e4',
  ELECTRIC_ENERGY = '#ffa734',
  GAS = '#8c94f2'
}

export enum MeasureObjectStateIcon {
  SERVICE = 'icon-service',
  CREATED = 'icon-registered',
  NEW = 'icon-new',
  NOWORKING = 'icon-not_work',
  ARCHIVE = 'icon-in_archive',
  WORKINGPU = 'icon-operated_without_uspd',
  WORKINGUSD = 'icon-operated_with_uspd',
  CONNECTED = 'icon-connected_to_uspd',
  WORKING = 'icon-good',
  NOTINSTALLED = 'icon-not_work'
}

export enum MeasureObjectStateIndex {
  SERVICE = 8,
  CREATED = 1,
  NEW = 2,
  NOWORKING = 7,
  ARCHIVE = 9,
  WORKINGPU = 5,
  WORKINGUSD = 4,
  CONNECTED = 6,
  WORKING = 3,
  NOTINSTALLED = 10
}

export enum MeasureObjectState {
  NEW = "NEW", //Новый
  CREATED = "CREATED", //Создан
  NOWORKING = "NOWORKING", //Не работает
  SERVICE = "SERVICE", //Сервисный режим
  WORKING = "WORKING", //Эксплуатируется
  ARCHIVE = "ARCHIVE", //В архиве
  NOTINSTALLED = "NOTINSTALLED", //Не установлен
  WORKINGUSD = "WORKINGUSD", //Эксплуатируется с УСД
  WORKINGPU = "WORKINGPU" //Эксплуатируется без УСД
}

export enum AdminNsiDictionaryMode {
  READ_ONLY = "READ_ONLY",
  FULL = "FULL"
}

export enum AdminNsiDictionaryUiType {
  NSI = "NSI",
  NSI_CUSTOM = "NSI_CUSTOM"
}

export enum AddressSource {
  FIAS = "FIAS",
  MANUAL = "MANUAL",
  ZKH = 'ZKH'
}

export enum ColType {
  VARCHAR = 'VARCHAR',
  BIGINT = 'BIGINT',
  INTEGER = 'INTEGER',
  TIMESTAMP = 'TIMESTAMP',
  BOOL = 'BOOL',
  NUMBER = 'NUMBER'
}

export enum DeviceMeterTypeCode {
  HEAT = 'HEAT', // Тепловычислитель
  EE = 'EE', // Счетчик электроэнергии
  IMPULSE = 'IMPULSE', // Расходомер импульсный ХВС или ГВС
  GAS = 'GAS', // Расходомер Газа
}

export enum MeasurePointType {
  INPUT = 'INPUT', // Потребление
  OUTPUT = 'OUTPUT', // Генерация
  NET = 'NET' // Сеть распределения
}

export enum AccountType {
  COMM = 'COMM', // Коммерческий учет
  TECH = 'TECH' // Технологический учет
}

export enum MeasurePointSystemType {
  CLOSED = 'CLOSED', // Закрытая"
  OPENED = 'OPENED', // Открытая
  ONE_PHASE = 'ONE_PHASE', // Однофазная
  THREE_PHASE = 'THREE_PHASE', // Трехфазная
  STUB = 'STUB', // Тупиковая
  CIRCULATING = 'CIRCULATING' // Циркуляционная
}

export enum TranformRatioTypeCode {
  V = 'V', // Напряжение
  A = 'A' // Ток
}

export enum MeasureObjectTreeContextFuncCode {
  ADD_TU_TO_OU = 'c_MeasurePoint.cf_add', // Добавить Точку учета
  CONNECT_USPD_TO_OU = 'c_DeviceTransfer.cf_ConnectToMeasureObject', // Подключить УСПД к Объекту учета
  CONNECT_UPD_TO_OU = 'c_DeviceModem.cf_ConnectToMeasureObject', // Подключить УПД к Объекту учета
  DISCONNECT_USPD_FROM_OU = 'c_DeviceTransfer.cf_DisconnectFromMeasureObject', // Отключить УСПД от Объекта учета
  DELETE_CONNECT_USPD_TO_OU = 'c_DeviceTransfer.cf_DeleteConnectToMeasureObject', // Удалить подключение УСПД к Объекту учета
  CHECK_CONNECT_WITH_USPD = 'c_DeviceTransfer.cf_CheckConnectionUSPD', // Проверка связи с УСПД
  TRANSFER_USPD_PARAMS_TO_IVK = 'c_DeviceTransfer.cf_WriteUspdParam', // Передать параметры УСПД на ИВК
  REQUEST_CONFIG_USPD = 'c_DeviceTransfer.cf_ReadUspdTree', // Запрос конфигурации УСПД
  REQUEST_INSTANT_VALUES_USPD = 'c_DeviceTransfer.cf_GetInstantMeterDataUSPD', // Запрос мгновенных значений
  REBOOT_USPD = 'c_DeviceTransfer.cf_RestartUSPD', // Перезагрузка УСПД
  TRANSFER_CONFIG_USPD = 'c_DeviceTransfer.cf_WriteUspdTree', // Передать конфигурацию на УСПД
  REREAD_ARCHIVE_DATA_USPD = 'c_DeviceTransfer.cf_RereadMeterDataUSPD', // Переопросить архивные данные УСПД
  GENERATE_PASSPORT_USPD = 'c_DeviceTransfer.cf_GeneratePassportUSPD', // выгрузка паспорта УСПД
  DISCONNECT_UPD_FROM_OU = 'c_DeviceModem.cf_DisconnectFromMeasureObject', // Отключить УПД от Объекта учета
  DELETE_CONNECT_UPD_TO_OU = 'c_DeviceModem.cf_DeleteConnectToMeasureObject', // Удалить подключение УПД к Объекту учета
  CHECK_CONNECT_WITH_UPD = 'c_DeviceModem.cf_CheckConnectionModem', // Проверка связи с УПД
  TRANSFER_UPD_PARAMS_TO_IVK = 'c_DeviceModem.cf_WriteModemParam', // Передать параметры УПД на ИВК
  REQUEST_CONFIG_UPD = 'c_DeviceModem.cf_ReadModemTree', // Запрос конфигурации УПД
  REQUEST_INSTANT_VALUES_UPD = 'c_DeviceModem.cf_GetInstantMeterDataModem', // Запрос мгновенных значений
  REBOOT_UPD = 'c_DeviceModem.cf_RestartModem', // Перезагрузка УПД
  TRANSFER_CONFIG_UPD = 'c_DeviceModem.cf_WriteModemTree', // Передать конфигурацию на УПД
  REREAD_ARCHIVE_DATA_UPD = 'c_DeviceModem.cf_RereadMeterDataModem', // Переопросить архивные данные УПД
  GENERATE_PASSPORT_UPD = 'c_DeviceModem.cf_GeneratePassportModem', // выгрузка паспорта модема (УПД)
  CONNECT_PU_TO_USD = 'c_DeviceMeter.cf_ConnectToUSD', // Подключить ПУ к УСД
  DISCONNECT_PU_FROM_USD = 'c_DeviceMeter.cf_DisconnectFromUSD', // Отключить ПУ от УСД
  DELETE_CONNECT_PU_TO_USD = 'c_DeviceMeter.cf_DeleteConnectToUSD', // Удалить подключение ПУ к УСД
  DISCONNECT_IU_FROM_TU = 'c_DeviceMeasure.cf_DisconnectFromMeasurePoint', // Отключить ИУ от ТУ
  DELETE_CONNECT_IU_TO_TU = 'c_DeviceMeasure.cf_DeleteConnectToMeasurePoint', // Удалить подключение ИУ к ТУ
  CONNECT_IU_TO_TU = 'c_DeviceMeasure.cf_ConnectToMeasurePoint', // Подключить ИУ к ТУ
  CONNECT_TU_TO_PU = 'c_MeasurePoint.cf_ConnectToDeviceMeter', // Подключить ТУ к ПУ
  DISCONNECT_TU_FROM_PU = 'c_MeasurePoint.cf_DisconnectFromDeviceMeter', // Отключить ТУ от ПУ
  DELETE_CONNECT_TU_TO_PU = 'c_MeasurePoint.cf_DeleteConnectToDeviceMeter', // Удалить подключение ТУ к ПУ
  OPEN_INSTANT_JOURNAL = "c_MeasurePoint.cf_OpenInstantJournal", // Открыть журнал мгновенных параметров
  OPEN_ARCHIVE_JOURNAL = "c_MeasurePoint.cf_OpenArchiveJournal", // Открыть журнал архивных параметров
  REREAD_ARCHIVE_DATA_TU = 'c_MeasurePoint.cf_RereadMeterDataTU', // Переопросить архивные данные ТУ
}

export enum MeasureParamType {
  INSTANT = 'INSTANT', // Мгновенные
  ARCHIVE = 'ARCHIVE', // Архив
  HOURLY = 'HOURLY', // Часовой архив
  DAILY = 'DAILY', // Суточный архив
  MONTHLY = 'MONTHLY', // Месячный архив
  YEARLY = 'YEARLY', // Годовой архив
}

export enum DocumentType {
  TXT = 'TXT',
  PDF = 'PDF',
  DOC = 'DOC',
  DOCX = 'DOCX',
  RTF = 'RTF',
  XLSX = 'XLSX',
  XLS = 'XLS',
  XML = 'XML',
  JSON = 'JSON',
  ZIP = 'ZIP',
  GIF = 'GIF',
  JPEG = 'JPEG',
  PNG = 'PNG'
}

/**
 * Типы пароля электросчетчика
 */
export enum EPasswordTypeCodesEE {
  HEX = 'HEX',
  ASCI = 'ASCII'
}

export enum Microservice {
  MAIN = 'WS_WEB_BACKEND'
}

export enum ModemConnectionType {
  DYNAMIC = 'DYNAMIC',
  STATIC = 'STATIC',
}

