<div class="actionMenuClass">
  <div class="action-menu-icon"
       [class.opened]="openedMenu"
       [class.disabled]="disabled"
       [clickOutsideEnabled]="enableClickOutside"
       (clickOutside)="openMenu($event, !enableClickOutside)"
       (click)="openMenu($event, !enableClickOutside)">
    <span class="font-icons"
          [class.icon-action_menu]="!withIconEllipsis && !withIconAdd && !withIconReport"
          [class.icon-context_menu]="withIconEllipsis && !withIconAdd && !withIconReport"
          [class.icon-plus]="withIconAdd && !withIconEllipsis && !withIconReport"
          [class.icon-report]="withIconReport && !withIconEllipsis && !withIconAdd"
    ></span>
  </div>
  <div *ngIf="openedMenu" class="action-menu-block"
       [class.for-icon-ellipsis]="withIconEllipsis"
       [class.for-icon-add]="withIconAdd"
       [class.for-icon-report]="withIconReport">
    <ng-content class="action-menu-content"></ng-content>
  </div>
</div>
