import {Component, Input, forwardRef, OnChanges, EventEmitter, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ITextMaskConfig} from "@core/interfaces/text-mask";
import {createAutoCorrectedDatePipe} from "text-mask-addons/dist/textMaskAddons";

@Component({
  selector: 'app-time-select-input',
  templateUrl: './time-select-input.component.html',
  styleUrls: ['./time-select-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeSelectInputComponent),
      multi: true
    }
  ]
})
export class TimeSelectInputComponent implements OnChanges, ControlValueAccessor {

  @Input() id: string = null;
  @Input() selected: string = '00:00:00';
  @Input() withMinutes: boolean = true;
  @Input() withSeconds: boolean = false;
  @Input() disabled: boolean = false;
  @Input() withIcon: boolean = true;
  @Output() OnSelect: EventEmitter<string> = new EventEmitter<string>();

  public mask = [/\d/, /\d/];
  private maskConfig: ITextMaskConfig = {
    mask: this.mask,
    showMask: false,
    guide: false,
    keepCharPositions: true
  };
  public hourMaskConfig: ITextMaskConfig = {...this.maskConfig, ...{pipe: createAutoCorrectedDatePipe('HH')}};
  public minuteMaskConfig: ITextMaskConfig = {...this.maskConfig, ...{pipe: createAutoCorrectedDatePipe('MM')}};
  public secondMaskConfig: ITextMaskConfig = {...this.maskConfig, ...{pipe: createAutoCorrectedDatePipe('SS')}};
  public hour: string = '00';
  public minute: string = '00';
  public second: string = '00';

  public _onChange: any = () => {};
  public _onTouch: any = () => {};

  constructor() { }

  ngOnChanges() {
    if (!this.selected) {
      this.selected = '00:00:00';
    }
    this.prepareFields();
    this.changeValue(this.selected);
  }

  private changeValue(value: string) {
    if (value) {
      this.selected = value;
    }
    this._onChange(value);
  }

  private prepareFields() {
    if (this.selected) {
      const time = this.selected.split(':');
      this.hour = time && time.length > 0 ? time[0] : '00';
      this.minute = time && time.length > 0 ? time[1] : '00';
      this.second = time && time.length > 0 ? time[2] : '00';
      if (!this.withMinutes && !this.withSeconds) {
        this.minute = '00';
        this.second = '00';
      }
    } else {
      this.hour = '00';
      this.minute = '00';
      this.second = '00';
    }
  }

  private prepareTimeString() {
    const hour = this.hour && this.hour !== '' ? this.hour.length === 1 ? '0' + this.hour : this.hour : '00';
    const minute = this.minute && this.minute !== '' ? this.minute.length === 1 ? '0' + this.minute : this.minute : '00';
    const second = this.second && this.second !== '' ? this.second.length === 1 ? '0' + this.second : this.second : '00';
    return hour + ':' + minute + ':' + second;
  }

  public changeHourByArrow(type: string) {
    this.hour = this.changeByArrow(this.hour, type, 0, 23);
  }

  public changeMinuteByArrow(type: string) {
    this.minute = this.changeByArrow(this.minute, type, 0, 59);
  }

  public changeSecondByArrow(type: string) {
    this.second = this.changeByArrow(this.second, type, 0, 59);
  }

  private changeByArrow(value: string, type: string, min: number, max: number): string {
    let result: number = Number(value);
    if (type === 'minus') {
      if (result > min) {result--;}
    } else if (type === 'plus') {
      if (result < max) {result++;}
    }
    return result.toString();
  }

  public changeHour(value: string) {
    this.hour = this.prepareValue(value, 0 , 23);
  }

  public sendHour() {
    this.hour = this.prepareValue(this.hour, 0 , 23);
    const timeStr = this.prepareTimeString();
    this.writeValue(timeStr);
    this.OnSelect.emit(timeStr);
  }

  public changeMinute(value: string) {
    this.minute = this.prepareValue(value, 0, 59);
  }

  public sendMinute() {
    this.minute = this.prepareValue(this.minute, 0, 59);
    const timeStr = this.prepareTimeString();
    this.writeValue(timeStr);
    this.OnSelect.emit(timeStr);
  }

  public changeSecond(value: string) {
    this.second = this.prepareValue(value, 0, 59);
  }

  public sendSecond() {
    this.second = this.prepareValue(this.second, 0, 59);
    const timeStr = this.prepareTimeString();
    this.writeValue(timeStr);
    this.OnSelect.emit(timeStr);
  }

  private prepareValue(value: string, min: number = 0, max: number): string {
    let result: string;
    if (value) {
      result = value;
      if (Number(result) < min) {
        result = '00';
      } else if (Number(result) > max) {
        result = max.toString();
      }
    }
    return result;
  }

  writeValue(value: string): void {
    this.selected = value ? value : '00:00:00';
    this.prepareFields();
    this.changeValue(this.selected);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
