<div class="addGuideModalClass">
  <div class="modal-header">{{'GUIDE.LOAD_GUIDE' | translate}}</div>
  <div class="modal-body">
    <app-loader [show]="isLoading"></app-loader>
    <div class="add-guide-file">
      <div class="add-guide-file-content">
      <div class="add-guide-file-item">
        <div class="add-guide-file-item-label">
            <span class="form-field-label required">
              {{'GUIDE.FILE' | translate}}
            </span>
          <span class="font-icons icon-info"
                [ngbTooltip]="'GUIDE.FILE_SIZE' | translate: {value: maxFileSize}"
                [tooltipClass]="'card-field-tooltip'"
                placement="bottom" container="body"></span>
        </div>
        <div class="add-guide-file-field">
          <div *ngIf="!file" class="add-guide-file-field-file">
            <label for="file" class="add-guide-file-btn">
              <span class="font-icons icon-upload_file"></span>
              <span class="add-guide-file-btn-text">{{'GENERAL.LOAD' | translate}}</span>
            </label>
            <input #uploadInput type="file" id="file" (change)="addFile($event.target)"
                   accept=".pdf, .doc, .docx">
          </div>
          <div *ngIf="file" class="add-guide-file-field-file">
            <span class="text">
              <span class="name">{{fileName.name}}</span>
              <span class="ext">.{{fileName.extension}}</span>
            </span>
            <span class="font-icons icon-clear" (click)="deleteFile()"></span>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <app-button class="modal-footer-btn"
                (click)="cancel()"
                [title]="'GENERAL.CANCEL' | translate"
    ></app-button>
    <app-button class="modal-footer-btn blue"
                (click)="apply()"
                [disabled]="!file"
                [title]="'GENERAL.LOAD' | translate"
    ></app-button>
  </div>
</div>
