<div class="noAccessClass page-content">
  <div class="no-access-content">
    <span class="no-access-icon font-icons icon-no_access"></span>
    <div class="no-access-title">{{'NO_ACCESS.TITLE' | translate}}</div>
    <div class="no-access-description">{{'NO_ACCESS.DESCRIPTION' | translate}}</div>
    <div class="no-access-info">
      <div class="no-access-phone">
        <span class="font-icons icon-phone"></span>
        <span class="no-access-phone-text">{{supportPhone}}</span>
      </div>
      <div class="no-access-mail">
        <span class="font-icons icon-mail"></span>
        <a href="mailTo:{{supportEmail}}"
           class="outer-href">{{supportEmail}}</a>
      </div>
    </div>
  </div>
</div>
