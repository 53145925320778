import { Component, forwardRef, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true
    }
  ]
})
export class SwitchComponent implements OnChanges, ControlValueAccessor {

  @Input() value: boolean;  // выбранное значение
  @Input() name: string; // имя чекбокса
  @Input() title: string; // заголовок рядом
  @Input() public disabled: boolean; // блокировка
  @Input() withSelectTitle: boolean = false; // с выделение заголовка у выбранного чекбокса (для списков)
  /**
   * Показывать или нет надписи "Вкл" / "Выкл" внутри свича
   */
  @Input() stateTitle: boolean = false;
  @Output() public OnChange: EventEmitter<boolean> = new EventEmitter(); // событие при смене состояния

  public _onChange: any = () => { };
  public _onTouch: any = () => { };

  constructor(private cdr: ChangeDetectorRef) {

  }

  ngOnChanges() {
    this.changeValue(this.value);
  }

  private changeValue(value: boolean) {
    this.value = value;
    this._onChange(this.value);
  }

  writeValue(value: boolean) {
    if (value !== this.value) {
      this.changeValue(value);
      this.cdr.detectChanges();
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  public change(e): void {
    e.srcElement.blur();
    e.preventDefault();
    if (!this.disabled) {
      this.changeValue(e.target.checked);
      this.OnChange.emit(e.target.checked);
    }
  }
}
