<div class="MenuItemClass"
    [class.main-item]="mainItem">
    <div *ngIf="!item?.url"
        class="menu-item-title"
        [class.active]="item.isActive"
        (click)="changeOpened()">
        <div class="title-left">
            <span *ngIf="item.iconClass"
                class="font-icons menu-item-icon {{item.iconClass}}">
            </span>
            <div>
                <span class="text"
                    [class.main-item]="mainItem">
                    {{item.name}}
                </span>
            </div>
        </div>
        <span class="font-icons icon-str_down"
            [class.str_up]="item.isOpened"></span>
    </div>
    <div *ngIf="item?.url"
        class="menu-item-route"
        [class.active]="item.isActive"
        [routerLink]="item.url">
        <span *ngIf="item.iconClass"
            class="font-icons menu-item-icon {{item.iconClass}}">
        </span>
        <span class="text">{{item.name}}</span>
    </div>
    <div *ngIf="item?.isOpened && item?.children?.length > 0"
        class="menu-item-sublist"
        [class.main-item]="mainItem">
        <app-menu-item *ngFor="let child of item.children"
                       [item]="child"
                       (changeOpen)="changeItemOpen()">
        </app-menu-item>
    </div>
</div>
