import {Component, EventEmitter, forwardRef, Input, OnChanges, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {SimpleDictionaryComponent} from "@shared/components/simple-dictionary/simple-dictionary.component";
import {OpenModalService} from "@shared/services/open-modal.service";
import {DictionaryService} from "@shared/services/dictionary.service";
import {ISelectItem} from "@core/interfaces/select-item";
import {IDictionaryBody, IDictionaryParams} from "@core/interfaces/dictionary";
import {AddressSimpleDictionaryModalComponent} from "@shared/components/address-simple-dictionary/address-simple-dictionary-modal/address-simple-dictionary-modal.component";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";

@Component({
  selector: 'app-address-simple-dictionary',
  templateUrl: './address-simple-dictionary.component.html',
  styleUrls: ['./address-simple-dictionary.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressSimpleDictionaryComponent),
      multi: true
    }
  ]
})
export class AddressSimpleDictionaryComponent extends SimpleDictionaryComponent implements OnChanges, ControlValueAccessor {

  @Input() withAdd: boolean = false; // с функцией добавления

  constructor(
    protected modalService: OpenModalService,
    protected dictionaryService: DictionaryService
  ) {
    super(modalService, dictionaryService);
  }

  public open() {
    if (this.disabled) {
      return;
    }
    this.simpleDictionaryModal = this.modalService.show(
      AddressSimpleDictionaryModalComponent,
      {
        selected: this.selected,
        list: this.list,
        params: this.params,
        title: this.titleDictionary ? this.titleDictionary : this.title,
        loadingInProgress: this.loadingInProgress,
        withAdd: this.withAdd,
        OnSelect: (item: ISelectItem) => {
          this.apply(item);
        },
        OnLoadList: (params: IDictionaryParams) => {
          this.loadList(params);
        },
        OnClose: () => {
          this.simpleDictionaryModal = undefined;
          this.params = {...this.params, ...this.dictionaryService.getDefaultParams()};
          this.list = this.dictionaryService.getDefaultList();
        }
      },
      'OnClose',
      {
        centered: true,
        windowClass: 'dictionary-modal dictionary-modal-full-width',
      });
  }
}
