import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenuService} from "@shared/services/menu.service";
import {SystemParamsService} from "@core/services/system-params.service";
import {SYSTEM_PARAMETER_NAME} from "@app/app.enums";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-no-access-software-rent-contract',
  templateUrl: './no-access-software-rent-contract.component.html',
  styleUrls: ['./no-access-software-rent-contract.component.scss']
})
export class NoAccessSoftwareRentContractComponent implements OnDestroy {

  public supportPhone: string;
  public supportEmail: string;
  private publicSystemParamsSubscription: Subscription = new Subscription();

  constructor(
    private menuService: MenuService,
    private systemParamsService: SystemParamsService
  ) {
    this.menuService.setOpenedMenu(false);
    this.publicSystemParamsSubscription = this.systemParamsService.getPublicSystemParams()
      .subscribe((systemParams: any) => {
        this.supportPhone = systemParams ? systemParams[SYSTEM_PARAMETER_NAME.SUPPORT_PHONE] : '';
        this.supportEmail = systemParams ? systemParams[SYSTEM_PARAMETER_NAME.SUPPORT_EMAIL] : '';
      });
  }

  ngOnDestroy() {
    this.publicSystemParamsSubscription.unsubscribe();
  }

}
